import { Dispatch, useEffect, useState } from "react";
import { DevisInfos, DevisValeur } from "../../../class/devis";
import DevisSelect from "./devis-select";
import { ActionDevis } from "./devis-infos-reducer";
import { DevisTypeCode, corresp_provenance_accord_devis, corresp_statuts_devis } from "../../../class/document";
import DatePicker from "react-datepicker";
import { fr } from "date-fns/locale";
interface DevisPreHeaderProps {
    devisInfos: DevisInfos | undefined;
    editable: boolean;
    devisInfosDispatch: Dispatch<ActionDevis>
    addAgreedDate: (date: Date | null) => void;
    acceptedDevis: boolean;
    addSource: (e: string) => void;
    agreedStatut?: string;
    agreedDate: Date | null;
    headerAcceptedStatut: { [key in keyof Partial<DevisInfos>]: (Date | string | null) }
    showInfos: boolean;
    setShowInfos: Dispatch<React.SetStateAction<boolean>>
}
const DevisPreHeader = ({
    devisInfos,
    editable,
    devisInfosDispatch,
    addAgreedDate,
    acceptedDevis,
    addSource,
    agreedStatut,
    agreedDate,
    headerAcceptedStatut,
    showInfos,
    setShowInfos
}: DevisPreHeaderProps) => {

    const scrollbarElement = document.querySelector('.scrollbar');

    const buildOnChange = (champ: string, isFamille?: boolean) =>
        (e: DevisValeur) =>
            devisInfosDispatch({ key: champ, value: isFamille ? e.label : e });

    const toggleInfos = () => {
        setShowInfos(prev => !prev);
        if (!showInfos) scrollbarElement?.scrollTo(0, 0);
    };

    useEffect(() => {
        !acceptedDevis && devisInfosDispatch({ key: 'updateHeaderAcceptedStatut', value: headerAcceptedStatut });
    }, [acceptedDevis])

    return (
        <div className="py-2 pb-md-0 px-3 d-flex justify-content-between align-items-start h-100">
            <div className="d-md-flex mb-1 mb-xl-0">

                <DevisSelect
                    classSuppl={' mb-1 mb-md-0'}
                    listeDeroulante={devisInfos?.statut}
                    titre='Statut'
                    editable={editable}
                    isLoading={!devisInfos}
                    onChange={buildOnChange('statut')}
                />
                {acceptedDevis &&
                    <div className="d-md-flex align-items-center justify-content-start">
                        <div className="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                            <span className="nowrap me-1 ms-0 ms-md-2">Date d'accord : </span>
                            <label>
                                <DatePicker
                                    onChange={(date) => addAgreedDate(date)}
                                    selected={agreedDate}
                                    dateFormat="dd/MM/yyyy"
                                    icon="fa fa-calendar"
                                    showIcon
                                    className="form-control mx-width-3"
                                    closeOnScroll={true}
                                    maxDate={new Date()}
                                    minDate={devisInfos?.creation_date}
                                    locale={fr}
                                />
                            </label>
                        </div>
                        <div className="d-flex align-items-center justify-content-start">
                            <span className="nowrap me-1 ms-0 ms-md-2">Provenance : </span>
                            <select
                                className="form-select form-control"
                                id="pourcent2"
                                onChange={(e) => addSource(e.target.value)}
                                value={agreedStatut}
                            >
                                {Object.entries(corresp_provenance_accord_devis)?.map(([key, source]) =>
                                    <option key={key} value={key}>{source}</option>
                                )}
                            </select>
                        </div>
                    </div>
                }
            </div>
            <div className="ms-2 mt-2 mt-lg-0">
                <button className="btn btn-infos bg-bleu-clair3 text-uppercase d-flex align-items-center" onClick={toggleInfos}><span className="nowrap">{showInfos ? 'Masquer les infos' : 'Afficher les infos'}</span> <span className={`fa fa-chevron-${showInfos ? 'up' : 'down'} ms-0 ms-lg-2`}></span></button></div>
        </div>
    )
}

export default DevisPreHeader;