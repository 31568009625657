import axios from "axios";
import { API } from "../Constantes";
import authHeader from "./AuthHeader";
import { encodeParams } from "../components/functions/requestTools";

export interface ResponsePbiADGroups {
    id: string;
    name: string;
    menu_item: string;
}

class PowerBIServiceClass {

    async GetPBIGroups(email: string) {
        const response = await axios.post(API + "powerbi/get_AD_groups",
            encodeParams({ email: email }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                ...authHeader()
            }
        });
        return response.data;
    }

}

const PowerBIService = new PowerBIServiceClass();
export default PowerBIService;