import { Document, Font, Page, Text, View } from "@react-pdf/renderer"
import { DevisInfos } from "../../../class/devis";
import { PDFDevisSinature } from "./pdf-devis-signature";
import { PDFDevisMontantTotal } from "./pdf-devis-montant-total";
import { PDFHeader } from "./pdf-header";
import { PDFLigneTitre } from "./pdf-ligne-titre";
import { PDFLigneArticle } from "./pdf-ligne-article";
import { PDFLigneCommentaire } from "./pdf-ligne-commentaire";
import { PDFFooter } from "./pdf-footer";
import { PDFBodyHeader } from "./pdf-body-header";
import { getDevisClientModelStyles } from "./devis-client-model-style";
import { PDFLogo } from "./pdf-logo";
import { InfosPDF } from "../hooks/usePDFInfos";


export interface DevisClientModel {
    devisInfos: DevisInfos;
    infosPDF?: InfosPDF | undefined;
    isProForma?: boolean;
    hidePrice?: boolean;
    hideTotalDevis?: boolean;
    hideAdresse?: boolean;
    devisWithoutSinistre?: boolean;
    code_agence?: number;
    pageNumber?: number;
}




export const DevisClientModel = ({ pageNumber, devisInfos, isProForma, hidePrice, hideTotalDevis, hideAdresse, devisWithoutSinistre, infosPDF }: DevisClientModel) => {
    const styles = getDevisClientModelStyles({ isProForma });


    Font.register({
        family: 'Open Sans',
        fonts: [
            { src: '../../../../assets/fonts/OpenSans/open-sans-regular.ttf' },
            { src: '../../../..//assets/fonts/OpenSans/open-sans-700.ttf', fontWeight: 'bold' },
            { src: '../../../../assets/fonts/OpenSans/open-sans-800.ttf', fontWeight: 'ultrabold' },
            { src: '../../../../assets/fonts/OpenSans/open-sans-600italic.ttf', fontStyle: 'italic' },
        ]
    })

/*
    Font.register({
        family: 'Ubuntu',
        fonts: [
            { src: '../../../../assets/fonts/Ubuntu/Ubuntu-Regular.ttf' , fontWeight : 400},
            { src: '../../../..//assets/fonts/Ubuntu/Ubuntu-Medium.ttf', fontWeight: 500 },
            { src: '../../../../assets/fonts/Ubuntu/Ubuntu-Bold.ttf', fontWeight: 700 },
            { src: '../../../../assets/fonts/Ubuntu/Ubuntu-Italic.ttf', fontWeight: 700, fontStyle: 'italic' },
        ]
    })
*/
    return (
        <Document title='Devis'>
            <Page style={styles.page}>
                <PDFLogo
                    devisInfos={devisInfos}
                    pageNumber={pageNumber}
                    isProForma={isProForma}
                />
                <PDFHeader
                    devisInfos={devisInfos}
                    isProForma={isProForma}
                    hideAdresse={hideAdresse}
                    devisWithoutSinistre={devisWithoutSinistre}
                />
                <View>
                    <PDFBodyHeader hidePrice={hidePrice} />
                    <View>
                        {devisInfos.lignes.map(ligne => {
                            return (
                                <View key={ligne.id} wrap={false}>
                                    <View >
                                        {ligne.is_titre ?
                                            <PDFLigneTitre
                                                text={ligne.titre.content}
                                                montant_total={ligne.montant_total}
                                                hidePrice={hidePrice}
                                                typeTitre={ligne.titre.type}
                                            />
                                            : (ligne.is_article || ligne.is_lot) ?
                                                <PDFLigneArticle
                                                    code_op={infosPDF?.code_op[ligne.id] || ''}
                                                    ligne={ligne}
                                                    hidePrice={hidePrice}
                                                />
                                                : ligne.is_nota ?
                                                    <PDFLigneCommentaire
                                                        text={ligne.commentaire}
                                                    />
                                                    : <Text></Text>}
                                    </View>
                                </View>
                            )
                        })}
                    </View>

                </View>
                <View wrap={false}>
                    <View style={styles.containerSignatureAndCalculs}>
                        {!isProForma && <PDFDevisSinature />}
                        {!hidePrice && !hideTotalDevis && <PDFDevisMontantTotal devisInfos={devisInfos} codeTva={infosPDF?.code_tva} />}
                    </View>
                    {
                        !isProForma && (
                            <View style={styles.validitePeriode}>
                                <Text>Validité de 1 mois à compter de la date du devis.</Text>
                                <Text>Toute validation postérieure au délai sera soumise à analyse avant confirmation de la tarification.</Text>
                            </View>
                        )
                    }
                </View>
                <PDFFooter />
            </Page>
        </Document>
    )

};

export const DevisClientModelClassic = (props: any) => DevisClientModel({ ...props });
export const DevisClientModelClassicProformat = (props: any) => DevisClientModel({ ...props, isProForma: true });
export const DevisClientModelSansPrix = (props: any) => DevisClientModel({ ...props, hidePrice: true, hideTotalDevis: true });
export const DevisClientModelSansPrixSansAdresse = (props: any) => DevisClientModel({ ...props, hidePrice: true, hideTotalDevis: true, hideAdresse: true });
export const DevisClientModelChiffreSansSinistre = (props: any) => DevisClientModel({ ...props, devisWithoutSinistre: true });

// Font.register({
//     family: 'Oswald',
//     src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
// });

export const aaa = ''; // Ne pas supprimer, permet le live reload de react