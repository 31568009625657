export interface Utilisateur {
    // label: any;
    //code: any;
    id: number;
    representant_id: number;
    representants_secondaires: number[];
    accessToken: string;
    nom: string;
    prenom: string;
    isfirstlogin: boolean;
    email: string;
    identifiant: string;
    verified: boolean;
    status: number;
    role: number;
    type_employe: TypeEmploye;
    lastlogin: string;
    expiration: number;
    hasAgency: boolean;
    acces_reporting: boolean;
}

export const charge_affaire = 'charge_affaire';
export const assistante = 'assistante';

type TypeEmploye = typeof charge_affaire | typeof assistante