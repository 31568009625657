import { Media, TableColumn } from "react-data-table-component";
import { NavigateFunction } from "react-router-dom";
import { Affaire } from "../../../class/affaire";

interface getAffairesColsProps {
    navigate: NavigateFunction,
    OpenModalWithData: (id_affaire: string) => void,
    isChargeDaffaire: boolean,
}

export const getAffairesCols = ({ navigate, OpenModalWithData, isChargeDaffaire }: getAffairesColsProps) => [
    {
        name: "N° d'affaire",
        selector: (row: Affaire) => row.numero_dossier,
        sortable: true,
        width: 'auto',
    },
    {
        name: "Chargé d'affaires",
        selector: (row: Affaire) => row.nom_charge_affaire,
        sortable: true,
        grow: 2,
        hide: 'lg' as Media,
        width: isChargeDaffaire ? '20%' : '15%',
    },
    isChargeDaffaire ?
        undefined
        : {
            name: "Agence",
            selector: (row: Affaire) => row.code_agence,
            sortable: true,
            grow: 2,
            hide: 'md' as Media,
            width: '10%',
        },
    {
        name: 'Client',
        selector: (row: Affaire) => row.nom_client,
        sortable: true,
        grow: 2,
        minWidth: '50px',
        width: 'auto',
    },
    {
        name: 'Adresse',
        selector: (row: Affaire) => row.display_addr_assure === "2" ? row.adresse_client + row.code_postal_client + row.ville_client : row.rue_sinistre + row.cp_sinistre + row.ville_sinistre,
        cell: (row: Affaire) =>
            row.display_addr_assure === "2" ?
                <>{row.adresse_client}<br />{row.code_postal_client} {row.ville_client}</> :
                <>{row.rue_sinistre}<br />{row.cp_sinistre} {row.ville_sinistre}</>,
        sortable: true,
        hide: 'lg' as Media,
        width: '25%',
    },
    /*{
        name: 'Adresse',
        selector: (row: Affaire) => row.rue_sinistre + row.cp_sinistre + row.ville_sinistre,
        cell: (row: Affaire) =>
            <>{row.rue_sinistre}<br />{row.cp_sinistre} {row.ville_sinistre}</>,
        sortable: true,
        hide: 'lg' as Media,
    },*/
    {
        name: 'Date de création',
        selector: (row: Affaire) => row.date_creation.toLocaleDateString(),
        sortable: true,
        hide: 'md' as Media,
        width: 'auto',
    }/*,
    {
        name: 'Dossier',
        selector: (row: Affaire) => row.dossier,
        cell: NbPhotos,
        sortable: true,
        hide: 'lg' as Media,
        width: '10%'
    }*/,
    {
        name: 'Référence sinistre',
        selector: (row: Affaire) => row.ref_sinistre,
        sortable: true,
        hide: 'sm' as Media,
        width: 'auto'
    }
    // {
    //     name: 'Chat',
    //     // selector: (row: Affaire) => row.dossier,
    //     cell: (row: Affaire) => <button onClick={() => OpenModalWithData(row.numero_dossier)} className="nobt bleu-actif"><Icon  icon={'comment-dots  display-16'} /></button>,
    //     sortable: false,
    //     hide: 'lg' as Media,
    //     right: true,
    //     width:'80px',
    // }
].filter(Boolean) as TableColumn<Affaire>[];

const NbPhotos = (row: Affaire) => {
    //const nbphotos = useAffaireNbPhotos(row.numero_dossier);
    return <>{/*nbphotos || */'0 photo'}</>;
}