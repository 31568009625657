import { DevisInfos } from "../../../class/devis";
import { InfosPDF } from "../hooks/usePDFInfos";
import { DevisClientModelClassic, DevisClientModelSansPrix, DevisClientModelSansPrixSansAdresse, DevisClientModelClassicProformat, DevisClientModelChiffreSansSinistre } from "./devis-client-model";


export const pdfModelList = [
    { name: 'Devis classique', model: DevisClientModelClassic }, // Ajout de mes nouveaux Pdf 
    { name: 'Proforma classique', model: DevisClientModelClassicProformat },
    { name: "Devis Sans Prix", model: DevisClientModelSansPrix },
    { name: "Devis Sans Prix Sans Adresse", model: DevisClientModelSansPrixSansAdresse },
    { name: "Devis Chiffré - Hors Sinistre", model: DevisClientModelChiffreSansSinistre },
];

// export const getPdfModelList = (devisInfos: DevisInfos, infosPDF: InfosPDF | undefined) => {
//     const pdfListRender = pdfModelList.map((model, i) => {
//         const ModelRendu = model.model;
//         const rendu = <ModelRendu devisInfos={devisInfos} infosPDF={infosPDF} />

//         return { id: i, rendu, name: model.name }
//     });

//     return pdfListRender;
// }

export interface PdfModel {
    id: number;
    rendu: JSX.Element;
    name: string;
}

export const getPdfModelList = (devisInfos: DevisInfos | undefined, infosPDF: InfosPDF | undefined): PdfModel[] => {
    const pdfListRender = pdfModelList.map((model, i) => {
        const ModelRendu = model.model;
        const rendu = <ModelRendu devisInfos={devisInfos} infosPDF={infosPDF} />

        return { id: i, rendu, name: model.name }
    });

    return pdfListRender;
}
