import { TemplateMail } from '../class/templateMail'

export function templateMailFormatter(template: any): TemplateMail {

    return {
        id: parseInt(template.template_mail_id),
        name: template.template_mail_name,
        content: template.template_mail_content,
        comment: template.template_mail_comment,
        model: template.template_mail_model,
        is_restricted: !!template.template_mail_is_restricted,
        restricted_user_list: template.template_mail_restricted_user_list
    };
};


export const templatesMailsFormatter = (templates: any): TemplateMail[] =>
    templates.map((template: any) => templateMailFormatter(template));

export const allowedExtensions = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.pdf',
    '.odt',
    '.docx',
    '.xlsx',
    '.pptx'];