import { useState, useEffect, useContext } from "react";
import PowerBIService from "../../services/PowerBIService";
import { AuthContext } from "../../provider/AuthProvider";
import { GroupsPowerBI } from "../../class/power-bi";

export function usePBIGroups() {

    const { user } = useContext(AuthContext);
    const [userAcces_reporting, setUserAcces_reporting] = useState(false);
    const [groups, setGroups] = useState<GroupsPowerBI[]>([]);
    // Retourne la liste des contacts d'une company

    useEffect(() => {
        if (user && user.acces_reporting) {
            const fetchGroups = async () => {
                try {
                    const response = await PowerBIService.GetPBIGroups(user.email);
                    if (response.groups.length > 0) {
                        setUserAcces_reporting(true);
                        setGroups(response.groups);
                    } else {
                        setUserAcces_reporting(false);
                    }
                } catch (error) {
                    setUserAcces_reporting(false);
                    console.error('Failed to fetch groups:', error);
                }
            };
            fetchGroups();
        } else {
            setUserAcces_reporting(false);
        }
    }, [!user]);

    return { userAcces_reporting, groups };
}