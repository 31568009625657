import { Modal, Button } from 'react-bootstrap';
import { Loadering } from './loadering';

interface ConfirmModalProps {
  show: boolean;
  hideTitle?: boolean;
  hideHeader?: boolean;
  title: string | JSX.Element;
  titleIcon?: string;
  message: JSX.Element | string;
  size?: 'sm' | 'lg' | 'xl' | undefined;
  isRed?: boolean;
  isLoading?: boolean;
  cantConfirm?: boolean;
  cantCancel?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  hideFooter?:boolean,
}

const ConfirmModal = ({show, title, hideTitle, hideHeader, titleIcon = 'triangle-exclamation',
  message, size, onConfirm, onCancel, isRed, isLoading, cantConfirm, cantCancel, hideFooter}: ConfirmModalProps) => {

  const onConfirmSafe = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault(); e.stopPropagation();
    onConfirm();
  }

  return (
    <Modal show={show} onHide={onCancel} centered size={size}>
      {!hideHeader && 
      <Modal.Header closeButton>
        {!hideTitle && <Modal.Title>
          <div className={isRed ? 'rouge' : 'bleu'}>
            <i className={`fa-solid fa-${titleIcon} me-2 ${isRed ? 'rouge' : 'bleu'}`}></i>
            {title}
          </div>
        </Modal.Title>}
      </Modal.Header>}
      <Modal.Body>
        {isLoading ? <Loadering color={'bleu-actif'} formodal={true} /> : message}
      </Modal.Body>
      {hideFooter != true || !hideFooter ? (
        <Modal.Footer>
          {!cantCancel && <Button variant='secondary' onClick={onCancel}>
            Annuler
          </Button>}
          {!cantConfirm && <Button variant={isRed ? 'danger' : 'primary'} onClick={onConfirmSafe} autoFocus id={modalConfirmId}>
            Confirmer
          </Button>}
        </Modal.Footer>
      ) : (
        ''
      )}        
      </Modal>


  );
};

export default ConfirmModal;

export const modalConfirmId = 'modalConfirm';