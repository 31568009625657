import { StyleSheet, Text, View } from "@react-pdf/renderer"
import { formatDateValueForPdf } from "../../../components/functions/formatDate";
import { Affaire } from "../../../class/affaire";

export interface PDFTableContainerProps {
    ref_devis?: string;
    affaire: Affaire;
    devisWithoutSinistre?: boolean;
    hideAdresse?: boolean;
}

export const PDFTableContainer = ({
    ref_devis,
    affaire,
    devisWithoutSinistre,
    hideAdresse
}: PDFTableContainerProps) => {

    const styles = getPDFTableContainerStyles();
    const { numero_dossier, nom_charge_affaire, tel_charge_affaire, nom_expert,
        ref_sinistre, type_sinistre, nom_autre, nom_plateforme, nom_do, date_creation,
        tiers, nom_client, rue_sinistre, adresse_client, ville_client, ville_sinistre,
        code_postal_client, cp_sinistre, compl1_client, compl2_client,
        localisation_client, compl1_sinistre, compl2_sinistre, localisation_sinistre
    } = affaire;

    const formattedDateResult = formatDateValueForPdf(date_creation);
    const formattedDate = formattedDateResult.formattedDate;
    const formattedDateValidite = minusOneDay(formattedDateResult.formattedDateValidite);    

    return (
        <View style={styles.tableContainer}>
            <View style={styles.tableColumn}>
                <View style={styles.styleSection}>
                    <Text style={styles.labelText}>Date :</Text>
                    <Text style={{ ...styles.labelText, ...styles.textWeight }}>{formattedDate}</Text>
                </View>
                <View style={styles.styleSection}>
                    <Text style={styles.labelText}>Validité :</Text>
                    <Text style={{ ...styles.labelText, ...styles.textWeight }}>{formattedDateValidite}</Text>
                </View>
                <View style={styles.styleSection}>
                    <Text style={styles.labelText}>Réf. :</Text>
                    <Text style={{ ...styles.labelText, ...styles.textWeight }}>{ref_devis}</Text>
                </View>
                <View style={styles.styleSection}>
                    <Text style={styles.labelText}>Affaire N°</Text>
                    <Text style={{ ...styles.labelText, ...styles.textWeight }}>{numero_dossier}</Text>
                </View>
                <View style={styles.styleSection}>
                    <Text style={styles.labelText}>Charg.affaire :</Text>
                    <Text>{nom_charge_affaire}</Text>
                </View>
                <View style={styles.styleSection}>
                    <Text style={styles.labelText}>Tél. :</Text>
                    <Text style={styles.labelText}>{tel_charge_affaire}</Text>
                </View>
                <View></View>
            </View>
            <View style={styles.tableColumn}>
                {!devisWithoutSinistre ? (
                    <>
                        <View style={styles.styleSection}>
                            <Text style={styles.labelText}>Comp. assurance :</Text>
                            <Text style={styles.labelText}>{nom_do}</Text>
                        </View>
                        <View style={styles.styleSection}>
                            <Text style={styles.labelText}>Expert :</Text>
                            <Text style={styles.labelText}>{nom_expert}</Text>
                        </View>
                        <View style={styles.styleSection}>
                            <Text style={styles.labelText}>
                                Plateforme :
                            </Text>
                            <Text style={styles.labelText}>
                                {nom_plateforme}
                            </Text>
                        </View>
                        <View style={styles.styleSection}>
                            <Text style={styles.labelText}>Autre tiers :</Text>
                            <Text style={styles.labelText}>{nom_autre}</Text>
                        </View>
                    </>
                ) : ( 
                    <></>
                )}
                <View style={styles.styleSection}>
                    {devisWithoutSinistre
                        ? <Text style={{ ...styles.labelText, ...styles.textWeight }}>Réf. dossier :</Text>
                        : <Text style={styles.labelText}>Réf. sinistre</Text>
                    }
                    <Text style={styles.labelText}>{ref_sinistre}</Text> {/*Attente réponse Jodie si ref.dossier = ref.sinistre*/}
                </View>
            </View>
            <View style={{ ...styles.tableColumn, borderRight: 'none' }}>
                {!devisWithoutSinistre &&
                    <View style={styles.styleSection}>
                        <Text style={styles.labelText}>Type de sinistre :</Text>
                        <Text style={styles.labelText}>{type_sinistre}</Text>
                    </View>
                }
                {!hideAdresse && (
                    <View style={styles.styleSection}>
                        {devisWithoutSinistre
                            ? <Text style={{ ...styles.labelText, ...styles.textWeight }}>Client :</Text>
                            : <Text style={styles.labelText}>Assuré :</Text>
                        }
                        <View style={styles.styleSectionAdresse}>
                            <Text>{tiers}</Text>
                            <Text style={styles.labelText}>{nom_client}</Text>
                        </View>
                    </View>
                )}
                {!hideAdresse && (
                    <View style={{ gap: 15 }}>
                        <View >
                            {devisWithoutSinistre
                                ? <Text style={{ ...styles.labelText, ...styles.textWeight, width: "60%" }}>Adresse d'intervention :</Text>
                                : <Text style={styles.labelText}>Adresse du sinistre :</Text>
                            }
                            <Text style={{ fontSize: 8 }}>{rue_sinistre || adresse_client}</Text>
                            <Text style={{ fontSize: 8 }}>{compl1_sinistre || compl1_client}</Text>
                            <Text style={{ fontSize: 8 }}>{compl2_sinistre || compl2_client}</Text>
                        </View>
                        <View style={styles.styleSectionAdresseDetails}>
                            <Text style={{ fontSize: 8 }}>{cp_sinistre || code_postal_client}</Text>
                            <Text style={{ fontSize: 8 }}>{ville_sinistre || ville_client}</Text>
                            <Text style={{ fontSize: 8 }}>
                                {localisation_sinistre || localisation_client
                                    ? 'Localisation : ' + localisation_sinistre || localisation_client
                                    : ''
                                }
                            </Text>
                        </View>
                    </View>
                )}
            </View>
        </View>
    )
}

export const minusOneDay = (date: string) => {
    var dateArray: number[] = date.split("/").map(Number);
    var nouvelleDate = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
    nouvelleDate.setDate(nouvelleDate.getDate() - 1);
    
    return nouvelleDate.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' });
}

export const getPDFTableContainerStyles = () => {
    return StyleSheet.create({
        tableContainer: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            fontSize: 9,
            border: '1px solid #3a3a3a',
            borderRadius: 5,
        },
        tableColumn: {
            width: '33%',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            padding: 3,
            borderRight: '1px solid #3a3a3a',

        },
        styleSection: {
            width: '100%',
            display: "flex",
            flexDirection: 'row',
            gap: 1
        },
        labelText: {
            width: '50%',
        },
        textWeight: {
            fontWeight: 'bold',
        },
        styleSectionAdresse: {
            display: 'flex',
            flexDirection: 'column',
        },
        styleSectionAdresseDetails: {
            display: 'flex',
            flexDirection: 'row',
            gap: 15
        }
    })
}