import { useFeature } from "flagged";
import { DevisInfos } from "../../../class/devis";
import { PdfExportButton } from "../../../options/pdf-export/pdf-export-button";
import { Icon } from "../../tools/icon";
import { Dispatch, SetStateAction, useState } from "react";

interface PreHeaderProps {
    safeExit: () => void;
    isFromAffaire: boolean;
    devisInfos?: DevisInfos;
    getDocInfos: (type: 'type' | 'liste') => string;
    editable: boolean;
    isNewDevis?: boolean;
    handleShowCalculatrice: () => void;
    statusDevis?: string | number;
    setShowSendMailModal: React.Dispatch<React.SetStateAction<boolean>>;
    setShowAvertissementModal: React.Dispatch<React.SetStateAction<boolean>>;
    agreedStatut?: string;
    SetshowSaveModal: Dispatch<SetStateAction<{ isOpen: boolean; type: string; }>>;
    provenanceModal: string;
    saveQuitDevisHeader: (leave: boolean, forceLeave?: boolean) => void;
}

const PreHeader = ({
    safeExit,
    isFromAffaire,
    devisInfos,
    getDocInfos,
    editable,
    isNewDevis,
    handleShowCalculatrice,
    statusDevis,
    setShowSendMailModal,
    setShowAvertissementModal,
    SetshowSaveModal,
    agreedStatut,
    provenanceModal,
    saveQuitDevisHeader
}: PreHeaderProps) => {

    const isDocumentListeOPT = useFeature('DocumentListe');
    const isPDFExport = useFeature('PDFExport');
    const isSendDevis = useFeature('sendDevis');

    const handleShowAvertissemendModal = () => {
        const validStatus = ['2', '4', '7', '5', '3'];

        statusDevis === '1'
            ? setShowSendMailModal(true)
            : validStatus.includes(String(statusDevis))
            && setShowAvertissementModal(true);
    };

    const handleSaveQuitBtn = () => {
        if (devisInfos && devisInfos.budget_heure !== undefined &&
            devisInfos.budget_heure <= 0) {
            handleShowCalculatrice();
            return;
        }
        statusDevis && statusDevis === "2" && (agreedStatut === "0" || !agreedStatut)
            ? SetshowSaveModal({ isOpen: true, type: provenanceModal })
            : saveQuitDevisHeader(true);
    }

    return (
        <>
            <div className="px-2 px-xl-4 entete bg-bleu-clair1">
                <div className="d-md-flex align-items-center justify-content-between height-search">

                    <button onClick={safeExit} className={`nobt my-2 m-md-0 retour ${(isFromAffaire && !devisInfos) ? 'opacity-50' : ''}`}>
                        <span className="bg-bleu-actif btn-rounded me-2 blanc px-2 border-0 d-inline-block">
                            <span className="fa fa-chevron-left"></span>
                        </span>
                        {isFromAffaire
                            ? <div className="bleu-actif font-bold text-uppercase display-19">Retour à l'affaire</div>
                            : <div className="bleu-actif font-bold text-uppercase display-19">Retour liste des {isDocumentListeOPT ? getDocInfos('liste') : 'devis'}</div>
                        }
                    </button>

                    <div className="d-flex align-items-center mb-1 mb-md-0">

                        <div className="mx-2 mb-1 mb-md-0">
                            {editable && !isNewDevis &&
                                <>
                                    <button className="btn btn-primary mx-1" onClick={handleShowCalculatrice}>
                                        <span className="hidemobile">Calculatrice</span> <span className="fa fa-calculator ms-0 ms-xl-1"></span>
                                    </button>
                                    <button className="hideme btn btn-orange me-0 me-md-2" >
                                        Transférer<span className="fa fa-check ms-1"></span>
                                    </button>
                                </>
                            }
                            {!isNewDevis &&
                                <button className="btn btn-primary mx-1" >
                                    <span className="hidemobile">Générer BDC SST</span> <span className="fa fa-file-alt ms-0 ms-xl-1"></span>
                                </button>
                            }
                        </div>

                        <div className="d-flex align-items-center mb-1 mb-md-0 mx-2">
                            {!isNewDevis && isPDFExport &&
                                <PdfExportButton devisInfos={devisInfos} />
                            }
                            {editable && !isNewDevis &&
                                <button className="btn btn-primary mx-0 mx-xl-1" onClick={() =>
                                    isSendDevis && handleShowAvertissemendModal()
                                }>
                                    <span className="hidemobile">Envoyer</span>
                                    <span className="fa fa-share ms-0 ms-xl-1"></span>
                                </button>
                            }
                        </div>
                    </div>

                    {!isNewDevis && editable &&
                        <div className="d-lg-flex align-items-center justify-content-center">
                            <button onClick={safeExit} className="btn btn-secondary mb-2 mb-md-0 me-2">
                                Annuler
                            </button>
                            <button className="btn btn-vert mb-2 mb-md-0" onClick={handleSaveQuitBtn}>
                                Sauvegarder et quitter
                                <Icon icon='floppy-disk ms-2 blanc' />
                            </button>
                        </div>
                    }

                </div>
            </div>
        </>
    )
}

export default PreHeader;