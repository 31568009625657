import { DevisLigneEdit } from "../class/devis";
import { DevisInfos, DevisLigne } from "../class/devis";
import { devis_type_default } from "../class/document";
import { formatPriceToNumber } from "../components/functions/formatMontant";
import { DvLigneType, devisPrefix, documentFormatter, documentInfosFormatter, documentListFormatter } from "./documentFormatter";


export const devisListFormatter = documentListFormatter;
export const devisFormatter = documentFormatter;

export const devisInfosFormatter = documentInfosFormatter;

export function devisDBFormatter(devis: DevisInfos): any {
    const formattedDevis: any = { // On respecte le meme ordre et nommage que la classe php Devis
        id: devis.id,
        user_id: devis.creator_id,
        user_id_modification: devis.modifier_id,
        user_name_creation: devis.user_name_creation,
        user_name_modification: devis.user_name_modification,
        statut: devis.statut.selection?.code || '',
        date_envoi: devis.date_envoi || null,
        date_accord: devis.date_accord || null,
        provenance_accord: devis.provenance_accord || null,
        num_piece: (devis.is_devis && (devis.num_piece+'').slice(0, 3) === devisPrefix ? devis.num_piece.slice(3) : devis.num_piece),
        reference: devis.ref,
        date: devis.creation_date,
        date_modification: devis.modification_date,
        commentaire: devis.commentaire,
        metier: devis.metier.selection?.code || '',
        sous_metier: devis.sous_metier.selection?.code || '',
        age_batiment: devis.age_batiment,
        budget_heure: formatPriceToNumber(devis.budget_heure) || 0,
        taux_horaire: formatPriceToNumber(devis.taux_horaire) || 0,
        remise: formatPriceToNumber(devis.remise_global) || 0,
        montant_total: formatPriceToNumber(devis.montant_total) || 0,
        envoye: devis.envoye,
        devis_type: devis.devis_type.selection?.code || devis_type_default,
        lignes: [],
        affaire: devis.affaire?.id,
        is_from_divalto: devis.is_from_divalto,
    };

    return formattedDevis;
}

export const devisDBLignesFormatter = (lignes: (DevisLigne | DevisLigneEdit)[], devisID: number) =>
    lignes.map(ligne => devisDBLigneFormatter(ligne, devisID));

export function devisDBLigneFormatter(ligne: DevisLigne | DevisLigneEdit, devisID: number) {
    const formattedDevis = {  // On respecte le meme ordre et nommage que la classe php Ligne_devis
        id: ligne.id,
        id_devis: devisID, // You need to provide the corresponding value
        article_ref: ligne.article_ref,
        designation: ligne.designation,
        ordre: ligne.ordre,
        prix_vente: formatPriceToNumber(ligne.prix_vente),
        tva: ligne.TVA || 0,
        quantite: formatPriceToNumber(ligne.quantite),
        unite: ligne.unite,
        remise: formatPriceToNumber(ligne.remise),
        commentaire: ligne.is_titre ? ligne.titre.content : ligne.commentaire,
        titre_type: ligne.titre.type,
        type: getLigneType(ligne),
        montant_total: ligne.montant_total,
    };
    return formattedDevis;
}

const getLigneType = (l: DevisLigne | DevisLigneEdit) =>
    l.is_article ? DvLigneType.article
        : l.is_commentaire ? DvLigneType.commentaire
            : l.is_titre ? DvLigneType.titre + '-' + l.titre.type
                : l.is_photo ? DvLigneType.photo
                    : l.is_nota ? DvLigneType.nota
                        : l.is_lot ? DvLigneType.lot
                            : l.is_lot_content ? DvLigneType.lot_content + '-' + l.lot_id
                                : DvLigneType.article;

export function devisLigneEditFormatter(ligne: DevisLigne): DevisLigneEdit {
    const formattedLigne: DevisLigneEdit = {
        ...ligne,
        prix_vente: ligne.prix_vente + '',
        quantite: ligne.quantite + '',
        remise: ligne.remise + '',
    };
    return formattedLigne;
}


export { devisPrefix };

