import { ChangeEvent, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import QuillToolbar, { formats, modules } from "./toolbar-editor";
import ReactQuill from "react-quill";
import { useTemplates } from "../../../hooks/useMail";
import { DevisInfos } from "../../../class/devis";
import { getPdfModelList } from "../../../options/pdf-export/models/pdf-model-list";
import { Icon } from "../../tools/icon";
import BtnTooltips from "../../tools/btn-tooltips";
import { ShowModelPdf } from "./show-pdf-model";
import { SelectDestinataire } from "./select-destinataires";
import { SelectEmailModel } from "./select-email-model";
import templateMailService from "../../../services/TemplateMailService";
import { SelectPdf } from "./select-pdf";
import { usePDFInfos } from "../../../options/pdf-export/hooks/usePDFInfos";
import { SelectPj } from "./select-pj";
import { FilesProps } from "../../../class/templateMail";
import { AvertissementModalForSendEmail } from "./avertissement-modale-email";
import devisService from "../../../services/DevisService";
import { Loadering } from "../../tools/loadering";

export interface SendMailModalProps {
    show: boolean;
    onHide: () => void;
    devisInfos?: DevisInfos;
    statusDevis?: string | number;
    showAvertissementModal: boolean;
    setShowAvertissementModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsSentEmailInProgress: (value: boolean) => void;
    isSentEmailInProgress: boolean;
    setIsEmailNotSent: (value: boolean) => void;
}

export const SendMailModal = ({ show, onHide, devisInfos, statusDevis, setIsSentEmailInProgress, isSentEmailInProgress, setIsEmailNotSent }: SendMailModalProps) => {

    const infosPDF = usePDFInfos(devisInfos?.id || -1, !!devisInfos?.is_from_divalto, devisInfos?.lignes);
    const pdfList = getPdfModelList(devisInfos, infosPDF);

    const [selectedPdfModelId, setSelectedPdfModelId] = useState(pdfList[0]?.id || '');
    const [selectedPdfName, setSelectedPdfName] = useState('');
    const [showModelPdf, setShowModelPdf] = useState(false);
    const [selectedEmailName, setSelectedEmailName] = useState('');
    const [selectValueInput, setSelectValueInput] = useState('');
    const selectValueInputArray = selectValueInput.split(' ');
    const [ccEmails, setCcEmails] = useState<string>('');
    const ccEmailsArray = ccEmails.split(' ');
    const [isValidEmailDestinataire, setIsValidEmailDestinataire] = useState(true);
    const [isValidEmailCopy, setIsValidEmailCopy] = useState(true);
    const [selectDestinataire, setSelectDestinataire] = useState('');
    const [selectFiles, setSelectFiles] = useState<FilesProps[]>([]);
    const [showAvertissementModalForSendEmail, setShowAvertissementModalForSendEmail] = useState(false);

    const [loading, setLoading] = useState(false);
    const setIsLoading = (isLoading: boolean) => setLoading(isLoading);
    const timestamp = Date.now();

    const { montant_total, num_piece } = devisInfos || {};
    const affaire = devisInfos?.affaire;
    const { numero_dossier, nom_client, nom_do, ref_sinistre, nom_expert,
        ref_expert_cie_assurance, nom_charge_affaire, agence_email,
        agence_tel, nom_agence } = affaire || {};

    const templateMails = useTemplates() || [];
    const templateOneMail = templateMails.find(template => template.name === selectedEmailName);

    const destinairePresentInAffaire = affaire
        ? [
            { id: '0', label: 'DOs Compagnie assurance', email: affaire.mail_assu_do },
            { id: '1', label: 'DOs autres', email: affaire.mail_autre_do },
            { id: '2', label: 'DOs expert', email: affaire.mail_expert_do },
            { id: '3', label: 'DOs plateforme', email: affaire.mail_plateforme_do },
            { id: '4', label: 'Assuré', email: affaire.mail_client },
        ].filter(dest => dest.email !== null && dest.email !== undefined && dest.email !== '')
        : [];

    const emailSelectDestinataire = destinairePresentInAffaire.find(dest => dest.label === selectDestinataire)?.email || '';
    let acompte = montant_total?.toString();
    acompte = montant_total ? (montant_total * 0.4)?.toFixed(2) : '';

    const variables = {
        PRJAP__AFFAIRE: numero_dossier,
        CLI__NOM: nom_client,
        MLOG_ADR2TB__NOM___1: nom_do,
        MLOG_PRJAP__MLOG_CIEREF: ref_sinistre,
        MLOG_ADR2TB__NOM___2: nom_expert,
        MLOG_PRJAP__MLOG_CIEEXPERTREF: ref_expert_cie_assurance,
        DTRENT__VERSIONDEVISORIPINO: num_piece,
        DTRENT__VERSIONDEVISNO: num_piece,
        ETS__EMAIL: agence_email,
        VRP__NOM: nom_charge_affaire,
        ETS__TEL: agence_tel,
        CALCUL: acompte,
    };

    const contentMail = replaceVariables(templateOneMail?.content || "", variables);

    const handleSelectEmailName = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedEmailName(e.currentTarget.value);
    }

    const handleSelectModelPdf = (e: React.ChangeEvent<HTMLSelectElement>) =>
        setSelectedPdfModelId(e.currentTarget.value);

    const handleCcAddressesChange = (e: ChangeEvent<HTMLInputElement>) =>
        setCcEmails(e.currentTarget.value);

    const handleKeyDown = (
        emailArray: string[],
        setEmailState: (isValid: boolean) => void,
        e: React.KeyboardEvent<HTMLInputElement>,
        valueInput: string,
        setValueState: (updateFunction: (prevEmails: string) => string) => void
    ) => {
        validateEmailAddresses(emailArray, setEmailState);
        e.key === ' ' && valueInput !== '' &&
            setValueState((prevEmails) => prevEmails + ' ; ')
    }

    const handleSelectValue = (e: React.ChangeEvent<HTMLInputElement>) =>
        setSelectValueInput(e.currentTarget.value);

    const handleSelectDestinataire = (e: React.ChangeEvent<HTMLSelectElement>) =>
        setSelectDestinataire(e.currentTarget.value);

    const handleSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFilesList = e.target.files;

        if (selectedFilesList) {
            const newFilesArray = Array.from(selectedFilesList).map(file => ({
                name: file.name,
                type: file.type,
                file: file,
                size: file.size
            }));

            setSelectFiles(prevFiles => [...prevFiles, ...newFilesArray]);
        }
    };

    const handleRemoveFile = (indexToRemove: number) => {
        setSelectFiles(prevFiles => {
            const newFilesArray = [...prevFiles];
            newFilesArray.splice(indexToRemove, 1);
            return newFilesArray;
        });
    };

    const handleAddAttachment = () => {
        if (!selectedPdfModelId) {
            return;
        }
        try {
            const selectedPdfModel = pdfList.find((model) => model.id === +selectedPdfModelId);
            selectedPdfModel ? setSelectedPdfName(selectedPdfModel.name) : console.error("Aucun modèle trouvé avec l'ID sélectionné");

        } catch (error) {
            console.log("Erreur lors de l'ajout de la pièce jointe :", error);
        }
    }

    useEffect(() => {
        handleAddAttachment();
        if (selectedPdfModelId === '') {
            setSelectedPdfName('')
        };
        setShowModelPdf(selectedPdfModelId !== '')
    }, [selectedPdfModelId]);


    return (
        <Modal
            setshow={'modal-fullscreen-md-down'}
            className="custom-modal-size"
            size={'lg'}
            scrollable={true}
            fullscreen={'md-down'}
            centered
            show={show}
            onHide={onHide}>
            <Modal.Header closeButton >
                <h4>
                    <span className="fa fa-paper-plane me-2"></span>
                    <span>Envoi du devis par mail</span>
                </h4>
            </Modal.Header>
            <Modal.Body>
                {isSentEmailInProgress ?
                    <div className="height-11">
                        <Loadering color='bleu' loadingText='Envoi en cours' formodal />
                    </div>
                    : <div className="d-flex flex-column gap-3">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-4">
                                <label><span className="fa fa-envelope me-2"></span>Model d'email</label>
                            </div>
                            <div className="col-md-8">
                                <SelectEmailModel
                                    handleSelectEmailName={handleSelectEmailName}
                                    selectedEmailName={selectedEmailName}
                                    templateMails={templateMails}
                                />
                            </div>
                        </div>

                        <div className="row d-flex align-items-center">
                            <div className="col-md-4">
                                <label><span className="fa fa-file-pdf me-2"></span>Model de PDF</label>
                            </div>
                            <div className="col-md-8">
                                <SelectPdf
                                    selectedPdfModelId={selectedPdfModelId}
                                    handleSelectModelPdf={handleSelectModelPdf}
                                    pdfList={pdfList}
                                />
                            </div>
                        </div>
                        <div className="row align-items-cstartnter">
                            <div className="pt-2 col-md-4">
                                <label><span className="fa fa-envelope me-2"></span>Destinataire</label>
                            </div>
                            <div className="col-md-8">
                                <SelectDestinataire
                                    affaire={affaire}
                                    handleSelectValue={handleSelectValue}
                                    ccEmails={ccEmails}
                                    handleCcAddressesChange={handleCcAddressesChange}
                                    selectValueInput={selectValueInput}
                                    selectDestinataire={selectDestinataire}
                                    handleSelectDestinataire={handleSelectDestinataire}
                                    destinairePresentInAffaire={destinairePresentInAffaire}
                                    statusDevis={statusDevis}
                                    isValidEmailDestinataire={isValidEmailDestinataire}
                                    isValidEmailCopy={isValidEmailCopy}
                                    handleKeyDown={handleKeyDown}
                                    selectValueInputArray={selectValueInputArray}
                                    setIsValidEmailDestinataire={setIsValidEmailDestinataire}
                                    setSelectValueInput={setSelectValueInput}
                                    ccEmailsArray={ccEmailsArray}
                                    setIsValidEmailCopy={setIsValidEmailCopy}
                                    setCcEmails={setCcEmails}
                                />
                            </div>
                        </div>
                        <AvertissementModalForSendEmail
                            show={showAvertissementModalForSendEmail}
                            onHide={() => setShowAvertissementModalForSendEmail(false)}
                            selectedEmailName={selectedEmailName}
                            selectedPdfModelId={selectedPdfModelId}
                        />
                        {selectedEmailName !== '' && (
                            <div className='editor-container row h-100 g-0'>
                                <QuillToolbar func={[]} />
                                <ReactQuill
                                    theme="snow"
                                    value={contentMail}
                                    modules={modules}
                                    formats={formats}
                                />
                            </div>
                        )}
                        <>
                            {selectedPdfName && (
                                <div className="border border-primary rounded p-2 text-primary mt-2 d-flex align-items-center">
                                    <Icon icon="file-export fa-solid me-2 bleu" /> PDF: {selectedPdfName}
                                    <BtnTooltips
                                        tooltiplabel={'Supprimer le PDF'}
                                        buttonstyle={'nobt fa fa-circle-xmark display-14 rouge m-1'}
                                        onClick={() => {
                                            setSelectedPdfModelId('');
                                            setSelectedPdfName('');
                                        }}
                                    />
                                </div>
                            )}
                            <SelectPj
                                handleSelectFiles={handleSelectFiles}
                                selectFiles={selectFiles}
                                handleRemoveFile={handleRemoveFile}
                            />
                        </>
                        <div className="pdf-preview">
                            <ShowModelPdf showModelPdf={showModelPdf} pdfList={pdfList} setShowModelPdf={setShowModelPdf} selectedPdfModelId={selectedPdfModelId} />
                        </div>
                    </div>
                }
            </Modal.Body>
            {
                !isSentEmailInProgress &&
                <Modal.Footer>
                    <Button variant='secondary' onClick={onHide}>
                        Annuler
                    </Button>
                    <Button variant='vert hidemobile' onClick={sendEmailWithAttachments}>
                        Envoyer
                    </Button>
                </Modal.Footer>
            }
        </Modal >
    );

    function replaceVariables(content: string, variable: { [key: string]: string | undefined }) {
        let modifiedContent = content;

        typeof content === 'string' && (
            Object.entries(variable).forEach(([key, value]) => {
                const regex = new RegExp(`\\[${key}]`, 'g');
                if (value !== undefined) {
                    modifiedContent = modifiedContent.replace(regex, value);
                }
            })
        )

        return modifiedContent;
    }

    function formatEmailArray(emailArray: string[]) {
        let formattedArray = emailArray
            .filter(email => email !== ";");

        if (formattedArray.length === 1 && formattedArray[0] === "") {
            formattedArray = [];
        }

        return formattedArray;
    }

    function sendEmailWithAttachments() {

        setIsSentEmailInProgress(true);

        const devisId = devisInfos?.id?.toString();
        const selectedPdfModel = pdfList.find((model) => model.id === +selectedPdfModelId);
        const pdfNameWithDevisId = `${devisId ?? ''}_${selectedPdfModel?.name ?? ''}`;

        if (!selectedPdfModel ||
            !selectedPdfModelId ||
            !templateOneMail ||
            !pdfNameWithDevisId ||
            !nom_client ||
            !nom_agence ||
            !numero_dossier) {
            setShowAvertissementModalForSendEmail(true)
            setIsSentEmailInProgress(false)
            return
        };

        const fileNames = selectFiles.map((fileName) => fileName.name);
        const ccEmailsArrayFormatted = formatEmailArray(ccEmailsArray);
        const selectedValueInputFormatted = formatEmailArray(selectValueInputArray);

        const resetState = () => {
            setSelectFiles([]);
            onHide();
            setSelectValueInput('');
            setCcEmails('');
            setSelectedPdfModelId('');
            setSelectedPdfName('');
            setSelectedEmailName('')
            setIsSentEmailInProgress(false);
        };

        return templateMailService.sendEmailRequest(
            selectDestinataire !== '' ? emailSelectDestinataire : selectedValueInputFormatted,
            ccEmailsArrayFormatted,
            setIsLoading,
            pdfNameWithDevisId,
            contentMail,
            fileNames,
            timestamp,
            selectedPdfModel,
            pdfNameWithDevisId,
            selectFiles,
        )
            .then((response) => {
                if (response.status !== 'error') {
                    return templateMailService.addEmailToDocuware(
                        nom_client,
                        nom_agence,
                        numero_dossier,
                        contentMail,
                        pdfNameWithDevisId,
                        fileNames,
                        selectFiles,
                        timestamp,
                        selectedEmailName
                    )
                        .then(() => {
                            console.log("Email envoyé avec succès ✅");
                            return devisService.sendDevisXML(devisInfos?.id as number)
                                .then(() => {
                                    resetState();
                                    console.log("Devis XML envoyé avec succès ✅");
                                })
                                .catch(error => console.error("Erreur lors de l'envoi du devis XML :", error));
                        });
                } else {
                    setIsSentEmailInProgress(false)
                    setIsEmailNotSent(true);
                }
            })
            .catch(error => console.error("Une erreur s'est produite :", error));
    }

    function validateEmailAddresses(emailArray: string[], setEmailState: (isValid: boolean) => void) {
        const filteredEmails = emailArray.filter((email => email !== ";"));

        const isValidEmail = filteredEmails.every(address => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{1,2}$/;
            return emailRegex.test(address)
        })

        setEmailState(isValidEmail)
    }
}; 