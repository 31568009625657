interface SelectPdfProps {
    selectedPdfModelId: number | string;
    handleSelectModelPdf: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    pdfList: {
        id: number;
        rendu: JSX.Element;
        name: string;
    }[];
}

export const SelectPdf = ({ selectedPdfModelId, handleSelectModelPdf, pdfList }: SelectPdfProps) => {

    return (
        <select
            value={selectedPdfModelId}
            onChange={handleSelectModelPdf}
            className="form-select bg-bleu-clair1 border-0 bleu"
            style={{ flex: 1 }}
        >
            <option value="">Modèle PDF</option>
            {pdfList.map((model, index) => (
                <option key={index} value={model.id}>
                    {model.name}
                </option>
            ))}
        </select>
    )
};