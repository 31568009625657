

import { Text, StyleSheet, View } from '@react-pdf/renderer';
import formatMontant from '../../../components/functions/formatMontant';
import { DevisLigneTitreTypes } from '../../../components/forms/editiondevis/devis-ligne/titre-types';
import { ValueOf } from '../../../class/tools';

export interface PDFLigneTitreProps {
    text: string;
    montant_total?: number;
    hidePrice?: boolean;
    typeTitre: DevisLigneTitreTypes
}

export const PDFLigneTitre = ({ text, montant_total, hidePrice, typeTitre }: PDFLigneTitreProps) => {
    const styles = pdfLigneCommentaireStylesLocal;

    const titreStyle = styles[`titre_${typeTitre}`];
    const titleWithStyle = [1, 2, 3] as const;

    const montantStyle = styles[`montant_${(titleWithStyle).includes(+typeTitre as any) ? typeTitre as Extract<DevisLigneTitreTypes, ValueOf<typeof titleWithStyle>> : 3}`]; // typeTitre peut être une string, d'ou le +

    return (
        <View style={styles.ligne_titre}>
            {/* ⚠️ Mettre le sous-titre de type 3 en italique */}
            <Text style={[styles.commentaire, titreStyle]}>{text || ' '}</Text>
            {/* ⚠️ Supprimer le symbole € */}
            {!hidePrice && <Text style={[styles.montant_total, montantStyle]}>{formatMontant(montant_total, false)}</Text>}
        </View>
    )
};

export const pdfLigneCommentaireStylesLocal = StyleSheet.create({

    ligne_titre: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 10,
        textTransform: 'uppercase'
    },
    commentaire: {
        fontWeight: 'bold',
        paddingVertical: 4,
    },
    montant_total: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        fontWeight: "black",
        paddingRight: 40
    },
    titre_1: {
        fontSize: 17,
        fontWeight: "bold",
        textDecoration: 'underline',
    },
    titre_2: {
        fontSize: 14,
        color: '#000080',
        fontWeight: "bold",
        paddingLeft: 10,
    },
    titre_3: {
        fontSize: 10,
        fontWeight: "semibold",
        fontStyle: 'italic',
        paddingLeft: 17,
    },
    titre_4: {
        fontSize: 10,
        fontWeight: "semibold",
        fontStyle: 'italic',
        paddingLeft: 24,
    },
    titre_5: {
        fontSize: 10,
        fontWeight: "semibold",
        fontStyle: 'italic',
        paddingLeft: 30,
    },
    montant_1: {
        fontSize: 17,
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
    montant_2: {
        fontSize: 14,
        color: '#000080',
        fontWeight: 'bold',
    },
    montant_3: {
        fontSize: 10,
    }
});

