import { Modal, Button } from "react-bootstrap";
import { Icon } from "../../components/tools/icon";
import "./style/pdf-export.css"
import { getPdfModelList } from "./models/pdf-model-list";
import { cloneElement, useMemo, useState } from "react";
import { DevisInfos } from "../../class/devis";
import { PDFDownloadLink, PDFViewer, pdf } from "@react-pdf/renderer";
import { toValidFilename } from "../../components/functions/textManager";
import { usePDFInfos } from "./hooks/usePDFInfos";


interface PdfExportModalProps {
    show: boolean;
    devisInfos: DevisInfos;
    onConfirm: () => void;
    onCancel: () => void;
}

export const PdfExportModal = ({ show, devisInfos, onConfirm, onCancel }: PdfExportModalProps) => {

    const infosPDF = usePDFInfos(devisInfos.id, devisInfos.is_from_divalto, devisInfos.lignes);

    // const devisInfosWithCode = usePDFCodeOp(devisInfos.id, devisInfos.envoye, devisInfos.lignes)
    // console.log('devisInfosWithCode', devisInfosWithCode)
    const pdfList = useMemo(() => getPdfModelList(devisInfos, infosPDF), [show, infosPDF]);
    // const pdfList = useMemo(() => getPdfModelList(devisInfosWithCode), [show, devisInfosWithCode]);

    // console.log('pdfList', pdfList)
    const loadAllPDF = devisInfos.lignes.length <= 15; // A augmenter si on veut charger tous les pdfs à partir de x lignes
    const [selectedModelId, setselectedModelId] = useState(pdfList[0].id);
    const selectedModel = pdfList.filter(m => m.id === selectedModelId)[0];
    const fullFileName = useMemo(() => {
        const today = new Date();
        const fileName = devisInfos.ref || 'Devis';
        return fileName && toValidFilename(fileName) + ' ' + today.toLocaleDateString().replace(/[\/:]/g, '-') + '.pdf';
    }, [devisInfos.ref]);

    const DownloadBtn = useMemo(() => {
        if (!loadAllPDF) return (
            <PDFDownloadLink document={cloneElement(selectedModel?.rendu)}
                fileName={fullFileName}
                className="PDFDownloadLink">
                <Button variant='primary'>Télécharger</Button>
            </PDFDownloadLink>)

        else return (
            <div className="PDFDownloadLink">
                <Button variant='primary' onClick={downloadPdf}>
                    Télécharger
                </Button>
            </div>
        )
    }, [selectedModel])

    return (
        <Modal show={show} onHide={onCancel} centered dialogClassName="modal-fullscreen-md-down modal-xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className='bleu'>
                        <Icon icon="file-export fa-solid me-2 bleu" />
                        Impression PDF
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="PDFModalSubHeader">
                    <select value={selectedModelId}
                        onChange={e => setselectedModelId(Number(e.currentTarget.value))}
                        className="form-select bg-bleu-clair1 border-0 bleu">
                        {pdfList.map(model =>
                            <option key={model.id} value={model.id}>
                                {model.name}
                            </option>
                        )}
                    </select>
                    {DownloadBtn}
                </div>
                <br />
                {pdfList.filter(m => loadAllPDF || m.id === selectedModelId).map(Model =>
                    <div key={Model.id} className={Model.id === selectedModelId ? '' : ' hideme'}>
                        <PDFViewer className="PDFViewer">
                            {Model.rendu}
                        </PDFViewer>
                    </div>)}
            </Modal.Body>
        </Modal>
    )

    async function downloadPdf(event: any) {
        event.preventDefault(); // prevent page reload
        const blob = await pdf(
            selectedModel.rendu
        ).toBlob();
        const blobUrl = URL.createObjectURL(blob);

        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = fullFileName;
        downloadLink.click();
        URL.revokeObjectURL(blobUrl);
    }

    /*<Modal.Footer>
    </Modal.Footer>*/
}