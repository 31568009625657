import { Button, Modal } from "react-bootstrap";

interface AvertissementModalProps {
    show: boolean;
    onHide: () => void;
    statusDevis?: string | number;
    setShowSendMailModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AvertissementModal = ({ show, onHide, statusDevis, setShowSendMailModal }: AvertissementModalProps) => {

    let title: JSX.Element | string = '';
    let prompt = "";
    let text: JSX.Element | string = '';
    let isRed = '';

    switch (statusDevis) {
        case '2':
        case '4':
        case '7':
            title = <h4 className="orange"><span className="fa fa-triangle-exclamation me-2"></span>Devis déjà envoyé</h4>;
            text = (<div>Poursuivre ?</div>);
            isRed = '';
            break;
        case '3':
            title = (<h4 className="rouge"><span className="fa fa-triangle-exclamation me-2 rouge"></span>Le statut de votre devis est Annulé.</h4>);
            text = (<div><p>Si vous souhaitez envoyer le devis au DO ou à l'assuré, vous devez changer le statut à 'En cours'.</p><p className="m-0">Poursuivre ?</p></div>);
            isRed = '';
            break;
        case '5':
            title = (<h4 className="rouge"><span className="fa fa-triangle-exclamation me-2 rouge"></span>Attention</h4>);
            text = (<div><p>Votre devis doit être validé avant envoi</p></div>);
            isRed = 'rouge';
            break;
        default:
            break;
    }

    function onConfirmSafe() {
        setShowSendMailModal(true);
        onHide();
    }

    return (
        <Modal show={show} onHide={onHide} centered >
            <Modal.Header className="p-3 rouge" closeButton>
                {title}
            </Modal.Header>
            <Modal.Body className="d-flex flex-column gap-2">
                <div className={`d-flex align-items-center `}>
                    {text}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {statusDevis === '5' ?
                    <Button variant='danger' onClick={onHide}>
                        Stop
                    </Button>
                    :
                    <>
                        <Button variant='secondary' onClick={onHide}>
                            Annuler
                        </Button>
                        <Button variant={isRed ? 'danger' : 'vert'} onClick={onConfirmSafe} autoFocus>
                            Confirmer
                        </Button>
                    </>
                }
            </Modal.Footer>
        </Modal>

    )
}
