import { useState, useEffect } from 'react';

const useScrollPosition = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = document.querySelector('.scrollbar')?.scrollTop || 0;
            setIsScrolled(scrollPosition > 10);
        };

        const scrollbarElement = document.querySelector('.scrollbar');
        scrollbarElement?.addEventListener('scroll', handleScroll);

        scrollbarElement?.scrollTo(0, 0);

        return () => {
            scrollbarElement?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return isScrolled;
};

export default useScrollPosition;
