import { useCustomData } from "../../../hooks/customs/useCustomData";
export interface InfosPDF {
    // client: { fax: string };
    // photos: { [key: string]: string };
    code_op: CodeOp;
    code_tva: CodeTva;
}
export interface CodeOp {
    [key: string]: string;
}
export interface CodeTva {
    [key: string]: number | '';
}

export const usePDFInfos = (devisId: number, is_divalto: boolean, refresher:any): InfosPDF | undefined => {
    // useCustomData(`pdf/infos?devisId=${devisId}&gescom=${is_divalto?1:0}`, false, refresher) as InfosPDF | undefined;
    const codeOp = usePDFCodeOp(devisId, is_divalto, refresher);
    const codeTva = usePDFCodeTVA(devisId, is_divalto, refresher);

    if (!codeOp) return undefined;
    if (!codeTva) return undefined;

    const infosPDF: InfosPDF = {
        code_op: codeOp,
        code_tva: codeTva
    }

    return infosPDF;
}


export const usePDFCodeOp = (devisId: number, is_divalto: boolean, refresher:any) => 
    useCustomData(`devis/codeop?devisId=${devisId}&divalto=${is_divalto?1:0}`, codeOpFormatter, refresher, devisId !== -1) as CodeOp | undefined;


export function codeOpFormatter (codeOp: any): CodeOp {
    return Object.fromEntries(Object.entries(codeOp).map(([id, code]) => [parseInt(id)+'', code || ''])) as CodeOp;
};

export const usePDFCodeTVA = (devisId: number, is_divalto: boolean, refresher:any) => 
    useCustomData(`devis/codetva?devisId=${devisId}&divalto=${is_divalto?1:0}`, codeTVAFormatter, refresher, devisId !== -1) as CodeTva | undefined;


export function codeTVAFormatter (codeTVA: any): CodeTva {
    return Object.fromEntries(Object.entries(codeTVA).map(([tva, code]) => [parseFloat(tva)+'', code || ''])) as CodeTva;
};
