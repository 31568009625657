import { Dispatch, useCallback, useEffect, useMemo, useReducer, useState, KeyboardEvent as ReactKeyboardEvent } from "react"
import { DevisLigne, DevisLigneEdit } from "../../../../class/devis"
import DevisLigneType from "./devis-ligne-type"
import { Article, LotArticles, Nota } from "../../../../class/articles"
import { ActionDevis } from "../devis-infos-reducer"
import { devisLigneReducer } from "./devis-ligne-reducer"
import DevisService from "../../../../services/DevisService"
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd"
import { devisLigneEditFormatter } from "../../../../formatters/devisFormatter"
import DevisLigneCommentaire from "./devis-ligne-commentaire"
import DevisLigneTitre from "./devis-ligne-titre"
import { DevisLigneArticle } from "./devis-ligne-article"
import { DevisLigneBtns, DragNDropBtn } from "./devis-ligne-btns"
import { DevisLigneLot } from "./devis-ligne-lot"
import { formatPriceToNumber } from "../../../functions/formatMontant"
import DevisLignePhoto from "./devis-ligne-photo"
import classNames from "classnames"
import DevisLigneNota from "./devis-ligne-nota"
import { SelectionManager } from "../devis-selection-reducer"
import { cancelHigherEvent, createLignesFromArticles, duplicateLignes } from "../devis-body"
import { modalConfirmId } from "../../../tools/confirm-modal"


interface LigneArticleProps {
    devisLigneStart: DevisLigne;
    articles: Article[];
    lots?: LotArticles[];
    notaList: Nota[];
    TVAListe: number[];
    devisInfosDispatch: Dispatch<ActionDevis>;
    setShowModal?: (id: number) => void;
    manageSafeExit: (key: string, isSafe: boolean) => void;
    devisEditable: boolean;
    devisId: number;
    lignesInfos?: { index: number, total: number, isEndOfLot: boolean, isInLot: boolean, isHidden: boolean };
    provided?: DraggableProvided;
    snapshot?: DraggableStateSnapshot;
    openSelectLotContentModal: (lot: LotArticles, saveEditLot: (selectedArticle: LotArticles) => void) => void;
    selectArticlePhoto?: (show: boolean, p: string) => void;
    selectionManager?: SelectionManager;
    selectLinesAndChildren?: (startLine: DevisLigneEdit) => number[];

}

export const LigneDevis = ({ devisInfosDispatch, setShowModal, manageSafeExit, openSelectLotContentModal, selectArticlePhoto = () => { },
    articles, notaList, lots = [], devisLigneStart, devisEditable, devisId, lignesInfos, selectionManager, provided, snapshot, TVAListe, selectLinesAndChildren }: LigneArticleProps) => {


    const [devisLigne, dispatch] = useReducer(devisLigneReducer, devisLigneEditFormatter(devisLigneStart));

    const selectedArticle = useMemo(() => (
        lots.filter(a => a.ref === devisLigne.article_ref)[0] as LotArticles | undefined
    ), [devisLigne.article_ref]);

    // Empeche le resfresh quand c'est l'ordre qui change
    const refreshOnDevisChange = JSON.stringify({ ...devisLigneStart, ordre: 0 });
    useEffect(() => {
        dispatch({ key: 'all', value: devisLigneStart });
    }, [refreshOnDevisChange]); // eslint-disable-line react-hooks/exhaustive-deps

    const { id, article_ref, commentaire, titre, is_commentaire, is_article, is_titre, is_photo, is_lot, is_nota, is_lot_content } = devisLigne;

    const isNewLigne = id === -1;
    const [isEdit, setIsEdit] = useState(isNewLigne);
    const [isMenuShown, setIsMenuShown] = useState(false);

    const isValidable = is_commentaire || is_titre || is_lot_content || is_nota
        || (is_photo && !!commentaire)
        || (is_article && articles?.filter(a => a.ref === article_ref).length === 1)
        || (is_lot && lots?.filter(l => l.is_lot && l.ref === article_ref).length === 1);

    useEffect(() => {
        manageSafeExit(id + '', !isEdit || !isValidable);
    }, [!isEdit || !isValidable])  // eslint-disable-line react-hooks/exhaustive-deps

    const cancelEdit = () => {
        if (!isNewLigne) setIsEdit(false);
        dispatch({ key: 'all', value: devisLigneStart });
    }
    const setEdit = () => setIsEdit(true);


    const saveEditLot = useCallback((selectedArticle: LotArticles) => { // createLignesFromArticles TODO
        if (selectedArticle.articles.length === 0) return setEdit();
        createLignesFromArticles(selectedArticle.articles, devisLigne.ordre, devisId, devisInfosDispatch)
    }, [devisLigne, devisId])

    const saveEditPhoto = useCallback((devisLigne: DevisLigneEdit) => {
        if (devisLigneStart.article_ref === devisLigne.article_ref) return; // Si l'image n'a pas changé
        if (devisLigneStart.article_ref) DevisService.delArticlePhoto(devisLigneStart.commentaire, devisLigneStart.article_ref, devisLigneStart.id);
        if (devisLigne.photoFile) DevisService.setArticlePhoto(devisLigne.commentaire, devisLigne.article_ref, devisLigne.photoFile);
    }, [devisLigne, devisId])

    const saveEdit = useCallback(() => {
        if (!isValidable) return;
        setIsEdit(false);
        if (devisLigne.is_photo) saveEditPhoto(devisLigne);
        const newLigne = cleanDevisLigne(devisLigne);
        if (isNewLigne) selectedArticle?.is_lot
            ? openSelectLotContentModal(selectedArticle, saveEditLot)
            : DevisService.setDevisLigne(newLigne, devisId)
                .then(newId => {
                    if (newId) newLigne.id = newId;
                    devisInfosDispatch({ key: 'addLigne', value: newLigne })
                });
        else {
            devisInfosDispatch({ key: 'updateLigne', value: newLigne })
            selectedArticle
                && selectedArticle.is_lot
                && devisLigneStart.article_ref !== selectedArticle.ref
                && openSelectLotContentModal(selectedArticle, saveEditLot);
        };
    }, [isValidable, devisLigne, isNewLigne, selectedArticle, devisLigneStart, saveEditLot, devisId]);

    const duplicateLigne = () => duplicateLignes([devisLigne], devisId, devisInfosDispatch)
    const deleteLigne = () =>
        setShowModal && setShowModal(id);
    // devisInfosDispatch({ key: 'deleteLigne', value: id })


    const ToggleMenu = (val?: number) => setIsMenuShown(val ? val === 1 : !isMenuShown);
    const ToggleLigne = (ligneType: keyof DevisLigneEdit) => {
        dispatch({ key: 'ligneType', value: ligneType });
        ToggleMenu();
    };
    const classHideDraggable = classNames({
        'hideDraggable': !devisEditable,
        'hideVisibleDraggable': lignesInfos && lignesInfos.total === 1,
    });
    const ligneClass = classNames(
        'ligne-article',
        {
            'dragging': snapshot?.isDragging,
            [`devis-ligne-titre-${titre.type}`]: is_titre,
            'ligne-article-lot-content': is_lot_content,
            'ligne-article-lot': is_lot,
            'end-of-lot': lignesInfos?.isEndOfLot,
            'in-lot': lignesInfos?.isInLot,
            'hideme': lignesInfos?.isHidden,
            'selected-ligne': selectionManager?.selectedLigne?.id === id,
            'selected-lignes': selectionManager?.selectedLignes.includes(id),
        }
    );
    const CBClass = classNames(
        'bleu-actif fa-square p-1',
        selectionManager?.selectedLignes.includes(id) ? 'fa-solid' : 'fa-regular',
        { 'border border-primary': selectionManager?.lastSelectedLigneCB === id }
    );

    useEffect(() => {
        //document.body.removeEventListener('keydown', doLigneShortcut)
        selectionManager?.selectedLigne?.id === id
            ? document.body.addEventListener('keyup', doLigneShortcut)
            : document.body.removeEventListener('keyup', doLigneShortcut)
        return () => { document.body.removeEventListener('keyup', doLigneShortcut); };
    }, [selectionManager?.selectedLigne?.id])

    return (
        <li className={ligneClass}
            onClick={handleLigneClick}
            style={{ opacity: 1 }}
            {...provided?.draggableProps}
            ref={provided?.innerRef}>
            {isNewLigne ? (
                <DevisLigneType
                    isMenuShown={isMenuShown}
                    ligne={devisLigne}
                    ToggleLigne={ToggleLigne}
                    ToggleMenu={ToggleMenu}
                />
            ) : devisEditable ? (
                <div className={"colonne-1 text-center justify-content-between" + classHideDraggable}>
                    <DragNDropBtn provided={provided} />
                    <i className={CBClass} onClick={onLigneCBClick} />
                </div>
            ) : <DragNDropBtn provided={provided} hideme /> // Si aucun des cas n'est vrai, vous pouvez renvoyer null ou un composant vide
            }

            {useMemo(() => (is_article
                ? <DevisLigneArticle onKeyUp={onKeyUp} isEdit={isEdit} devisLigne={devisLigne} articles={articles} dispatch={dispatch} TVAListe={TVAListe} />
                : is_commentaire
                    ? <DevisLigneCommentaire dispatch={dispatch} commentaire={commentaire} isEdit={isEdit} onKeyUp={onKeyUp} />
                    : is_titre
                        ? <DevisLigneTitre dispatch={dispatch} devisLigne={devisLigne} isEdit={isEdit} devisInfosDispatch={devisInfosDispatch} onKeyUp={onKeyUp} />
                        : is_photo
                            ? <DevisLignePhoto dispatch={dispatch} photoName={commentaire} photoRef={article_ref} isEdit={isEdit} selectArticlePhoto={selectArticlePhoto} />
                            : is_nota
                                ? <DevisLigneNota dispatch={dispatch} notaCommentaire={commentaire} notaRef={article_ref} notaList={notaList} isEdit={isEdit} onKeyUp={onKeyUp} />
                                : is_lot
                                    ? <DevisLigneLot onKeyUp={onKeyUp} isEdit={isEdit} devisLigne={devisLigne} articles={lots} dispatch={dispatch} />
                                    /*: is_lot_content
                                        ? <DevisLigneLotContent dispatch={dispatch} devisLigne={devisLigne} isEdit={isEdit} articles={articles} />*/
                                    : ''), [dispatch, saveEdit, isEdit, articles, devisLigne])
            }

            {devisEditable &&
                <DevisLigneBtns isValidable={isValidable}
                    isEdit={isEdit}
                    isNewLigne={isNewLigne}
                    saveEdit={saveEdit}
                    setEdit={setEdit}
                    cancelEdit={cancelEdit}
                    deleteLigne={deleteLigne}
                    duplicateLigne={duplicateLigne} />}
        </li>
    )

    function cleanDevisLigne(ligne: DevisLigneEdit) {
        let newLigne = { ...ligne };
        if (newLigne.quantite === '' || formatPriceToNumber(newLigne.quantite) === 0) newLigne.quantite = '1';
        if (newLigne.prix_vente === '') newLigne.prix_vente = '0';
        if (newLigne.remise === '') newLigne.remise = '0';
        if (newLigne.photoFile) newLigne.photoFile = undefined;

        const formatedLigne = {
            ...newLigne,
            prix_vente: formatPriceToNumber(newLigne.prix_vente) || 0,
            remise: formatPriceToNumber(newLigne.remise) || 0,
            quantite: formatPriceToNumber(newLigne.quantite) || 1,
        } as DevisLigne;
        return formatedLigne;
    }

    function handleLigneClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
        if (!devisEditable) return;
        if (event.detail === 1) { // it was a single click
            const isLigneSelected = selectionManager?.selectedLigne?.id === id
            event.ctrlKey
                ? selectionManager?.dispatch({ key: `selectedLignes-one`, value: devisLigne.id })
                : event.shiftKey
                    ? selectionManager?.dispatch({ key: `selectedLignes-maj`, value: devisLigne.id })
                    : !isLigneSelected // Don't unselect the ligne if ligne is selected and in edit mode
                        ? selectionManager?.dispatch({ key: 'selectedLigne', value: devisLigne })
                        : !isEdit && selectionManager?.dispatch({ key: 'selectedLigne', value: devisLigne })

        } else if (event.detail === 2) { // it was a double click
            setIsEdit(true);
        }
    }

    function onKeyUp(e: ReactKeyboardEvent<HTMLInputElement>) {
        cancelHigherEvent(e);
        const selectionId = selectionManager?.selectedLigne?.id;
        if (selectionId !== -1) {
            if (e.key === 'Enter') saveEdit();
            else if (e.key === "Escape") cancelEdit();
        } else {
            if (e.key === 'Enter') saveEdit();
            else if (e.key === "Escape") e.currentTarget.blur();
        }

    }

    function doLigneShortcut(e: KeyboardEvent) {

        const allowedKeys = ['Enter', 'Delete', 'Escape'];
        const selectionId = selectionManager?.selectedLigne?.id;
        const target = e.target as any;

        if (!selectionId || !allowedKeys.includes(e.key) || target.id === modalConfirmId)
            return;

        if (e.key === 'Delete') {
            deleteLigne();
            return;
        }

        if (isNewLigne) {
            if (e.key === 'Enter') {
                setIsEdit(false);
                setTimeout(() => setIsEdit(true), 0);
            }
        } else {
            e.key === 'Enter'
                ? setIsEdit(prev => { if (!prev) return true; else { saveEdit(); return true } })
                : cancelEdit();
        }
    }

    function onLigneCBClick(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        e.stopPropagation();

        if (is_titre && selectLinesAndChildren && selectionManager) {

            const isSelected = selectionManager.selectedLignes.includes(devisLigne.id);
            const TitleAndChildrenIds = selectLinesAndChildren(devisLigne);
            const oldSelectedLignes = selectionManager.selectedLignes;

            const newSelectedLines = isSelected
                ? oldSelectedLignes.filter(l => !TitleAndChildrenIds.includes(l))
                : [...new Set([...oldSelectedLignes, ...TitleAndChildrenIds])]; // Creer un set supprime les doublons

            selectionManager.dispatch({
                key: 'selectedLignes',
                value: newSelectedLines,
            });
        } else {
            const key = `selectedLignes-${e.shiftKey ? 'maj' : 'one'}`;
            selectionManager?.dispatch({ key, value: devisLigne.id });
        }
    }

}

export function preventInputClearOnEscape(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Escape') e.preventDefault();
}

function stopPropagation(e: any) {
    e.stopPropagation();
}









/*function getStyle(style:any) {
        if (style?.transform && index && snapshot?.isDragging) {
            const actualTransform = style.transform.match(/-?\d+/g)[1];
            const maxTranslateBottom = -40 * index.index;
            const maxTranslatetop = 40 * (index.total -1 -index.index);
            const maxTranslate = Math.max(Math.min(actualTransform, maxTranslatetop), maxTranslateBottom)
            const axisLockY = `translate(0px, ${maxTranslate}px)`;
            return {
                ...style,
                transform: axisLockY,
            };
        }
        return style;
    }
    getStyle({...provided?.draggableProps.style})
    */