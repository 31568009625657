export const OPT_FILTRE = {
    codeTiersSociete: true, // filtre sur code comptable
    CPSociete: true, // filtre sur code postal
    villeSociete: true, // filtre sur ville
    tel: false // filtre sur numéro de téléphone de la société, avec ou sans espace
}; // Filtre la grande barre de recherche des clients

export const customerOptions = {
    CreateAdresseInDevis: false,
    createProspectInClients: false,
    PDFExport: true,
    sendDevis: true,
    TitreDevisLigne: true,
    LotInDevis: true,
    LotInArticle: false,
    DevisListeInClientDashboard: false,
    StockInDevis: false,
    StockInArticle: false,
    PhotoInArticle: false,
    PhotoInDevis: true,
    CommandeInClientDashboard: false,
    RemiseInClientDashboard: false,
    DocumentListe: false,
    DocumentDetails: false,
    RemiseClientInDevis: false,
    DevisDragNDropTitreContent: true,
    TitreAccordeonInDevis: true,
}