import { NavigateFunction } from "react-router-dom";
import BtnTooltips from "../../tools/btn-tooltips";
import { Devis } from "../../../class/devis";
import { Media } from "react-data-table-component";
import { corresp_statuts_devis } from "../../../class/document";
import formatMontant from "../../functions/formatMontant";
import { Icon } from "../../tools/icon";
import { affaire } from "../../../webpages/affaire-dashboard";

/*
const voirLeDevis = (e: any) => {
    e?.preventDefault();
    devis.divalto
        ? navigate('/devis/divalto/' + devis.id)
        : navigate('/devis/local/' + devis.id);
}*/

export const getDevisListsCols = (navigate: NavigateFunction, openModalDuplicate: (devis: Devis) => void, affaireId: string | undefined) => [
    {
        name: 'Date',
        selector: (row: Devis) => row.createDate.toLocaleDateString(),
        sortable: true,
        hide: 'sm' as Media,
    },
    {
        name: 'N° de pièce',
        selector: (row: Devis) => row.num_piece,
        sortable: true,
    },
    {
        name: 'Référence',
        selector: (row: Devis) => row.reference,
        sortable: true,
    },
    {
        name: 'Statut',
        cell: (row: Devis) => (
            <>
                <span className="font-italic">
                    {
                        row.statut === "1" ? "En cours" : corresp_statuts_devis[row.statut]
                    }
                </span>
                <Icon icon={`${row.statut === '1' ? 'ellipsis'
                    : row.statut === '2' ? 'check text-success'
                        : row.statut === '3' ? 'ban'
                            : row.statut === '4' ? 'ban text-danger'
                                : row.statut === '5' ? 'circle-question'
                                    //    : devis.statut === '6' ? 'arrow-right'
                                    : row.statut === '7' ? 'share'
                                        //    : devis.statut === '8' ? 'triangle-exclamation'
                                        : ''
                    } mx-2`} />
            </>),
        sortable: true,
        hide: 'sm' as Media,
    },
    {
        name: 'Montant HT remisé',
        selector: (row: Devis) => formatMontant(row.montant),
        sortable: true,
        hide: 'md' as Media,
        style: { 'justify-content': 'center' },
    },
    {
        name: "Date d'envoi",
        selector: (row: Devis) => row.date_envoi.getFullYear() !== 1970 ? row.date_envoi.toLocaleDateString() : '...',
        sortable: true,
        hide: 'lg' as Media,
    },
    {
        button: true,
        right: true,
        name: '',
        style: { 'justify-content': 'flex-end' },
        hide: 'md' as Media,
        cell: (row: Devis) => (
            <div className="d-flex">
                {!row.divalto &&
                    <BtnTooltips
                        buttonstyle={`nobt fa fa-pen bleu-actif mx-2`}
                        tooltiplabel={'Editer le Devis'}
                        onClick={() => navigate('/devis/local/' + row.id, { state: { from: affaire } })}
                    />
                }
                {row.divalto &&
                    <BtnTooltips
                        buttonstyle={`nobt fa fa-eye bleu-actif mx-2`}
                        tooltiplabel={'Voir le Devis'}
                        onClick={() => navigate('/devis/divalto/' + row.id, { state: { from: affaire } })}
                    />
                }
                <BtnTooltips
                    buttonstyle={`nobt fa fa-copy bleu-actif mx-2`}
                    tooltiplabel={'Dupliquer le Devis'}
                    onClick={() => openModalDuplicate(row)}
                />
            </div>

        ),
    },
];