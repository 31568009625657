import DataTable, { createTheme } from "react-data-table-component";
import { CustomDatatableTheme, PaginationComponentOptions } from '../dataTable/CustomTheme';
import { formatUrl } from "../../functions/formatUrl";
import { useMemo, useState } from "react";
import { Devis } from "../../../class/devis";
import { useNavigate, useParams } from 'react-router-dom';
import { getDevisListsCols } from "./devis-list-cols";
import { Loadering } from "../../tools/loadering";
import ExpandedDevisList from "./devis-list-expand";
import { Icon } from "../../tools/icon";
import { useAffairesForDuplication } from "../../../hooks/useAffaires";
import DuplicateDevisModal from "./duplicate-devis-modal";
import { affaire } from "../../../webpages/affaire-dashboard";


interface DevisArrayListArrayProps {
    filtredDevis: Devis[];

}


const DevisArrayListArray = ({ filtredDevis }: DevisArrayListArrayProps) => {
    createTheme('custom', CustomDatatableTheme, 'light');

    const navigate = useNavigate();
    const { affaireId } = useParams();
    const [filterText, setFilterText] = useState('');
    const [duplicateDevis, setDuplicateDevis] = useState<Devis>()

    const [showDupplicateModal, setShowDupplicateModal] = useState(false);
    const agencyAffaires = useAffairesForDuplication();
    const [searchAffaire, setSearchAffaire] = useState(duplicateDevis?.numero_dossier);

    const handleDelModalCancel = () => {
        setShowDupplicateModal(false);
    }

    const openModalDuplicate = (devis: Devis) => {
        setShowDupplicateModal(true);
        setDuplicateDevis(devis);
        setSearchAffaire(devis.numero_dossier)
    }

    const columns = useMemo(() => getDevisListsCols(navigate, openModalDuplicate, affaireId), [])

    // const [pending, setPending] = useState(true);
    // const devis = useBothDevis();

    // useEffect(() => {
    //     setPending(!devis);
    // }, [devis]);

    const createDevis = () => { navigate(formatUrl('devis/nouveau', affaireId), { state: { from: affaire } }) }

    const subHeaderComponentMemo = useMemo(() => (
        <div className="d-flex align-items-center justify-content-center w-100">
            <button className="btn btn-bleu" onClick={createDevis}>
                <span className="me-2">Créer un devis</span>
                <Icon icon='plus blanc' />
            </button>
        </div>
    ), []);


    return (
        <div className="table px-2 ">
            <DataTable
                columns={columns}
                data={filtredDevis}
                theme="custom"
                className="row_pointer"
                // customStyles={affaireTableTheme}
                striped
                fixedHeader
                //fixedHeaderScrollHeight={window.innerWidth <= 768 ? "calc(100% - 240px)" : "calc(100% - 190px)"} 
                paginationPerPage={10}
                pagination
                paginationComponentOptions={PaginationComponentOptions}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                // paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
                paginationResetDefaultPage={false} // optionally, a hook to reset pagination to page 1
                subHeader


                noDataComponent={
                    filterText
                        ? <div className="text-center fw-bold display-15 p-3"><span className="fa-solid fa-circle-exclamation me-2"></span>Nous n'avons trouvé aucune réponse correspondant à votre recherche.</div>
                        : <div className="text-center fw-bold display-15 p-3"><span className="fa-solid fa-circle-exclamation me-2"></span>Aucun devis n'a été trouvé.</div>
                }

                expandableRows={window.innerWidth < 1440}
                expandableRowsComponent={(row) => <ExpandedDevisList data={row.data} openModalDuplicate={openModalDuplicate} />}
                expandableRowsHideExpander={false}

                subHeaderComponent={subHeaderComponentMemo}
                /*              
                
                expandableRowExpanded={(rowsData: Utilisateur) => expandedRow === rowsData.id}
                                onRowExpandToggled={(expanded, rowsData) => handleRowExpand(expanded ? rowsData.id : undefined)}             
                              
                
                                expandableRowsHideExpander={false}
                                expandableRowExpanded={(rowData: Affaire) => expandedRow === rowData.id}
                                
                
                                
                                //selectableRows
                                conditionalRowStyles={[{ when: row => row.id === expandedRow, classNames: ['selected'] }]}
                                highlightOnHover={true}
                                persistTableHead
                                expandableRowsHideExpander={false}
                                expandableRowExpanded={(rowData: Affaire) => expandedRow === rowData.id}
                                //onRowExpandToggled={(expanded, rowData) => handleRowExpand(expanded ? rowData.id : undefined)}
                                progressPending={pending}
                */
                progressComponent={<Loadering color={'bleu'} />}
                onRowClicked={rowData => {
                    navigate(!rowData.divalto ? '/devis/local/' + rowData.id : '/devis/divalto/' + rowData.id, { state: { from: affaire } });
                }}
            />
            <DuplicateDevisModal
                show={showDupplicateModal}
                closeModal={handleDelModalCancel}
                document={duplicateDevis}
                affaires={agencyAffaires} />
        </div>
    )
}
export default DevisArrayListArray;