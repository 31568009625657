
interface RowExpandedProps {
    titre: string;
    value: string | number | Date | JSX.Element;
    classSup?:string;
}

export const RowExpanded = ({titre, value, classSup}: RowExpandedProps) =>
    <li className={`list-group-item border-0 pb-0 d-flex justify-content-start ${classSup} `}>
        <span className="bleu-leger normal font-bold me-1 nowrap">{titre} : </span>
        {typeof value === 'string' || typeof value === 'number' 
          ? value // Render string or number
          : value instanceof Date
          ? value.toLocaleString() // Render Date as a string
          : value // Render JSX.Element (could be HTML or React component)
        }
    </li>