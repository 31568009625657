import { Devis } from "../../../class/devis";
import { RowExpanded as ExpRow } from "../dataTable/row-expanded";
import { useNavigate } from "react-router-dom";
import { corresp_statuts_devis } from "../../../class/document";
import formatMontant from "../../functions/formatMontant";
import { Icon } from "../../tools/icon";
import { affaire } from "../../../webpages/affaire-dashboard";

interface ExpandedDevisListProps {
    data: Devis; // On doit garder le nom data pour coller au type
    openModalDuplicate: (devis: Devis) => void;
}

const ExpandedDevisList = ({data, openModalDuplicate}: ExpandedDevisListProps) => {
    const devis = data;
    const navigate = useNavigate();
    return (
        <ul className="list-group list-group-flush pb-2 border-bottom border border-dark" id={`expanded-client-${devis.id}`}>
            <ExpRow titre='Date' value={devis.createDate.toLocaleDateString()} classSup="d-lg-none" />
            <ExpRow titre='Statut' value={corresp_statuts_devis[devis.statut]} classSup="d-lg-none" />
            <ExpRow titre='Montant' value={formatMontant(devis.montant)} classSup="d-lg-none" />
            {devis.date_envoi && (
                <ExpRow titre='Date d’envoi' value={devis.date_envoi.toLocaleDateString()} />
            )}
            <div className="d-flex justify-content-center mt-2 d-lg-none">
                <button 
                    className="btn btn-primary d-flex align-items-center"
                    onClick={() => navigate(!data.divalto ? '/devis/local/' + data.id : '/devis/divalto/' + data.id, { state: { from: affaire } })} >
                        {!data.divalto 
                            ? <>Editer <Icon icon={'pen ms-2'}/></>
                            : <>Consulter <Icon icon={'eye ms-2'}/></>
                        }
                </button>
                <button 
                    className="btn btn-primary d-flex align-items-center ms-2" 
                    onClick={() => openModalDuplicate(data)}>
                    Dupliquer 
                    <Icon icon={'copy ms-2'}/>
                </button>
            </div>
        </ul>
    )
}

export default ExpandedDevisList