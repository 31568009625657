import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { DevisLigneArticleBase, DevisLigneLot } from "../../../class/devis";
import { addSpaceToMillers } from "../../../components/functions/formatMontant";
import { roundAtDec } from "../../../components/functions/maths";

export interface PDFLigneArticlePropsNew {
    ligne: DevisLigneArticleBase | DevisLigneLot;
    hidePrice?: boolean;
    code_op: string;
}

export const PDFLigneArticle = ({ ligne, hidePrice, code_op }: PDFLigneArticlePropsNew) => {
    const styles = getPdfLigneArticleStyleLocalNew({ hidePrice, ligne });
    const designation = insertHyphen(ligne.designation, [25, 50]);

    return (
        <View style={styles.ligneContainer}>
            <Text style={styles.designationStyle}>{designation}</Text>
            <View style={styles.subLigne}>
                <Text style={styles.subLigneText}>{ligne.quantite}</Text>
                <Text style={styles.littleSubLigneText}>{ligne.unite}</Text>
                {/* // Vérifier si ça correspond bien au `prix unitaire du model devis` */}
                {!hidePrice && (
                    <>
                        <Text style={styles.largeSubLigneText}>{formatPDFNb(ligne.prix_vente)}</Text>
                        <Text style={styles.discountSubLigneText}>{ligne.remise > 0 && formatRemise(ligne.remise)}</Text>
                        <Text style={styles.largeSubLigneText}>{formatPDFNb(ligne.montant_total)}</Text>
                        <Text style={styles.littleSubLigneText}>{code_op}</Text>
                    </>
                )}
            </View>

        </View>
    )

}

const formatPDFNb = (nb: number) => { return addSpaceToMillers(roundAtDec(nb, 2).toFixed(2)) };
const formatRemise = (number: number) => { return (number % 1 !== 0 ? number.toFixed(2) : number.toFixed(0)) + '%'; }
// const formatRemise = (number: number) => { return number % 1 !== 0 ? number.toFixed(2) : number.toFixed(0); }
export const insertHyphen = (str: string, index: number[]) => index.reduce((a, b) => a.length < b ? a : a.slice(0, b) + '­' + a.slice(b), str);


export const getPdfLigneArticleStyleLocalNew = ({ hidePrice }: Partial<PDFLigneArticlePropsNew>) => {

    return StyleSheet.create({
        ligneContainer: {
            paddingHorizontal: 8,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            paddingVertical: 5,
        },
        designationStyle: {
            width: hidePrice ? '85%' : '55%',
            paddingLeft: '15',
        },

        subLigne: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: hidePrice ? 'flex-end' : 'center',
            gap: 5,
            width: hidePrice ? '15% ' : '45%',
        },
        subLigneText: {
            flex: 1,
            textAlign: 'right'
        },
        littleSubLigneText: {
            textAlign: 'right',
            width: 22
        },
        largeSubLigneText: {
            textAlign: 'right',
            width: 50
        },
        discountSubLigneText: {
            textAlign: 'right',
            width: 25
        },

    });
};

