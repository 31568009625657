import { Image, StyleSheet, Text, View } from "@react-pdf/renderer"
import { DevisInfos } from "../../../class/devis";
import { devisPrefix } from "../../../formatters/documentFormatter";
export interface PDFLogoProps {
    devisInfos: DevisInfos;
    pageNumber?: number;
    isProForma?: boolean;
}

export const PDFLogo = ({ devisInfos, isProForma }: PDFLogoProps) => {

    const styles = getPdfLogoStyleLocal();
    const { num_piece, affaire } = devisInfos;

    const devis_type = devisInfos.devis_type.selection?.code;
    const num_piece_pdf = devisInfos.is_devis && !isProForma ? devisPrefix + num_piece : num_piece;

    const num_devis = <Text >
        <Text style={styles.devis}>{isProForma ? 'PROFORMA N° ' : 'N° devis '}</Text>
        <Text style={styles.num}>{num_piece_pdf} {devis_type}</Text>
    </Text>

    return (
        <View style={styles.compagnyLogo} fixed>
            <Image src='/assets/img/client/logo-client.png' style={styles.logo} />
            <Text render={({ pageNumber }) => {
                const style_nom_agence = {
                    color: pageNumber === 1 ? '#3a3a3a' : '#a9a9a9'
                };
                return <Text style={{ ...style_nom_agence }}>{affaire?.nom_agence}</Text>;
            }} />
            <Text render={({ pageNumber }) => {
                return pageNumber === 1 ? null : num_devis
            }} />
        </View>
    )
}

export const getPdfLogoStyleLocal = () => {

    return StyleSheet.create({
        compagnyLogo: {
            paddingHorizontal: 15,
            display: 'flex',
            flexDirection: 'column',
        },
        logo: {
            width: '200px'
        },
        devis: {
            fontWeight: 400,
            fontSize: 10,
            paddingLeft: 10
        },
        num: {
            fontWeight: 900,
            fontSize: 10,
        },
        style_nom_agence: {
            fontWeight: 'bold',
            fontSize: 14,
            paddingHorizontal: 10
        }
    })
}