import { formatUrl } from '../../functions/formatUrl';
import { useNavigate } from 'react-router-dom';

interface iBlocRapport {
    nom: string;
    description: string;
    groupId: string;
    reportId: string;
    active: boolean;
    onReportClick: () => void;
}

const BlocRapport = ({
    nom,
    description,
    groupId,
    reportId,
    active,
    onReportClick
}: iBlocRapport) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/reporting/groupe/' + groupId + formatUrl('rapport', reportId));
        onReportClick();
    };

    return (
        <div className="col-12 col-md-4 cursor-pointer" onClick={handleClick}>
            <div className={`d-flex align-items-center 
                             mt-2 mx-md-0 mx-lg-1 mx-xl-1 mx-xxl-2 p-3  ${active ? 'bg-bleu-actif' : 'bg-bleu-clair7'}  rounded  card-hover`}>
                <div className="ln-4">
                    <span className={`d-block font-bold display-16  ${active ? 'blanc' : 'bleu-clair9'} pb-1`}>{nom}</span>
                    <span className={`display-19 d-block font-medium ln-2 ${active ? 'blanc' : 'bleu-clair9'} `}>{description}</span>
                </div>
            </div>
        </div>
    );
};

export default BlocRapport;
