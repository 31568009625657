interface InfosItemsDevisHeaderProps {
    label: string;
    value: string;
    shouldHideColon?: boolean
}

const InfosItemsDevisHeader = ({ label, value, shouldHideColon }: InfosItemsDevisHeaderProps) => {
    return (
        <>
            {value && (
                <li key={label}>
                    {label} {!shouldHideColon && ':'} <span className="font-bold text-uppercase">{value}</span>
                </li>
            )}
        </>
    )
}

export default InfosItemsDevisHeader