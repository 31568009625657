import { PDFViewer } from "@react-pdf/renderer";
import { Modal } from "react-bootstrap";

interface ShowModalPdfProps {
    showModelPdf: boolean;
    pdfList: {
        id: number;
        rendu: JSX.Element;
        name: string;
    }[];
    selectedPdfModelId: number | string;
    setShowModelPdf: (value: boolean) => void;

}

export const ShowModelPdf = ({ showModelPdf, pdfList, selectedPdfModelId, setShowModelPdf }: ShowModalPdfProps) => {

    const selectedPdfModelIdNumber = typeof selectedPdfModelId === 'string' && parseInt(selectedPdfModelId);

    return (
        <Modal show={showModelPdf} onHide={() => setShowModelPdf(false)} size="lg" centered >
            <Modal.Header closeButton>
                <Modal.Title>Modèle PDF Sélectionné</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pdfList.filter(model => model.id === selectedPdfModelIdNumber).map(Model =>
                (
                    <div key={Model.id}>
                        <PDFViewer className="PDFViewer">
                            {Model.rendu}
                        </PDFViewer>
                    </div>
                )
                )}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
};
