import ListeBpus from "./liste-bpu";
import Switcher from "../../../tools/switcher";
import { memo, useState } from "react";
import { BibliothequeCodeBpus, BibliothequeMetier, BibliothequeSousMetier } from "../../../../class/articles";
import { DevisInfos } from "../../../../class/devis";

interface WrapperBusProps {
    toggleBibliothequeBpu: () => void;
    createDevisLignesFromBibliotheque: (articleIds: number[]) => void;
    isBibliothequeBpuOpen: boolean;
    bibliothequeMetierArticles: BibliothequeMetier;
    bibliothequeMetierOuvrages: BibliothequeMetier;
    devisInfos?: DevisInfos | undefined;
}

export interface MetierAndCodeBpusLabel {
    metier: string;
    codeBpu: string;
}

// A vérifier avec Eswann, conflits lors du pull, 21/12/2023
// const WrapperBpus = ({ handleToggleBPU, createDevisLignesFromBibliotheque, isCheckedBPU, bibliothequeMetierArticles, bibliothequeMetierOuvrages, devisInfos }: WrapperBusProps) => {
const WrapperBpus = ({ isBibliothequeBpuOpen, toggleBibliothequeBpu, createDevisLignesFromBibliotheque, bibliothequeMetierArticles, bibliothequeMetierOuvrages, devisInfos }: WrapperBusProps) => {


    const [isCheckedFiltres, setIsCheckedFiltres] = useState(true);
    const [isOuvragesChecked, setIsOuvragesChecked] = useState(false);
    const handleToggleOuvrages = () => {
        setIsOuvragesChecked(prev => !prev);
        setSelectedArticles([]);
    };
    const bibliothequeMetier = isOuvragesChecked ? bibliothequeMetierOuvrages : bibliothequeMetierArticles;

    // Stocke les Bpus sélectionnés
    const [selectedArticles, setSelectedArticles] = useState<number[]>([]);
    // console.log("selectedArticles: ", selectedArticles);

    const labelMetierOfDevis = devisInfos?.metier.selection?.label;
    const labelCodeBpuOfDevis = devisInfos?.code_bpu ?? '';
    const labelSousMetierOfDevis = devisInfos?.sous_metier.selection?.label;
    const formattedLabelMetier = labelMetierOfDevis ? formattedLabelOfDevis(labelMetierOfDevis) : "";
    const formattedLabelSousMetier = labelSousMetierOfDevis ? formattedLabelOfDevis(labelSousMetierOfDevis) : "";

    return (
        <>
            <div className={`wrapper-bpu  ${isBibliothequeBpuOpen ? 'active' : ''}`}>
                <div className="fade modal-backdrop show" onClick={toggleBibliothequeBpu}></div>
                <div className="bg-bleu blanc h-100 position-fixed top-0 end-0 content">
                    <div className="container">

                        <div className="height-search py-2 d-flex align-items-center justify-content-end border-bottom border-white border-opacity-25">
                            <h2 className="ms-0 me-auto mb-0">Liste des  {isOuvragesChecked ? 'ouvrages' : 'articles'}</h2>
                            <div className="me-1 d-md-flex align-items-center h-100">
                                <div className="mb-md-0 mb-sm-2">
                                    <Switcher
                                        label={"Ouvrages"}
                                        onChange={handleToggleOuvrages}
                                        isChecked={isOuvragesChecked}
                                    />
                                </div>
                                <div className="mb-md-0 mb-sm-2">
                                    <Switcher
                                        label={"Filtres activés"}
                                        onChange={handleToggleFiltres}
                                        isChecked={isCheckedFiltres}
                                    />
                                </div>
                            </div>
                            {/* A vérifier avec Eswann, conflits lors du pull, 21/12/2023 */}
                            {/* <button className="nobt nobt align-items-center " onClick={() => { console.log("handleToggleBPU clicked"); handleToggleBPU() }}><span className="fa fa-xmark blanc"></span></button> */}
                            <button className="nobt nobt mt-1 mb-auto" onClick={() => { console.log("toggleBibliothequeBpu clicked"); toggleBibliothequeBpu() }}><span className="fa fa-xmark blanc"></span></button>

                        </div>


                        <div className="scrollbar height-18">
                            <div className="mt-3">
                                <ListeBpus
                                    selectedArticles={selectedArticles}
                                    setSelectedArticles={setSelectedArticles}
                                    isOuvragesChecked={isOuvragesChecked}
                                    metiers={isCheckedFiltres ? filteredList() : bibliothequeMetier}
                                />
                            </div>
                        </div>
                        <div className="height-search border-top border-white border-opacity-25 align-items-center d-flex justify-content-center">
                            <button className="my-1 btn btn-vert" onClick={onAddBpu}>
                                Ajouter les BPUS sélectionnés
                                <span className="fa fa-circle-check blanc ms-2"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    function onAddBpu() {
        createDevisLignesFromBibliotheque(selectedArticles);
        toggleBibliothequeBpu();
        setSelectedArticles([]);
    }

    function filteredList(): BibliothequeMetier {
        if (formattedLabelMetier in bibliothequeMetier) {
            return Object.fromEntries(
                Object.entries(bibliothequeMetier).map(([metier, codeBpu]) =>
                    metier === formattedLabelMetier
                        ? [
                            metier,
                            Object.fromEntries(
                                Object.entries(codeBpu).map(([codeBpuKey, sousMetiers]) =>
                                    (codeBpuKey === labelCodeBpuOfDevis || codeBpuKey === '*INTERNE')
                                        ? [
                                            codeBpuKey,
                                            Object.fromEntries(
                                                Object.entries(sousMetiers).filter(([sousMetierKey]) => (
                                                    sousMetierKey === formattedLabelSousMetier || sousMetiers[sousMetierKey]
                                                ))
                                            )
                                        ] : null
                                ).filter(c => c) as Array<[string, BibliothequeSousMetier]>
                            ),
                        ]
                        : null
                ).filter(item => item) as Array<[string, BibliothequeCodeBpus]>
            );
        } else {
            return Object.fromEntries(
                Object.entries(bibliothequeMetier).map(([metier, codeBpu]) => [
                    metier,
                    {
                        '*INTERNE': codeBpu['*INTERNE'] || {},
                    },
                ])
            );
        }
    }

    
    function handleToggleFiltres() {
        setIsCheckedFiltres((prev) => !prev);

        const metierData = bibliothequeMetier[formattedLabelMetier];

        // Si le métier spécifié dans le devis existe dans la bibliothèque métier
        if (metierData) {
            // Récupérer les IDs des articles du codeBpus `*INTERNE` s'il est spécifié dans le devis
            const interneData = metierData["*INTERNE"];
            const filteredArticlesIdsInterne = interneData && interneData[formattedLabelSousMetier] ? interneData[formattedLabelSousMetier].map((item) => item.id) : [];

            // Récupérer les IDs des articles correspondant au sous-métier spécifique du devis 
            const filteredArticlesIds = metierData[labelCodeBpuOfDevis]?.[formattedLabelSousMetier]?.map((item) => item.id) || [];

            // Si le codepu `*INTERNE` n'est pas spécifié dans le devis, récupérer tous les IDs du codepu `*INTERNE`
            const allInterneArticlesIds = interneData ? Object.values(interneData).flatMap((sousMetier) => sousMetier.map((item) => item.id)) : [];

            // Si le code BPU est spécifié dans le devis, récupérer tous les IDs de tous les sous-métiers de ce code BPU
            const bpuData = metierData[labelCodeBpuOfDevis];
            const allBpuArticlesIds = bpuData ? Object.values(bpuData).flatMap((sousMetier) => sousMetier.map((item) => item.id)) : [];

            // Fusionner les listes d'IDs
            const filteredEveryArticlesIds = [
                ...filteredArticlesIds,
                ...filteredArticlesIdsInterne,
                ...allInterneArticlesIds,
                ...allBpuArticlesIds,
            ];

            // console.log("filteredEveryArticlesIds :", filteredEveryArticlesIds);

            setSelectedArticles(selectedArticles.filter((article) => filteredEveryArticlesIds.includes(article)));

        }
    }
}

//Formatte le label du devis pour qu'il corresponde à celui reçu dans la bibliothequeMetier
export const formattedLabelOfDevis = (str: string) => {
    return str.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').slice(0, 8)
};


export default memo(WrapperBpus);