import { StyleSheet, Text, View } from "@react-pdf/renderer"
import { DevisInfos } from "../../../class/devis";
import { PDFTableContainer } from "./pdf-table-container";
import { devisPrefix } from "../../../formatters/documentFormatter";

export interface PDFHeaderProps {
    devisInfos: DevisInfos;
    isProForma?: boolean;
    hideAdresse?: boolean;
    devisWithoutSinistre?: boolean;
}

export const PDFHeader = ({ devisInfos, isProForma, hideAdresse, devisWithoutSinistre }: PDFHeaderProps) => {

    const { affaire, num_piece, ref } = devisInfos

    if (!affaire) return <></>;

    const { agence_adr_rue, agence_adr_compl1, agence_adr_compl2, agence_adr_cp,
        agence_adr_ville, agence_adr_loc, nom_client, ville_client,
        adresse_client, code_postal_client, compl1_client, compl2_client,
        localisation_client, agence_tel, agence_email, agence_rib, ref_plateforme } = affaire;

    const devis_type = devisInfos.devis_type.selection?.code
    const styles = getPdfHeaderStyleLocalNew();
    const num_piece_pdf = devisInfos.is_devis && !isProForma ? devisPrefix + num_piece : num_piece

    return (
        <View>
            <View style={styles.pdfContainerAdress}>
                <View>
                    <Text>{agence_adr_rue}</Text>
                    <Text>{agence_adr_compl1}</Text>
                    <Text>{agence_adr_compl2}</Text>
                    <Text>{agence_adr_cp} {agence_adr_ville}</Text>
                    <Text>{agence_adr_loc !== '' ? 'Localisation : ' + agence_adr_loc : ''}</Text>
                </View>

                {!hideAdresse && (
                    <View style={styles.adresseClient}>
                        <Text style={styles.adressName}>{nom_client}</Text>
                        <Text>{adresse_client.toLocaleLowerCase()}</Text>
                        <Text>{code_postal_client} {ville_client}</Text>
                        <Text>{compl1_client} {compl2_client}</Text>
                        <Text>{localisation_client && 'Localisation : ' + localisation_client}</Text>
                    </View>
                )}

            </View>
            <View style={styles.infosAgenceContainer}>
                <View style={styles.infosAgence}>
                    <View style={styles.infosAgenceText}>
                        <Text style={styles.text}>Tél</Text>
                        <Text>{agence_tel}</Text>
                    </View>
                    <View style={styles.infosAgenceText}>
                        <Text style={styles.text}>e-mail</Text>
                        <Text>{agence_email}</Text>
                    </View>
                    <View style={styles.infosAgenceText}>
                        <Text style={styles.text}>RIB</Text>
                        <Text>{agence_rib}</Text>
                    </View>
                </View>
                <View style={styles.numeroDevis}>
                    {isProForma ? <Text>PROFORMA N°</Text> : <Text>Devis N°</Text>}
                    <Text>{num_piece_pdf} {devis_type}</Text>
                </View>
            </View>
            <PDFTableContainer
                affaire={affaire}
                ref_devis={ref}
                devisWithoutSinistre={devisWithoutSinistre}
                hideAdresse={hideAdresse}
            />
            {/*⚠️ Vérifier le champ `Réf. plateforme` */}
            <Text style={styles.refPlateforme}>
                {affaire?.ref_plateforme &&
                    <Text > Réf. plateforme : {ref_plateforme}</Text>
                }
            </Text>
        </View>
    )
};

export const getPdfHeaderStyleLocalNew = () => {
    return StyleSheet.create({

        pdfContainerAdress: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: 5,
            paddingHorizontal: 15,
        },
        adresseClient: {
            marginRight: 60,
        },
        adressName: {
            fontWeight: 'bold',
            fontSize: 10,
        },
        nomDevis: {
            textTransform: 'uppercase',
            fontWeight: 900,
            fontSize: 12,
        },
        infosAgence: {
            width: '35%',
            fontSize: 8,
            display: 'flex',
            flexDirection: 'column',
            paddingHorizontal: 15,
        },
        infosAgenceContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        infosAgenceText: {
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            justifyContent: 'flex-start',
            color: "#929292",
            fontWeight: 'ultrabold',
            fontStyle: 'italic'
        },
        text: {
            width: '15%',
            color: '#3a3a3a',
            fontWeight: 400
        },
        numeroDevis: {
            fontWeight: 'bold',
            fontSize: 14,
            display: 'flex',
            flexDirection: 'row',
            gap: 20,
            paddingHorizontal: 15,
        },
        refPlateforme: {
            width: '100%',
            textAlign: 'center',
            fontSize: 8
        }
    })
}