import { ChangeEvent, Dispatch, KeyboardEvent } from "react";
import { ActionDevis } from "../devis-infos-reducer";
import "./style/devis-ligne-titre.css"
import { DevisLigneTitres } from "./titre-types";
import { Icon } from "../../../tools/icon";
import { defaultLineType } from "./titre-types";
import { DevisLigneEdit } from "../../../../class/devis";
import styled from "styled-components";
import { useFeature } from "flagged";
import formatMontant from "../../../functions/formatMontant";

interface DevisLigneTitreProps {
    dispatch: Dispatch<ActionDevis>;
    devisInfosDispatch: Dispatch<ActionDevis>;
    devisLigne: DevisLigneEdit;
    isEdit: boolean;
    onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => void;
}

//const InputSearch = styled.input::-webkit-search-cancel-button``;

const DevisLigneTitre = ({ dispatch, devisInfosDispatch, devisLigne, isEdit, onKeyUp }: DevisLigneTitreProps) => {
    const titre = devisLigne.titre;
    const { content, type } = titre;
    const isTitreAccordeon = useFeature('TitreAccordeonInDevis');

    const onChevronClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        devisInfosDispatch({
            key: 'setTitreHidden', value: {
                ligneId: devisLigne.id!,
                isHide: !titre.hide_content
            }
        })
    };

    if (isEdit) {
        return (
            <div className="d-lg-flex w-100">
                <div className="colonne-2 mb-2 mb-md-0">
                    <select value={type} className="form-control form-select w-100" onChange={onTitreTypeChange}>
                        {DevisLigneTitres.map(tt =>
                            <option value={tt.value} key={tt.value}>{tt.label}</option>
                        )}
                    </select>
                </div>
                <div className="colonne-11">
                    <input className="form-control"
                        autoFocus
                        value={content}
                        maxLength={69}
                        placeholder={'Votre titre ici !'}
                        onChange={onTitreContentChange}
                        onKeyUp={onKeyUp} />
                </div>
            </div>
        )
    } else {
        return (
            <div className="colonne-11 container-devis-ligne-titre d-flex align-items-center justify-content-between w-100">
                <div>
                    <div className="devis-ligne-titre-number d-inline">
                        <span className="ms-1">{content}</span>
                    </div>
                    {isTitreAccordeon &&
                        <IconWrapper onClick={onChevronClick}>
                            <Icon icon={'chevron-' + (titre.hide_content ? 'up' : 'down') + ' align-middle ms-2'} />
                        </IconWrapper>}
                </div>
                <span className="ms-1 font-bold">{formatMontant(devisLigne.montant_total)}</span>
            </div>
        )
    };

    function onTitreContentChange(e: ChangeEvent<HTMLInputElement>) {
        const newTitre = {
            ...titre,
            content: e.target.value,
        }
        dispatch({ key: 'titre', value: newTitre })
    }

    function onTitreTypeChange(e: ChangeEvent<HTMLSelectElement>) {
        const newTitre = {
            ...titre,
            type: e.currentTarget.value,
        }
        dispatch({ key: 'titre', value: newTitre })
    }
}
export default DevisLigneTitre;

const IconWrapper = styled.div`
    cursor: pointer;
    display: inline-block;
    width: 20px;
`;