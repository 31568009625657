import { useState } from "react"
import { Icon } from "../../components/tools/icon"
import { PdfExportModal } from "./pdf-export-modal";
import { DevisInfos } from "../../class/devis";


interface PdfExportButtonProps {
    devisInfos: DevisInfos | undefined;
}

export const PdfExportButton = ({ devisInfos }: PdfExportButtonProps) => {

    const [showPDFModal, setshowPDFModal] = useState(false);

    const openPDFModal = () => setshowPDFModal(true);
    const closePDFModal = () => setshowPDFModal(false);

    return (
        <>
            <button className="btn btn-primary mx-1" onClick={openPDFModal}>
                <span className="hidemobile">Imprimer </span>
                <Icon icon='fa fa-print ms-0 ms-xl-2' />
            </button>
            {devisInfos &&
                <PdfExportModal
                    show={showPDFModal}
                    onConfirm={closePDFModal}
                    onCancel={closePDFModal}
                    devisInfos={devisInfos} />}
        </>
    )
}