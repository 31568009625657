import { Affaire } from "../../../class/affaire";
import { DestinataireProps } from "../../../class/templateMail";


interface SelectDestinatairesProps {
    affaire?: Affaire;
    handleSelectValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
    selectValueInput: string;
    selectDestinataire: string;
    handleSelectDestinataire: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    statusDevis?: string | number;
    destinairePresentInAffaire: DestinataireProps[];
    ccEmails: string;
    handleCcAddressesChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isValidEmailDestinataire: boolean;
    isValidEmailCopy: boolean;
    handleKeyDown: (
        emailArray: string[],
        setEmailState: (isValid: boolean) => void,
        e: React.KeyboardEvent<HTMLInputElement>,
        valueInput: string,
        setValueState: (updateFunction: (prevEmails: string) => string) => void
    ) => void;
    selectValueInputArray: string[];
    setIsValidEmailDestinataire: (isValid: boolean) => void;
    setSelectValueInput: (updateFunction: (prevEmails: string) => string) => void;
    ccEmailsArray: string[],
    setIsValidEmailCopy: (isValid: boolean) => void;
    setCcEmails: (updateFunction: (prevEmails: string) => string) => void;

};

export const SelectDestinataire = ({
    handleSelectValue,
    selectValueInput,
    handleSelectDestinataire,
    selectDestinataire,
    destinairePresentInAffaire,
    statusDevis,
    ccEmails,
    handleCcAddressesChange,
    isValidEmailDestinataire,
    isValidEmailCopy,
    handleKeyDown,
    selectValueInputArray,
    setIsValidEmailDestinataire, setSelectValueInput,
    ccEmailsArray,
    setIsValidEmailCopy,
    setCcEmails

}: SelectDestinatairesProps) => {

    return (
        <>
            {statusDevis !== '3' && (
                <select className="form-select bg-bleu-clair1 border-0 bleu" onChange={handleSelectDestinataire} value={selectDestinataire}>
                    <option value="">Destinataire</option>
                    {destinairePresentInAffaire?.map((dest) => (
                        <option key={dest.id} value={dest.label}>
                            {dest.label}
                        </option>
                    ))}
                </select>
            )}
            <div  >
                <div className="d-flex align-items-center p-1">
                    <label className="nowrap mw-2">A: </label>
                    <input
                        type="email"
                        multiple
                        className={`form-control ${!isValidEmailDestinataire && selectValueInput && 'is-invalid'} `}
                        value={
                            selectDestinataire !== ''
                                ? destinairePresentInAffaire.find(dest => dest.label === selectDestinataire)?.email || ''
                                : selectValueInput
                        }
                        onChange={selectDestinataire === '' ? handleSelectValue : undefined}
                        onKeyDown={(e) => handleKeyDown(
                            selectValueInputArray,
                            setIsValidEmailDestinataire,
                            e,
                            selectValueInput,
                            setSelectValueInput,
                        )}

                    />
                </div>
                {!isValidEmailDestinataire && selectValueInput && (
                    <span className="pt-1 rouge font-italic">Veuillez saisir une adresse email valide.</span>
                )}
            </div>

            <div>
                <div className="d-flex align-items-center p-1">
                    <label className="nowrap mw-2">Cc :</label>
                    <input
                        type="email"
                        multiple
                        className={`form-control ${!isValidEmailCopy && ccEmails && 'is-invalid'} `}
                        value={ccEmails}
                        onChange={handleCcAddressesChange}
                        onKeyDown={(e) => handleKeyDown(
                            ccEmailsArray,
                            setIsValidEmailCopy,
                            e,
                            ccEmails,
                            setCcEmails

                        )}
                    />
                </div>
                {!isValidEmailCopy && ccEmails && (
                    <span className="pt-1 rouge font-italic">Veuillez saisir une adresse email valide.</span>
                )}
            </div>
        </>
    );
};
