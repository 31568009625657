import { StyleSheet, Text, View } from "@react-pdf/renderer"

export interface PDFBodyHeaderProps {
    hidePrice?: boolean | undefined;
}

export const PDFBodyHeader = ({ hidePrice }: PDFBodyHeaderProps) => {
    const styles = getPdfBodyHeaderStyleLocalNew({ hidePrice });

    return (
        <View style={styles.bodyHeaderContainer} fixed>
            <Text style={styles.designationStyle}>Désignation</Text>
            <View style={styles.subHeader}>
                <Text style={styles.subHeaderText}>Quantité</Text>
                <Text style={styles.littleSubHeaderText}>U.V</Text>
                {!hidePrice && (
                    <>
                        <Text style={styles.largeSubHeaderText}>Prix unitaire</Text>
                        <Text style={styles.discountSubHeaderText}>Rem</Text>
                        <View style={styles.largeSubHeaderText}>
                            <Text>Montant</Text>
                            <Text>Remisé</Text>
                        </View>
                        <Text style={styles.littleSubHeaderText}> OP</Text>
                    </>
                )}
            </View>
        </View>
    )
}

export const getPdfBodyHeaderStyleLocalNew = ({ hidePrice }: PDFBodyHeaderProps) => {
    return StyleSheet.create({
        bodyHeaderContainer: {
            marginTop: 4,
            padding: hidePrice ? 5 : 0,
            paddingHorizontal: 8,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: "#e3e3e3",
            width: '100%',
        },
        designationStyle: {
            width: hidePrice ? '85%' : '55%',
        },
        subHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: hidePrice ? 'flex-end' : 'center',
            gap: 5,
            width: hidePrice ? '15% ' : '45%',
        },
        subHeaderText: {
            textAlign: 'right',
            flex: 1
        },
        textMontantRemise: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'right'
        },
        littleSubHeaderText: { 
            textAlign: 'right',
            width: 22
        },
        largeSubHeaderText: {
            textAlign: 'right',
            width: 50
        },
        discountSubHeaderText: {
            textAlign: 'right',
            width: 25
        },
    })
}