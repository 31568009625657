import React, { useEffect, useState, ReactNode, useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Loadering } from '../components/tools/loadering';
import BlocRapport from '../components/forms/reporting/BlocRapport';
import Header from '../components/header/header';
import FullscreenButton from '../components/tools/full-screen-button';
import './reporting.css';
import { AuthContext } from '../provider/AuthProvider';
import { useCustomData } from '../hooks/customs/useCustomData';
import axios from 'axios';
import { API } from '../Constantes';
import authHeader from '../services/AuthHeader';

interface ReportItem {
    nom: string;
    description: string;
    groupId: string;
    reportId: string;
    datasetId: string;
    filtre: string;
}

const Reporting: React.FC = () => {
    const { user } = useContext(AuthContext);
    const params = useParams();
    const groupId = params.groupId;

    const [forceRender, setForceRender] = useState(0);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [embedInstances, setEmbedInstances] = useState<{ [key: string]: ReactNode }>({});
    const [selectedReport, setSelectedReport] = useState<ReportItem | null>(null);

    const BlocsRapportData = useCustomData(`powerbi/get_powerbi_reports?groupId=` + groupId, undefined, groupId);

    const getEmbedInstance = (report: ReportItem, accessToken: string) => (
        <PowerBIEmbed
            key={report.reportId}
            embedConfig={{
                type: 'report',
                id: report.reportId,
                accessToken: accessToken,
                tokenType: models.TokenType.Embed,
            }}
            cssClassName={`report-style-class ${isFullscreen ? 'fullscreen' : ''}`}
        />
    );

    const fetchAccessTokenAndLoadReport = async (report: ReportItem) => {
        if (embedInstances[report.reportId]) {
            setSelectedReport(report);
            return;
        }

        try {
            const response = await axios.get(`${API}powerbi/access-token`, {
                headers: authHeader(),
                params: {
                    groupId: report.groupId,
                    reportId: report.reportId,
                    datasetId: report.datasetId,
                    filtre: report.filtre,
                },
            });

            const token = response.data.access_token;
            const newEmbedInstance = getEmbedInstance(report, token);
            setEmbedInstances((prev) => ({ ...prev, [report.reportId]: newEmbedInstance }));
            setSelectedReport(report);
        } catch (error) {
            console.error('Error fetching access token:', error);
            setError("Erreur lors de la récupération du token, veuillez réessayer et contacter un administrateur si cela se reproduit.");
        } finally {
            setIsLoading(false);
        }
    };

    const toggleFullscreen = () => {
        const element = document.getElementsByClassName('fullscreen-container')[0] as HTMLElement;
        if (!isFullscreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            }
        } else {
            if (document.exitFullscreen && document.fullscreenElement) {
                document.exitFullscreen();
            }
        }
        setIsFullscreen((prevFullscreenState) => !prevFullscreenState);
    };

    useEffect(() => {
        setIsLoading(true);
        if (BlocsRapportData && BlocsRapportData.length > 0) {
            setSelectedReport(BlocsRapportData[0]);
        }
    }, [BlocsRapportData]);

    useEffect(() => {
        if (selectedReport) {
            setError(null);
            fetchAccessTokenAndLoadReport(selectedReport);
        }
    }, [selectedReport]);

    const handleReloadReporting = async (report: ReportItem) => {
        setIsLoading(true);
        setError(null);

        if (embedInstances[report.reportId]) {
            if (selectedReport?.reportId !== report.reportId) {
                setSelectedReport(report);
            }
            setForceRender((prev) => prev + 1);
            setIsLoading(false);
        } else {
            await fetchAccessTokenAndLoadReport(report);
        }
    };

    const userAccessReporting = !!user?.acces_reporting;
    if (!userAccessReporting) return <Navigate to="/" replace />;

    return (
        <div className="content-wrapper w-100">
            <Header titre={'Reporting'} icone={'chart-line'} infos={false} recherche={false} />
            <main className="bg-bleu-clair1 h-100 reporting">
                <div className="entete">
                    <div className={`scrollbar height-${BlocsRapportData && BlocsRapportData.length < 3 ? '16' : '14'} bg-creme shadow-bottom`}>
                        <div className="d-flex align-items-center h-100 w-100">
                            <div className="my-auto container-fluid">
                                <div className="row">
                                    {BlocsRapportData && BlocsRapportData.map((report: ReportItem, index: number) => (
                                        <BlocRapport
                                            key={index}
                                            nom={report.nom}
                                            description={report.description}
                                            reportId={report.reportId}
                                            groupId={report.groupId}
                                            active={selectedReport?.reportId === report.reportId}
                                            onReportClick={() => handleReloadReporting(report)}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`scrollbar height-${BlocsRapportData && BlocsRapportData.length < 3 ? '17' : '15'}`}>
                        {isLoading ? (
                            <Loadering color={'bleu'} />
                        ) : (
                            <div className="mt-2 mx-2 position-relative">
                                {error ? (
                                    <div className="d-flex align-items-center h-100 justify-content-center">
                                        <div className="text-center">
                                            <span className='d-block mb-1 display-3 ln-1'>
                                                <span className="fa fa-ban font-bold rouge d-block m-auto"></span>
                                            </span>
                                            <span className="font-bold">
                                                {error || "Une erreur inconnue s'est produite, veuillez contacter un administrateur."}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={`fullscreen-container report-style-class ${isFullscreen ? 'fullscreen' : ''}`} key={forceRender}>
                                        <FullscreenButton
                                            isFullscreen={isFullscreen}
                                            toggleFullscreen={toggleFullscreen}
                                        />
                                        {selectedReport && embedInstances[selectedReport.reportId] ? (
                                            embedInstances[selectedReport.reportId]
                                        ) : (
                                            <Loadering color={'bleu'} />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </div>
    );
};
export default Reporting;