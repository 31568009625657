import { FilesProps } from "../../../class/templateMail";
import { allowedExtensions } from "../../../formatters/mailFormater";
import BtnTooltips from "../../tools/btn-tooltips"
import { Icon } from "../../tools/icon"

interface SelectPjProps {
    handleSelectFiles: (e: React.ChangeEvent<HTMLInputElement>) => void;
    selectFiles: FilesProps[];
    handleRemoveFile: (indexToRemove: number) => void;
}

export const SelectPj = ({ handleSelectFiles, selectFiles, handleRemoveFile }: SelectPjProps) => {

    const acceptedExtensions = allowedExtensions.join(', ');

    return (
        <div className="mt-2">
            <label htmlFor="fileInput" className="d-flex align-items-center cursor-pointer">
                <Icon icon="paperclip fa-solid me-2" />
                <span>Ajouter des pièces jointes</span>
            </label>
            <input
                type="file"
                name="files"
                id="fileInput"
                multiple
                style={{ display: 'none' }}
                onChange={handleSelectFiles}
                accept={acceptedExtensions}
            />
            <p style={{ fontStyle: 'italic', fontSize: '10px' }}>Liste des extensions autorisées: {acceptedExtensions}</p>
            <div className="d-flex flex-wrap gap-2">
                {selectFiles && selectFiles.length > 0 && (
                    selectFiles.map((file, index) => (
                        <div key={index} className="border border-primary rounded p-2 text-primary d-flex gap-1 align-items-center">
                            <Icon icon="paperclip fa-solid me-2" />
                            <span>{file.name}</span>
                            <BtnTooltips
                                tooltiplabel={'Supprimer les pièces jointes'}
                                buttonstyle={'nobt fa fa-circle-xmark display-14 rouge m-1'}
                                onClick={() => handleRemoveFile(index)}
                            />
                        </div>
                    ))
                )}
            </div>
        </div>
    )
}