import { unstable_useBlocker as useBlocker, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { DevisInfos, DevisValeur, ListeDeroulanteDevis } from "../../../class/devis";
import Header from "../../header/header";
import { Icon } from "../../tools/icon";
import DevisBody from "./devis-body";
import DevisFooter from "./devis-footer";
import DevisHeader from "./devis-header";
import { ActionDevis } from "./devis-infos-reducer";
import { ArticleFamilles, ArticleOrLot, Nota } from "../../../class/articles";
import { Dispatch, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import DevisService from "../../../services/DevisService";
import { formatUrl } from "../../functions/formatUrl";
import { devisExitReducer } from "./devis-exit-reducer";
import ConfirmModal from "../../tools/confirm-modal";
import { useFeature } from "flagged";
import { DevisTypeCode, corresp_provenance_accord_devis } from "../../../class/document";
import { CalculatriceModal } from "./modal/calculatrice-modal";
import { devisPrefix } from "../../../formatters/devisFormatter";
import { SendMailModal } from "../mails/send-mail-modal";
import { AvertissementModal } from "./avertissement-modal";
import PreHeader from "./pre-header";
import DevisPreHeader from "./devis-pre-header";



interface DevisInfosWrapperProps {
    isNewDevis?: boolean;
    inDivalto?: boolean;
    articles?: ArticleOrLot[];
    notaList?: Nota[];
    devisInfos: DevisInfos | undefined;
    devisInfosDispatch: Dispatch<ActionDevis>;
    ref?: any;
}

const DevisInfosWrapper = ({ isNewDevis = false, inDivalto, articles = [], notaList = [], devisInfos, devisInfosDispatch }: DevisInfosWrapperProps) => {

    const isDocumentListeOPT = useFeature('DocumentListe');
    const [showTitreError, setShowTitreError] = useState(false);
    const [showSousMetierError, setShowSousMetierError] = useState(false);
    const [showTypeDevisError, setShowTypeDevisError] = useState(false);
    const [showSaveModal, SetshowSaveModal] = useState({ isOpen: false, type: '' });

    const [showSendMailModal, setShowSendMailModal] = useState(false);
    const [showAvertissementModal, setShowAvertissementModal] = useState(false);
    const [isSentEmailInProgress, setIsSentEmailInProgress] = useState(false);
    const [isEmailNotSent, setIsEmailNotSent] = useState(false);

    const saveModalType = showSaveModal.type;
    const saveQuitModal = 'save-quit', quitModal = 'quit', redirectModal = 'redirect', saveModal = 'save', provenanceModal = 'provenance';

    const navigate = useNavigate();
    const { state } = useLocation();
    const isFromAffaire = state?.from === 'affaire';
    const devisName = devisInfos?.num_piece ? (devisInfos.is_devis ? devisPrefix + devisInfos?.num_piece : devisInfos?.num_piece) : devisInfos?.ref || '';
    const newDevisName = 'Nouveau Devis ' + (devisInfos?.num_piece ? devisPrefix + devisInfos?.num_piece : '');
    const editable = isNewDevis || !inDivalto;
    const devisStatus = devisInfos?.statut.selection?.code;
    //const editable = true;
    const [afficheSave, setAfficheSave] = useState(false);
    const pageExitCheckerStart = { bilan: true, bilanRows: true, notOneRow: true };
    const [pageExitChecker, exitDispach] = useReducer(devisExitReducer, pageExitCheckerStart);

    const shouldBlock = !pageExitChecker.bilanRows && (!showSaveModal.isOpen || saveModalType === redirectModal)
    const navBlocker = useBlocker(
        useCallback(() => (
            shouldBlock
        ), [shouldBlock])
    );

    const updateAfficheSave = (value: boolean) => setAfficheSave(value);
    const manageSafeExit = (key: string, isSafe: boolean) => exitDispach({ key: key, value: isSafe });
    const nbLignes = devisInfos?.lignes.length;
    const listesArticles = useMemo(() => getListeArticle(articles), [articles]);
    const listesDeroulantesArticles = useMemo(() => formatListesDeroulantesArticle(listesArticles, devisInfos), [devisInfos?.metier, !devisInfos])

    const statusDevis = devisInfos?.statut.selection?.code;

    useEffect(() => {
        navBlocker.state === 'blocked'
            && !showSaveModal.isOpen
            && SetshowSaveModal({ isOpen: true, type: redirectModal });
    }, [navBlocker.state])

    useEffect(() => {
        const newNotOneRow = !!nbLignes;
        (newNotOneRow !== pageExitChecker.notOneRow) && manageSafeExit('notOneRow', newNotOneRow);
    }, [nbLignes])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        showTitreError && setShowTitreError(!devisInfos?.ref);
        showSousMetierError && setShowSousMetierError(!devisInfos?.sous_metier.selection);
        showTypeDevisError && setShowTypeDevisError(!devisInfos?.devis_type.selection?.code);
    }, [(showTitreError && devisInfos?.ref),
    (showSousMetierError && devisInfos?.sous_metier.selection),
    (showTypeDevisError && devisInfos?.devis_type.selection?.code)]);  // eslint-disable-line react-hooks/exhaustive-deps
    /*useEffect(() => {
        showAdresseError && setShowAdresseError(!isAdresseSelected);
    }, [(showAdresseError && isAdresseSelected)]);*/  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const valoriseEmptyKey = (key: keyof listeArticle) =>
            devisInfos && !devisInfos?.[key] && !inDivalto && devisInfosDispatch({ key: key, value: listesDeroulantesArticles?.[key]?.possible[0]?.code });
        valoriseEmptyKey("metier");
        valoriseEmptyKey("sous_metier");
    }, [!devisInfos]);  // eslint-disable-line react-hooks/exhaustive-deps

    const [showCalculatrice, setShowCalculatrice] = useState(false);
    const handleCloseCalculatrice = () => setShowCalculatrice(false);
    const handleShowCalculatrice = () => setShowCalculatrice(true);

    const [showInfos, setShowInfos] = useState(true);

    const getHoursDevis = (hourlyRate: number, budgetPerHour: number) => {
        const calculatriceHoursReturn: { [key in keyof Partial<DevisInfos>]: number } = {
            budget_heure: budgetPerHour,
            taux_horaire: hourlyRate
        }
        devisInfosDispatch({ key: 'updateCalculatriceValues', value: calculatriceHoursReturn });
    }

    const [acceptedDevis, setAcceptedDevis] = useState(false);
    const [agreedDate, setAgreedDate] = useState(devisInfos?.date_accord ? devisInfos.date_accord : new Date());
    const [agreedStatut, setAgreedStatut] = useState(devisInfos?.provenance_accord ? devisInfos.provenance_accord : undefined);
    const [headerAcceptedStatut, setHeaderAcceptedStatut] = useState<{ [key in keyof Partial<DevisInfos>]: (Date | string | null) }>({ date_accord: null, provenance_accord: undefined });

    useEffect(() => {
        devisInfos?.date_accord && setAgreedDate(devisInfos.date_accord);
        devisInfos?.provenance_accord && setAgreedStatut(devisInfos.provenance_accord);
    }, [devisInfos?.date_accord, devisInfos?.provenance_accord])


    const addAgreedDate = (date: Date | null) => {
        date && setAgreedDate(date);
        if (date) {
            setHeaderAcceptedStatut(prevState => ({
                ...prevState,
                date_accord: new Date(date.setHours(0o6))
            }));
        }
    }

    const addSource = (e: string) => {
        e && setAgreedStatut(e as keyof typeof corresp_provenance_accord_devis);
        if (e in corresp_provenance_accord_devis) {
            setHeaderAcceptedStatut(prevState => ({
                ...prevState,
                provenance_accord: e as keyof typeof corresp_provenance_accord_devis
            }));
        }
    }

    useEffect(() => {
        if (devisInfos?.statut.selection?.code === '2') {
            setAcceptedDevis(true);
        } else {
            setAcceptedDevis(false);
            setAgreedStatut(undefined);
            setAgreedDate(new Date());
            setHeaderAcceptedStatut(prevState => ({
                ...prevState,
                date_accord: null,
                provenance_accord: undefined,
            }));
        }
    }, [devisInfos])

    return (
        <div className="content-wrapper w-100 h-100 bg-bleu-clair2">
            <Header titre={`${getDocInfos('type')} | ${isNewDevis ? newDevisName : devisName}`}
                devisType={devisInfos?.devis_type?.selection?.code as DevisTypeCode}
                icone={'file-lines'}
                infos={false}
                recherche={false}
            />
            <main className="editdevis-wrapper">
                <PreHeader
                    safeExit={safeExit}
                    isFromAffaire={isFromAffaire}
                    devisInfos={devisInfos}
                    getDocInfos={getDocInfos}
                    editable={editable}
                    isNewDevis={isNewDevis}
                    handleShowCalculatrice={handleShowCalculatrice}
                    statusDevis={statusDevis}
                    setShowSendMailModal={setShowSendMailModal}
                    setShowAvertissementModal={setShowAvertissementModal}
                    agreedStatut={agreedStatut}
                    SetshowSaveModal={SetshowSaveModal}
                    provenanceModal={provenanceModal}
                    saveQuitDevisHeader={saveQuitDevisHeader}
                />
                <div className="px-2 px-xxl-4">
                    <div className="bg-blanc rounded-0 w-100">
                        <div className={`height-search border-bottom `}>
                            <DevisPreHeader
                                editable={editable}
                                devisInfosDispatch={devisInfosDispatch}
                                devisInfos={devisInfos}
                                addAgreedDate={addAgreedDate}
                                acceptedDevis={acceptedDevis}
                                addSource={addSource}
                                agreedStatut={agreedStatut}
                                agreedDate={agreedDate}
                                headerAcceptedStatut={headerAcceptedStatut}
                                showInfos={showInfos}
                                setShowInfos={setShowInfos}
                            />
                        </div >


                        <div className={`scrollbar container-fluid noir w-100 height-6 ${statusDevis === '2' ? 'statut-accepte' : ''} `}>
                            {afficheSave &&
                                <div className={`preloadSave display-15 d-flex h-100 w-100 position-absolute d-flex align-items-center justify-content-center z-100 top-0 start-0 bg-clair ${afficheSave ? "show" : ""}`}>
                                    <Icon icon='check vert me-2' />
                                    <span className="font-bold display-17">
                                        Devis enregistré
                                    </span>
                                </div>}
                            {showInfos &&
                                <DevisHeader
                                    devisInfos={devisInfos}
                                    devisInfosDispatch={devisInfosDispatch}
                                    editable={editable}
                                    showTitreError={showTitreError}
                                    showSousMetierError={showSousMetierError}
                                    showTypeDevisError={showTypeDevisError}
                                    showAdresseError={false}
                                    listesDeroulantesArticles={listesDeroulantesArticles}
                                    saveDevisHeader={saveQuitDevisHeader}
                                    openCalculatrice={handleShowCalculatrice} />
                            }
                            {!isNewDevis &&
                                <DevisBody devisInfos={devisInfos}
                                    articles={articles}
                                    notaList={notaList}
                                    devisInfosDispatch={devisInfosDispatch}
                                    manageSafeExit={manageSafeExit}
                                    editable={editable}
                                    openCalculatrice={handleShowCalculatrice}
                                />}
                            {editable && !isNewDevis &&
                                <DevisFooter devisId={devisInfos?.id ?? -1}
                                    devisName={devisName}
                                    safeTransfer={safeTransfer} />}
                        </div>
                    </div >
                </div >
            </main >
            <ConfirmModal show={showSaveModal.isOpen}
                title={'Attention'}
                message={getModalMessage()}
                onConfirm={handleModalConfirm}
                onCancel={handleModalCancel}
                cantConfirm={saveModalType === provenanceModal}
                cantCancel={saveModalType === saveModal}
                isRed />

            <CalculatriceModal
                showCalculatrice={showCalculatrice}
                handleCloseCalculatrice={handleCloseCalculatrice}
                devisInfos={devisInfos}
                getHoursDevis={getHoursDevis}
            />
            <SendMailModal
                show={showSendMailModal}
                onHide={() => setShowSendMailModal(false)}
                devisInfos={devisInfos}
                statusDevis={statusDevis}
                showAvertissementModal={showAvertissementModal}
                setShowAvertissementModal={setShowAvertissementModal}
                setIsSentEmailInProgress={setIsSentEmailInProgress}
                isSentEmailInProgress={isSentEmailInProgress}
                setIsEmailNotSent={setIsEmailNotSent}
            />
            <AvertissementModal
                show={showAvertissementModal}
                onHide={() => setShowAvertissementModal(false)}
                statusDevis={statusDevis}
                setShowSendMailModal={setShowSendMailModal}
            />
            {
                isEmailNotSent &&
                <ConfirmModal
                    show
                    title="Avertissement"
                    isRed
                    message="Échec de l'envoi de l'email"
                    onConfirm={() => setIsEmailNotSent(false)}
                    onCancel={() => setIsEmailNotSent(false)}
                    hideFooter
                />
            }
        </div >
    )


    function getDocInfos(type: 'type' | 'liste') {
        const infos = [
            ['Devis', 'Bon de commande', 'Bon de livraison', 'Facture', 'Devis'], // index 4 = default value
            ['devis', 'documents', 'documents', 'documents', 'devis'],
            // ['devis', 'bons de commande', 'bons de livraison', 'factures', 'documents'],
        ]
        const index = type === 'type' ? 0 : 1;
        return devisInfos?.is_devis ? infos[index][0]
            /*: devisInfos?.is_bon_commande ? infos[index][1]
                : devisInfos?.is_bon_commande ? infos[index][2]
                    : devisInfos?.is_facture ? infos[index][3]*/
            : infos[index][4] // Pour les types inconnus
    }

    function safeExit() {
        checkSafeExit(false, true) && leavePage();
    }
    function safeTransfer() {
        return checkSafeExit(true);
    }

    function saveQuitDevisHeader(leave: boolean, forceLeave?: boolean) {
        if (!devisInfos) return;
        if (!devisInfos.ref || !devisInfos.sous_metier.selection || !devisInfos.devis_type.selection?.code) {
            setShowTitreError(!devisInfos.ref);
            setShowSousMetierError(!devisInfos.sous_metier.selection);
            setShowTypeDevisError(!devisInfos.devis_type.selection?.code);
            return;
        }
        if (headerAcceptedStatut.provenance_accord && headerAcceptedStatut.date_accord) {
            devisInfosDispatch({ key: 'updateHeaderAcceptedStatut', value: headerAcceptedStatut });
        }
        // if (!devisInfos.ref) { //|| !isAdresseSelected
        //     setShowTitreError(!devisInfos.ref);
        //     // setShowAdresseError(!isAdresseSelected);
        //     return;
        // };
        if (leave && !forceLeave && !checkSafeExit(true, true)) return;

        showSaveAffichage();

        DevisService.setDevisHeader(devisInfos).then(
            id => leave ? leavePage()
                : isNewDevis && id && navigate(formatUrl('devis/local', id), { state: { from: state?.from } })
        );
    }

    function checkSafeExit(save?: boolean, quit?: boolean) {
        const modalName = save ? quit ? saveQuitModal : saveModal : quitModal;
        const isNotSafe = quit ? (!pageExitChecker.bilanRows) : (!pageExitChecker.bilan);
        isNotSafe && SetshowSaveModal({ isOpen: true, type: modalName });
        return !isNotSafe;
    }

    function handleModalConfirm() {
        saveModalType === quitModal || saveModalType === redirectModal
            ? leavePage()
            : saveModalType === saveQuitModal
            && saveQuitDevisHeader(true, true);
        closeModal();
    }
    function handleModalCancel() {
        navBlocker.state === 'blocked' && navBlocker.reset();
        closeModal();
    }

    function closeModal() {
        SetshowSaveModal(prev => ({ ...prev, isOpen: false }));
    }

    function leavePage() {
        // Risque de casse si nouvel accès a la creation d'un devis (17/01/2024)
        // Potentielles solutions : rtansmetter un state au devis / Utiliser le replace du navigate après la création du devis
        const destination = isFromAffaire ? 'affaire/' + devisInfos?.affaire?.id
            : isDocumentListeOPT ? (devisInfos?.is_devis ? 'devis' : 'documents') : 'devis';
        navBlocker.state === 'blocked'
            ? navBlocker.proceed()
            : isFromAffaire
                ? devisInfos && navigate(formatUrl(destination))
                : navigate(-1);
    };

    function showSaveAffichage() {
        updateAfficheSave(true);
        setTimeout(() => updateAfficheSave(false), 1500);
    };

    function getModalMessage() {
        const rowPb = "Le devis ne contient aucune ligne.";
        const leaveMessage = "Le devis contient des modifications qui n'ont pas été enregistrées. Êtes-vous sûr de vouloir quitter cette page ?";
        const agreedStatusMessage = "Merci de renseigner la provenance du devis avant de quitter la page"

        return saveModalType === provenanceModal
            ? agreedStatusMessage
            : saveModalType === saveModal && pageExitChecker.bilanRows
                ? rowPb
                : leaveMessage;
    }

    function getListeArticle(articles: ArticleOrLot[]): listeArticle {
        const keys: (keyof ArticleFamilles)[] = ['metier', 'sous_metier'];
        return keys.reduce((acc, curr) => {
            acc[curr] = formatListeArticle(articles, curr);
            return acc;
        }, {} as listeArticle)
    }

    function formatListeArticle(articles: ArticleOrLot[], key: keyof listeArticle): DevisValeur[] {
        const liste = [...new Set(articles.map(article => article[key]))] as string[];
        return liste.map(prop => ({ code: prop, label: prop })).sort();
    }

    function formatListesDeroulantesArticle(listes: listeArticle, devisInfos: DevisInfos | undefined): { [key in keyof listeArticle]: ListeDeroulanteDevis } {
        return Object.keys(listes).map(k => {
            const key = k as keyof listeArticle;
            const selectValue = devisInfos?.[key]?.selection?.code;
            return ({
                [key]: {
                    possible: listes[key],
                    selection: {
                        code: selectValue,
                        label: selectValue,
                    },
                } as ListeDeroulanteDevis
            })
        }
        ).reduce((a, b) => ({ ...a, ...b }), {}) as { [key in keyof listeArticle]: ListeDeroulanteDevis };
    }
}

type listeArticle = {
    [key in keyof ArticleFamilles]: DevisValeur[];
};

export default DevisInfosWrapper;