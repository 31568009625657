import { Button, Modal } from "react-bootstrap";

interface AvertissementModalForSendPdfProps {
    show: boolean;
    onHide: () => void;
    selectedEmailName: string;
    selectedPdfModelId: string | number;
}

export const AvertissementModalForSendEmail = ({ show, onHide, selectedEmailName, selectedPdfModelId }: AvertissementModalForSendPdfProps) => {
    return (
        <Modal show={show} onHide={onHide} centered size="sm">
            <Modal.Header className="p-3" closeButton>
                <h4 className="rouge">
                    <span className="fa fa-triangle-exclamation me-2"></span>Avertissement
                </h4>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column gap-2">
                {
                    !selectedEmailName && !selectedPdfModelId ?
                        <div>Merci de sélectionner un modèle Pdf et un modèle d'email avant d'envoyer votre email</div>
                        : !selectedPdfModelId ?
                            <div>Merci de sélectionner un modèle Pdf avant d'envoyer votre email</div>
                            : !selectedEmailName &&
                            <div>Merci de sélectionner un modèle d'email avant d'envoyer votre email</div>
                }
            </Modal.Body>
            <Modal.Footer className="text-center justify-content-center">
                <Button onClick={onHide}><span className="fa fa-chevron-left me-2"></span>Retour</Button>
            </Modal.Footer>
        </Modal>
    )
}