import axios from "axios";
import { API } from "../Constantes";
import { encodeParams } from "../components/functions/requestTools";
import authHeader from "./AuthHeader";
import { Devis, DevisInfos, DevisLigne, DevisLigneEdit } from "../class/devis";
import { devisDBFormatter, devisDBLigneFormatter, devisDBLignesFormatter, devisListFormatter } from "../formatters/devisFormatter";
import { NewAdresse } from "../class/document";


class DevisService {
  async setDevisHeader(devisInfos: DevisInfos) {

    const response = await axios
      .post(API + "devis/local/set/entete",
        encodeParams({ devisInfos: JSON.stringify(devisDBFormatter(devisInfos)) }), {
        headers: authHeader()
      });
    const id = response.data;
    if (typeof id === 'number') return id;
  }

  async updateStatutDevis(id: number, divalto: string) {
    const response = await axios
      .post(API + "devis/statut/update",
        encodeParams({ id, divalto }), {
        headers: authHeader()
      });
      return response
  }

  async setDevisLigne(devisLigne: DevisLigne | DevisLigneEdit, devisID: number) {

    const response = await axios
      .post(API + "devis/local/set/ligne",
        encodeParams({ devisLigne: JSON.stringify(devisDBLigneFormatter(devisLigne, devisID)) }), {
        headers: authHeader()
      });
    const id = response.data;
    return typeof id === 'number' ? id : NaN;
  }

  async setDevisLignes(devisLigne: (DevisLigne | DevisLigneEdit)[], devisID: number) {

    const response = await axios
      .post(API + "devis/local/set/lignes",
        encodeParams({ devisLigne: JSON.stringify(devisDBLignesFormatter(devisLigne, devisID)) }), {
        headers: authHeader()
      });
    const ids = response.data;
    return typeof Array.isArray(ids) && ids as number[];
  }

  async transferDevis(devis_id: number) {

    const response = await axios.post(API + 'devis/send', encodeParams({ devis_id }), {
      headers: authHeader()
    })

    return response;
  }

  delDevisLigne(ligneId: number) {
    axios.post(API + "devis/local/del/ligne",
      encodeParams({ ligneId }), {
      headers: authHeader()
    });
  }
  delDevisLignes(ligneIds: number[]) {
    axios.post(API + "devis/local/del/lignes",
      encodeParams({ deletedLignes: JSON.stringify(ligneIds) }), {
      headers: authHeader()
    });
  }

  async delDevis(devisId: number) {
    await axios.post(API + "devis/local/del",
      encodeParams({ devisId }), {
      headers: authHeader()
    });
  }

  async duplicateDevis(devisId: number, titre: string, affaireId: string, divalto: string): Promise<number | undefined> {
    const response = await axios.post(API + "devis/duplicate",
      encodeParams({ devisId, titre, affaireId, divalto }), {
      headers: authHeader()
    });
    return parseInt(response.data.id);
  }

  setLigneOrder(ordre: { id: number; ordre: number; }[]) {
    axios.post(API + "devis/local/set/lignes/ordre",
      encodeParams({ ordre: JSON.stringify(ordre) }), {
      headers: authHeader()
    });
  }

  setLigneOrderAndTitles(titles: { id: number; montant_total: number; }[], ordre: { id: number; ordre: number; }[]) {
    axios.post(API + "devis/local/set/lignes/ordre-and-titles",
      encodeParams({ ordre: JSON.stringify(ordre), titles: JSON.stringify(titles)}), {
      headers: authHeader()
    });
  }

  delArticlePhoto(name: string, ref: string, ligneId: number) {
    const url = API + 'delete/image/article/local';
    axios.post(url,
      encodeParams({ ref, name, ligneId }), {
      headers: authHeader()
    })
  }

  setArticlePhoto(name: string, ref: string, file: File) {
    const url = API + 'set/image/article/local';
    const formData = new FormData();
    formData.append('path', ref + name);
    formData.append('file', file);
    console.log('formData', formData, file)

    axios.post(url, formData, {
      headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' }
    })
  }

  async createAdresse(adresse: NewAdresse, codeTiers: string) {
    const response = await axios
      .post(API + 'adresse/create',
        encodeParams({ adresse: JSON.stringify(adresse), codeTiers }), {
        headers: authHeader()
      });

    return response.data;
  }

  getDevisByAgency = (agencyCode: string, setDevisByAgency: (value: React.SetStateAction<[] | Devis[]>) => void) => {
    if (agencyCode === 'all') {
      setDevisByAgency([])
    } else {
      axios.get(API + `devis/agency/${agencyCode}`, {
        headers: authHeader(),
      })
      .then(response => setDevisByAgency(devisListFormatter(response.data)))
      .catch(error => !axios.isCancel(error) && console.log(error));
    }
  }

  async sendDevisXML(devis_id: number) {
    const response = await axios.post(API + 'devis/send/xml', encodeParams({ devis_id }), {
      headers: authHeader()
    })

    return response;
  }
}
const devisService = new DevisService();
export default devisService;
