import { useEffect, useState } from "react";
import ToogleSidebar from "../sidebar/toogle-sidebar";
import BtnTooltips from "../tools/btn-tooltips";
import InfosAffaire from "../forms/dashboardaffaire/infos-affaires";
import SearchAutocomplete from "../tools/search-autocomplete";
import { SearchListValue } from "../../formatters/searchFormatter";
import { useNavigate } from "react-router-dom";
import { trimEnd } from "../functions/textManager";
import ScreenSizeComponent from "../functions/screen-size-component";
import { DevisTypeCode } from "../../class/document";
import useScrollPosition from "../tools/use-scroll-position";



interface HeaderProps {
    titre: string;
    icone: string;
    infos: boolean;
    recherche: boolean;
    data?: SearchListValue[],
    retourAffaire?: any,
    date?: any,
    labeltooltip?: string,
    devisType?: DevisTypeCode;
}

const Header = ({ titre, icone, infos, recherche, data = [], retourAffaire, date, labeltooltip, devisType }: HeaderProps) => {

    const [toggleInfos, setToggleInfos] = useState(false);
    const [btnState, setBtnState] = useState(false);
    const navigate = useNavigate();
    const isScrolled = useScrollPosition();

    function handleClick() {
        setToggleInfos(!toggleInfos);
        setBtnState(btnState => !btnState);
        // setShowTooltip(false);        
    }
    useEffect(() => {
        setToggleInfos(false);
        setBtnState(false);
    }, []);


    useEffect(() => {
        setToggleInfos(false);
        setBtnState(false);
    }, [titre]);


    const toggleClassCheck = btnState ? 'fa-chevron-up d-none d-md-inline-block bleu-actif' : 'fa-chevron-down d-md-inline-block bleu-actif';
    const toggleLabel = btnState ? 'Masquer' : 'Afficher';

    const windowWidth = ScreenSizeComponent();

    const [trimmedTitre, setTrimmedTitre] = useState<string>('');
    useEffect(() => {
        const handleResize = () => {
            let trimLength = window.innerWidth < 768 ? (infos ? 15 : 30) : 100;
            if (infos && window.innerWidth < 768 && titre.length > 15) {
                trimLength = 14;
            }
            if (!infos && window.innerWidth < 768 && titre.length > 20) {
                trimLength = 20;
            }

            const trimmed = titre.slice(0, trimLength);
            setTrimmedTitre(infos && window.innerWidth < 768 && titre.length > 16
                || !infos && window.innerWidth < 768 && titre.length > 20
                ? `${trimmed}…`
                : trimmed);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [titre, infos]);

    return (
        <div className={`headertop ${isScrolled ? 'isScrolled' : ''}`}>
            <div className={`bg-white nav-header w-100 d-flex align-items-center justify-content-between ps-2 ps-lg-4 headertop ${windowWidth < 768 ? 'headertop-search' : ''} `}>
                <h2 className='my-0 col-md-6 col-xxl-7 px-0 d-flex align-items-center justify-content-start'>
                    {retourAffaire &&
                        <BtnTooltips
                            tooltiplabel={labeltooltip ? labeltooltip : `Retour liste des affaires`}
                            buttonstyle={`fa fa-chevron-left  btn-primary btn-rounded me-2 blanc px-2 border-0 display-17`}
                            onClick={() => navigate(retourAffaire)}
                        />
                    }
                    <span className={"fa fa-" + icone + " me-2"}></span>
                    {trimmedTitre} {devisType && ' ' + devisType}
                    {infos &&
                        <BtnTooltips tooltiplabel={`${toggleLabel} les infos client`} buttonstyle={`nobt fa  ${toggleClassCheck} ms-2`} onClick={() => handleClick()} />
                    }
                </h2>

                <div className='d-flex align-items-center justify-content-end col-md-4'>
                    {recherche && <SearchAutocomplete data={data} />}
                    {date &&
                        <span className="ps-4 d-flex">
                            <span className={`${windowWidth < 768 ? "d-none" : ""} d-md-block me-1`}>Date de création : </span>
                            <span className="font-bold">{date}</span>
                        </span>}
                </div>
                <ToogleSidebar />
            </div>

            {(toggleInfos && infos) &&
                <InfosAffaire setToggleInfos={setToggleInfos} setBtnState={setBtnState} />}
        </div>
    );
};
export default Header;