import { ChangeEvent, Dispatch, KeyboardEvent, useEffect, useRef, useState } from "react";
import { ActionDevis } from "../devis-infos-reducer";
import { Nota } from "../../../../class/articles";
import styled from "styled-components";
import { cancelHigherEvent } from "../devis-body";
import { preventInputClearOnEscape } from "./devis-ligne";

interface DevisLigneNotaProps {
    dispatch: Dispatch<ActionDevis>;
    isEdit: boolean;
    onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => void;
    notaList: Nota[];
    notaRef: string;
    notaCommentaire: string;
}

const DevisLigneNota = ({ dispatch, notaList, notaRef, notaCommentaire, isEdit, onKeyUp }: DevisLigneNotaProps) => {

    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const filtredListeDeroulante = notaList
        ? notaList.filter((nota: Nota) => // Filtre pour la recherche
            nota.ref
                .toLowerCase()
                .indexOf((notaRef + '').toLowerCase()) !== -1)
            .sort((a: Nota, b: Nota) => a.ref < b.ref ? -1 : 1)
        : [];

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const placeholderNota = windowWidth < 1280 && windowWidth > 768 ? "Ref." : "Référence du nota";


    if (isEdit) {
        return (
            <div className="d-lg-flex w-100">
                <div className="colonne-2 ">
                    <input type='search'
                        value={notaRef}
                        className={'form-control search-input'}
                        placeholder={placeholderNota}
                        ref={inputRef}
                        autoFocus
                        onChange={onNotaRefChange}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        onKeyDown={preventInputClearOnEscape}
                        onKeyUp={onSearchEnter} />
                    <div className="position-absolute mt-2 mt-md-0 w-100">
                        {isFocused && <div className="position-absolute  w-auto listederoulante">
                            <div className="wrapper w-100">
                                <ul className="custom-scrollbar w-100">
                                    {filtredListeDeroulante.slice(0, 50).map(nota =>  // On limite à 50 articles pour les performances
                                        <li key={nota.ref} onMouseDown={() => onListeSelection(nota)} >
                                            <div className="cellule-option">{nota.ref}</div>
                                        </li>)}
                                </ul>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="colonne-11">
                    <input className="form-control"
                        value={notaCommentaire}
                        maxLength={69}
                        placeholder={'Votre nota ici !'}
                        onChange={onNotaContentChange}
                        onKeyUp={onKeyUp} />
                </div>
            </div>
        )
    } else {
        return (
            <div className={"colonne-11 devis-ligne-nota-number"}>
                {notaCommentaire}
            </div>
        )
    };

    function onListeSelection(nota: Nota) {
        dispatch && dispatch({ key: 'setNota', value: nota });
    }

    function onNotaRefChange(e: ChangeEvent<HTMLInputElement>) {
        dispatch({ key: 'article_ref', value: e.target.value, })
    }

    function onNotaContentChange(e: ChangeEvent<HTMLInputElement>) {
        dispatch({ key: 'commentaire', value: e.target.value })
    }

    function onSearchEnter(e: React.KeyboardEvent<HTMLInputElement>) {
        cancelHigherEvent(e);
        if (e.key === 'Enter' && filtredListeDeroulante[0]) {
            const isSelected = filtredListeDeroulante[0].ref === notaRef;
            isSelected
                ? onKeyUp(e)
                : onListeSelection(filtredListeDeroulante[0]);
        } else onKeyUp(e);
    }

}
export default DevisLigneNota;
