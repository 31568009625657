import { Affaire, AffaireClientAdresse } from "./affaire";
import { Devis, DevisInfos, DevisLigne, ListeDeroulanteDevis, ListeDeroulanteDevisSousMetier } from "./devis";
import { Modify } from "./tools";


export interface DocumentDivaltoBase { // extends DocumentDivaltoType 
    id: number;
    user_id_creation: number;
    user_id_modification: number;
    user_name_creation: string;
    user_name_modification: string;
    reference: string;
    code_do: string;    // Code Donner d'Ordre
    affaire_id: number;
    num_piece: string;
    numero_dossier: string;
    budget_heures: string;
    code_metier: string;
    code_sous_metier: string;
    code_agence: string;
    nom_charge_affaire: string;
    nom_assure: string;
    createDate: Date;
    date_envoi: Date;
    date_visite: Date;
    montant: number;
    divalto: boolean;
    statut: keyof typeof corresp_statuts_devis;
    date_accord: Date | undefined;
    provenance_accord: keyof typeof corresp_provenance_accord_devis | undefined;
    type: string;
    is_devis: true;
}
export interface DocumentDivaltoType {
    is_devis?: false;
    is_bon_commande?: false,
    is_bon_livraison?: false;
    is_facture?: false;
}

export type DocumentDivalto = Devis
    | BonLivraison
    | BonCommande
    | Facture;

export interface BonCommande extends Modify<DocumentDivaltoBase, { is_bon_commande: true }> { };
export interface BonLivraison extends Modify<DocumentDivaltoBase, { is_bon_livraison: true }> { };
export interface Facture extends Modify<DocumentDivaltoBase, { is_facture: true }> { };



/// DOCUMENT INFOS

export interface DocumentDivaltoInfosBase { /*extends DocumentDivaltoType*/
    id: number;
    creator_id: number;
    modifier_id: number;
    user_name_creation: string;
    user_name_modification: string;
    statut: ListeDeroulanteDevis;
    date_envoi: Date | undefined;
    date_accord: Date | undefined;
    provenance_accord: keyof typeof corresp_provenance_accord_devis | undefined;
    num_piece: string;
    ref: string;
    creation_date: Date;
    modification_date: Date;
    code_agence: string;
    commentaire: string;
    metier: ListeDeroulanteDevis;
    sous_metier: ListeDeroulanteDevisSousMetier;
    code_bpu: string;
    age_batiment: string;
    budget_heure: number;
    taux_horaire: number;
    remise_global: number | string;
    montant_total: number; //HT
    envoye: boolean; // signification ?
    devis_type: ListeDeroulanteDevis;
    lignes: DevisLigne[];
    affaire: Affaire | undefined; // | undefined pour les bugs
    is_devis: true;
    is_from_divalto: boolean;
    //    categorie_comptable: ;
}

export type DocumentDivaltoInfos = DevisInfos
    | BonLivraisonInfos
    | BonCommandeInfos
    | FactureInfos;

export interface BonCommandeInfos extends Modify<DocumentDivaltoInfosBase, { is_bon_commande: true }> { };
export interface BonLivraisonInfos extends Modify<DocumentDivaltoInfosBase, { is_bon_livraison: true }> { };
export interface FactureInfos extends Modify<DocumentDivaltoInfosBase, { is_facture: true }> { };

export interface ListeDeroulanteAdresse {
    selection: AffaireAdresseListeDeroulante | undefined;
    possible: AffaireAdresseListeDeroulante[];
}

export interface AffaireAdresseListeDeroulante extends AffaireClientAdresse {
    id: number;
    intitule: string;
    code: number;
    label: string;
}

export interface NewAdresse extends AffaireClientAdresse {
    intitule: string;
    codepostal: string;
    ville: string;
    adresse: string;
}

export const corresp_statuts_devis = {
    '1': 'En cours / Brouillon',
    '2': 'Accepté',
    '3': 'Annulé',
    '4': 'Refusé',
    '5': 'A valider',
    //  '6': 'A envoyer',
    '7': 'Envoyé',
    //  '8': 'Ne pas traiter'
}

export const corresp_provenance_accord_devis = {
    '0': 'Aucun',
    '1': 'Accord suite à arbitrage',
    '2': 'Assureur',
    '3': 'Compétence Reneur',
    '4': 'Expert',
    '5': 'Télé-expert',
    '6': 'Client (Assuré ou Syndic ou Bailleur...)'
}

export const corresp_devis_types = {
    'N': 'Normal',
    'M': 'Modificatif',
    'A': 'Additif',
    'P': 'Privé',
}

export type DevisTypeCode = keyof typeof corresp_devis_types;
export const devis_type_default: DevisTypeCode = 'N';

export const corresp_statuts_devis_code = {
    inProgress: '1',
    accepted: '2',
    cancel: '3',
    Refusé: '4',
    toValidate: '5',
    toSend: '6',
    send: '7',
    notTreat: '8'
}