import { useState } from "react";
import { Form } from "react-bootstrap";

interface ListItemProps {
  label: string;
  composants: JSX.Element[];
  handleOuvrageChange?: (isChecked: boolean) => void;
  isOuvrageChecked?: boolean;
  defaultOpen?: boolean;
}

const ListItem = ({ label, composants, handleOuvrageChange, isOuvrageChecked, defaultOpen = false }: ListItemProps) => {

  //Gère l'ouverture du composant   
  const [isItemOpen, setIsItemOpen] = useState(defaultOpen);

  return (
    <li className={`list-group-item_1 ${isItemOpen ? '' : 'opacity-50'} list-unstyled`}>
      <div className="d-flex align-items-center justify-content-start blanc">
        <div
          className={`cursor-pointer blanc d-flex align-items-center`}
          onClick={() => setIsItemOpen(!isItemOpen)}>
          <span className={`me-2 fa fa-${isItemOpen ? 'minus' : 'plus'}`}></span>
          <span className={`list-group-item level_2 ${isItemOpen ? '' : 'opacity-50'}`}>
            {label}
          </span>
        </div>
        {handleOuvrageChange &&
          <Form.Check className="check me-md-2 p-2 d-flex align-items-center"
            onChange={e => handleOuvrageChange(e.target.checked)}
            checked={isOuvrageChecked}
          />
        }
      </div>
      {
        isItemOpen &&
        <ul>{composants}</ul>
      }
    </li>
  );
};

export default ListItem;
