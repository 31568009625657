import React from 'react';

const Footer_1 = () => {
  return (
    <div style={{ backgroundColor: '#01243F', color: '#FFFFFE' }}>
      <div style={{ color: '#FFFFFE', textAlign: 'center', padding: '20px 0px 7px 0px', fontWeight: 'bold', fontSize: '14px' }}>Devis Web</div>
      <div style={{ color: '#FFFFFE', textAlign: 'center', padding: '0px 0px 4px 0px', fontSize: '11px' }}>
        &copy; R&eacute;silians &ndash; Tous droits r&eacute;serv&eacute;s | 2024
      </div>
      <div style={{ color: '#FFFFFE', textAlign: 'center', padding: '0px 0px 20px 0px', fontSize: '11px' }}>
        T&eacute;l. 08 09 54 20 44 &ndash; Prix d'un appel local
      </div>
    </div>
  );
};

export default Footer_1;