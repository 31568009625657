import { useFeatures } from "flagged";
import { DevisLigneEdit } from "../../../../class/devis";
import BtnTooltips from "../../../tools/btn-tooltips";
import { customerOptions } from "../../../../Options";

interface DevisLigneTypeProps {
    isMenuShown: boolean;
    ligne: DevisLigneEdit;
    ToggleMenu: (val?: number) => void;
    ToggleLigne: (ligneType: keyof DevisLigneEdit) => void;
}

const DevisLigneType = ({ isMenuShown, ligne, ToggleMenu, ToggleLigne }: DevisLigneTypeProps) => {

    const { is_article, is_commentaire, is_titre, is_lot, is_photo, is_nota } = ligne;
    const features = useFeatures() as typeof customerOptions;
    
    return (
        <div className="colonne-1 position-relative">
            <BtnTooltips tooltiplabel={'Choix type de ligne'} 
                    buttonstyle={'nobt fa fa-list bleu-actif mx-lg-auto m-2'} 
                    onClick={ToggleMenu}
                    onBlur={() => ToggleMenu(-1)}
                    contenu={<span className="small d-md-none ms-1">Choix type de ligne</span>}
                    />
            <div className={isMenuShown ? '' : 'hideme'}>
                <div className="menu-article bg-bleu-clair2">
                    <ul className="list-group text-start">
                        <DevisLigneTypeChoix type={{is_article}} ToggleLigne={ToggleLigne}>
                            Article
                        </DevisLigneTypeChoix>
                        {/* <DevisLigneTypeChoix type={{is_commentaire}} ToggleLigne={ToggleLigne}>
                            Commentaire
                        </DevisLigneTypeChoix> */}
                        {features.TitreDevisLigne && 
                            <DevisLigneTypeChoix type={{is_titre}} ToggleLigne={ToggleLigne}>
                                Titre
                            </DevisLigneTypeChoix>
                        }
                        {features.LotInDevis && 
                            <DevisLigneTypeChoix type={{is_lot}} ToggleLigne={ToggleLigne}>
                                Ouvrage
                            </DevisLigneTypeChoix>
                        }
                        {features.PhotoInDevis && 
                            <DevisLigneTypeChoix type={{is_photo}} ToggleLigne={ToggleLigne}>
                                Photo
                            </DevisLigneTypeChoix>
                        }
                        <DevisLigneTypeChoix type={{is_nota}} ToggleLigne={ToggleLigne}>
                            Notas
                        </DevisLigneTypeChoix>
                    </ul>
                </div>
            </div>
        </div>
    )

}

interface DevisLigneTypeChoixProps {
    type: {[keyof in keyof Partial<DevisLigneEdit>]: boolean};
    ToggleLigne: (ligneType: keyof DevisLigneEdit) => void;
    children: JSX.Element | string;
}

const DevisLigneTypeChoix = ({type, children, ToggleLigne}: DevisLigneTypeChoixProps) => {
    const typeName = Object.keys(type)[0] as keyof DevisLigneEdit;
    const value = type[typeName]
    const MenuItem  ='bg-bleu-clair2 list-group-item border-0 ';
    const menuItemActif = 'bg-bleu-actif list-group-item border-0  blanc';
    return (
        <li className={ value ? menuItemActif : MenuItem} onMouseDown={() => ToggleLigne(typeName)}>
            <button className={ value ? "nobt blanc": "nobt bleu"} >
                {children}
            </button>
        </li>
    )
}


export default DevisLigneType;