import { useNavigate } from "react-router-dom";
import Header from "../components/header/header";
import { Icon } from "../components/tools/icon";


interface ErrorProps {
    errorType: string;
}

const ErrorPage = ({errorType}: ErrorProps) => {

    const navigate = useNavigate();
    let message;
    if (errorType === 'client') {
        message = 
            <div className="text-center">
                <h3 className="align-items-center d-flex w-100 justify-content-center">
                    <Icon icon='ban fs-1 rouge me-2 fas' />
                    Client introuvable.
                </h3>
                <p>Nous n'avons pas trouvé le client que vous recherchiez.</p>
                <p>
                    <button onClick={() => navigate(`/clients`)} className="btn btn-vert mt-2">
                        <Icon icon='chevron-left me-2' />
                        Retour à la liste des clients
                    </button>
                </p>
            </div>;
    } else {
        message =
            <div className="text-center">
                <h3 className="align-items-center d-flex w-100 justify-content-center">
                    <Icon icon='ban fs-1 rouge me-2 fas' />
                    Page introuvable.
                </h3>
                <p>Nous n'avons pas trouvé la page que vous recherchiez.</p>
                {/*<p>Cette page est peut-être en cours de développement.</p>*/}
                <p>
                    <button onClick={() => navigate(`/`)} className="btn btn-vert mt-2">
                        <Icon icon='chevron-left me-2' />
                        Retour
                    </button>
                </p>
            </div>;
    }

    return (
        <div className="content-wrapper w-100">
            <Header titre={'Erreur'} icone={'ban'} infos={false} recherche={false} />
            <main className="container-fluid pt-1 ps-1 ps-lg-4 pe-1 h-100 bg-bleu-clair2">
                <div className="h-50 d-flex align-items-center justify-content-center">
                    <div className="error-page">
                        <h2 className="text-center pb-2 display-1">404</h2>
                        {message}
                    </div>
                </div>
            </main>
        </div>
    )
}
export default ErrorPage;
