import { ChangeEvent, Dispatch, useMemo } from "react";
import { DevisInfos, DevisLigne } from "../../../../class/devis";
import formatMontant, { formatPriceToNumber } from "../../../functions/formatMontant";
import { ActionDevis } from "../devis-infos-reducer";
import DevisService from "../../../../services/DevisService";
import { DevisBodyTotal } from "./devis-body-total";
import { getArticleRSEBrut } from "../devis-body";


interface DevisBodyTotauxProps {
    devisInfos: DevisInfos | undefined;
    devisInfosDispatch: Dispatch<ActionDevis>;
    editable: boolean;
}


const DevisBodyTotaux = ({ editable, devisInfos, devisInfosDispatch }: DevisBodyTotauxProps) => {

    const totaux = useMemo(() => getDevisTotaux(devisInfos), [devisInfos?.lignes, devisInfos?.montant_total]);

    return (
        <div className="list-articles py-3 totaux">
            <div className="entete-article text-uppercase font-bold g-0 border-bottom display-18">
                <div className="colonne-11 border-0"> </div>
                <div className="lh-1 colonne-5 justify-content-end text-right"><span>Sous-total HT</span></div>
                <div className="lh-1 colonne-4 justify-content-end"><span>Taux TVA</span></div>
                <div className="lh-1 colonne-5 justify-content-end"><span>Montant TVA</span></div>
                {editable && <div className="colonne-4 border-0 d-none d-md-block"> </div>}
            </div>

            <ul className="p-0 odd mb-0 striped list-articles-totaux">
                {totaux.tauxTVAListeApRemise.map(tva =>
                    <DevisBodyTotal editable={editable}
                        montant={tva.subTotal}
                        tva={tva.tva}
                        montantTva={tva.tvaAmount}
                        key={tva.tva} />
                )}
            </ul>

            <ul className="p-0 odd mb-0 striped">
                <DevisBodyTotal editable={editable}
                    titre="TOTAUX"
                    isFinal
                    montant={totaux.sousTotalHT}
                    montantTva={totaux.totalTVA} />

                <li className="ligne-article bg-blanc">
                    <div className="lh-1 colonne-11 justify-content-end font-bold border-0 text-end">
                        Remise globale sur Total HT
                    </div>
                    <div className="lh-1 colonne-5 justify-content-end border-0">
                        <span>{formatMontant(totaux.remiseGlobalHT, true)}</span>
                    </div>
                    <div className="lh-1 colonne-4 justify-content-end euros pourcent border-0">
                        {editable
                            ? <input type="text"
                                className="form-control text-end"
                                value={devisInfos?.remise_global ?? ''}
                                onFocus={e => e.target.value === '0' && devisInfosDispatch({ key: 'remise_global', value: '' })}
                                onChange={e => devisInfosDispatch({ key: 'remise_global', value: e.target.value })}
                                onBlur={onRemiseBlur} />
                            : devisInfos?.remise_global}
                    </div>
                    <div className="colonne-5 border-0"> </div>
                    {editable && <div className="colonne-4 border-0 d-none d-md-block border-0"> </div>}
                </li>

                <DevisBodyTotal editable={editable}
                    titre="TOTAUX après remise"
                    isFinal
                    montant={totaux.sousTotalHTApRemise}
                    montantTva={totaux.totalTVAApRemise} />
                    
                <li className="hidden"></li>

                <li className="ligne-article display-12 bg-bleu blanc font-bold py-2 mt-2">
                    <div className="lh-1 colonne-11 justify-content-end nowrap border-0 text-uppercase">
                        Total TTC
                    </div>
                    <div className="colonne-5 border-0 d-none d-md-block bg-ellipsis me-0 me-md-2"> </div>
                    <div className="lh-1 d-flex align-items-center justify-content-end nowrap me-2">
                        {formatMontant(totaux.totalTTC, true)}
                    </div>
                    {editable && <div className="colonne-4 border-0 d-none d-md-block"> </div>}
                </li>
            </ul>
        </div>
    )

    function onRemiseBlur(e: ChangeEvent<HTMLInputElement>) {
        devisInfos && DevisService.setDevisHeader(devisInfos);
        e.target.value === '' && devisInfosDispatch({ key: 'remise_global', value: '0' })
    }

}
export default DevisBodyTotaux;

export const getTauxTVAListe = (lignes: DevisLigne[]) => {

    const articleRSE = getArticleRSEBrut(lignes);
    const ArticlesTVAListe = Array.from(new Set(
        lignes.filter(ligne => ligne.is_article).map(ligne => ligne.TVA)
    ));

    const TVAListeValues = (ArticlesTVAListe.length > 1 && !ArticlesTVAListe.includes(10))
        ? [...ArticlesTVAListe, 10]
        : ArticlesTVAListe;

    const tauxTVAListe = TVAListeValues.map(tva => {
        const subTotal = lignes.reduce((accumulateur, total) => (
            accumulateur + (total.TVA === tva && total.is_article ? total.montant_total : 0)
        ), 0) || 0;

        const subTotalAndRSE = TVAListeValues.length <= 1 
            ? subTotal + articleRSE
            : tva === 10 
                ? subTotal + articleRSE 
                : subTotal;

        const tvaAmount = subTotalAndRSE ? subTotalAndRSE * (tva / 100) : 0;

        return { tva: tva, subTotal: subTotalAndRSE, tvaAmount: tvaAmount }
    });

    return tauxTVAListe;
}

export const getDevisTotaux = (devisInfos: DevisInfos | undefined) => {
    
    const tauxTVAListe = devisInfos ? getTauxTVAListe(devisInfos.lignes) : [];

    const remiseGlobal = formatPriceToNumber(devisInfos?.remise_global || 0) || 0;
    const remiseGlobalInverse = (1 - (remiseGlobal / 100));
    // const sousTotalHT = devisInfos?.lignes.map(ligne => (ligne.is_article || ligne.is_lot) ? ligne.montant_total : 0).reduce((a, b) => a + b, 0) || 0;
    // const sousTotalHTApRemise = devisInfos?.montant_total || 0;
    const sousTotalHTApRemise = devisInfos?.montant_total || 0;
    
    const sousTotalHT = tauxTVAListe.reduce((total, tva) => total + tva.subTotal, 0);
    const remiseGlobalHT = devisInfos?.remise_global
    ? sousTotalHT * (remiseGlobal / 100)
    : 0;
    
    const totalTVA = tauxTVAListe.reduce((total, tva) => total + tva.tvaAmount, 0);
    const totalTVAApRemise = totalTVA * (1 - (remiseGlobal / 100));
    const totalTTC = totalTVAApRemise + sousTotalHTApRemise;

    const tauxTVAListeApRemise = tauxTVAListe.map(tva => ({...tva, tvaAmount: tva.tvaAmount*remiseGlobalInverse, subTotal: tva.subTotal*remiseGlobalInverse }))

    return { sousTotalHT, sousTotalHTApRemise, totalTVA, totalTVAApRemise, totalTTC, tauxTVAListe, tauxTVAListeApRemise, remiseGlobalHT }
}



/* const totalTVA = devisInfos?.lignes.map(ligne => (ligne.is_article || ligne.is_lot) ? ligne.montant_total * ligne.TVA / 100 : 0).reduce((a, b) => a + b, 0) || 0;

<li className="ligne-article">
    <div className="colonne-11"> </div>
    <div className="lh-1 colonne-5 justify-content-end"><span>129.80 €</span></div>
    <div className="lh-1 colonne-4 justify-content-end"><span>10 %</span></div>
    <div className="lh-1 colonne-5 justify-content-end"><span>12.98 €</span></div>
    {editable && <div className="colonne-4 border-0 d-none d-md-block"> </div>}
</li> */