import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { formatUrl } from "../../functions/formatUrl"
import { Icon } from "../../tools/icon"
import { SearchTableControlled } from "../dataTable/SearchTable"
import { Affaire } from "../../../class/affaire"
import Switcher from "../../tools/switcher"
import { Utilisateur, assistante } from "../../../class/utilisateur"
import { Agency } from "../../../class/agency"
import { allAgencies } from "../affaires/affaires-wrapper"
import { corresp_statuts_devis } from "../../../class/document"
import './devis-liste-header.css'
import DatePicker from 'react-datepicker'
import { getYearMonth } from "../../functions/formatDate"
import { CBWrapper, CustomCB } from "../../tools/custom_CB"
import useScrollPosition from "../../tools/use-scroll-position"
import { fr } from "date-fns/locale";


interface DevisListeHeaderProps {
    setFilterText: Dispatch<SetStateAction<string>>;
    selectedAffaire: Affaire | undefined;
    agencies: Agency[] | undefined;
    setAgency: Dispatch<SetStateAction<string>>;
    selectMyDevis: () => void;
    myDevis: boolean;
    user: Utilisateur;
    setSelectedMonth: Dispatch<SetStateAction<string>>;
    setFilterByStatut: Dispatch<SetStateAction<string>>;
    setSortByVisitedDate: Dispatch<SetStateAction<boolean>>;
    sortByVisitedDate: boolean;
    firstDay: Date;
    selectedMonth: string | null;
    filterByStatut: string;
    filterText: string;
    agency: string;
    getDevisByAgency: (agencyCode: string) => void;
    fetchNotEnded: boolean;
}


export const DevisListeHeader = ({ selectedAffaire, setFilterText, agencies, setAgency, selectMyDevis, setSelectedMonth, setFilterByStatut, filterByStatut, selectedMonth, myDevis, user, setSortByVisitedDate, sortByVisitedDate, firstDay, filterText, agency, getDevisByAgency, fetchNotEnded }: DevisListeHeaderProps) => {

    const changeAgency = (e: ChangeEvent<HTMLSelectElement>) => {
        fetchNotEnded && getDevisByAgency(e.target.value);
        setAgency(e.target.value);
    }

    // const changeDate = (e: ChangeEvent<HTMLSelectElement>) => {
    //     setFilterByDate(e.target.value);
    // }
    // const dateType = [
    //     'Date de création',
    //     'Date de visite'
    // ];

    const changeStatut = (e: ChangeEvent<HTMLSelectElement>) => {
        setFilterByStatut(e.target.value);
    }

    /*const changeStatut = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        // Your logic here, and then call the DevisSelect onChange
        const selectedOption: DevisValeur = {
          code: selectedValue,
          label: corresp_statuts_devis[selectedValue] || '',
        };
        // Now, call the DevisSelect onChange with the new selectedOption
        onChange(selectedOption);
      };*/

    const navigate = useNavigate();
    const createDevis = () =>
        navigate(formatUrl('devis/nouveau', selectedAffaire?.id));

    const curentMonth = new Date();
    const lastDay = new Date(curentMonth.getFullYear(), 11, 31);
    const [startDate, setStartDate] = useState(selectedMonth ? new Date(selectedMonth) : null);

    const handleMonthChange = (date: Date | null) => {
        if (date) {
            setStartDate(date);
            const clickedDate = getYearMonth(date);
            setSelectedMonth(clickedDate);
        }
    };

    useEffect(() => {
        if (sortByVisitedDate && !startDate) {
            setStartDate(curentMonth);
            setSelectedMonth(getYearMonth(curentMonth));
        }
    }, [sortByVisitedDate])

    const [queryParameters] = useSearchParams();

    const queryMonth = queryParameters.get('month');
    const queryVisitDate = queryParameters.get('visitDate');
    const querySearch = queryParameters.get('search');
    const querySelect = queryParameters.get('select');
    const queryAgency = user?.type_employe === assistante ? queryParameters.get('agency') : null;


    const setDate = (month: string) => {
        setSelectedMonth(month);
        setStartDate(month ? new Date(month) : null);
    }

    useEffect(() => {
        queryMonth ? setDate(queryMonth) : setDate('');
        queryVisitDate === 'yes' ? setSortByVisitedDate(true) : setSortByVisitedDate(false);
        querySearch ? setFilterText(querySearch) : setFilterText('');
        querySelect && setFilterByStatut(querySelect);
        queryAgency && setAgency(queryAgency);

        queryAgency && getDevisByAgency(queryAgency);
    }, [])


    useEffect(() => {
        const queryParams = {
            month: selectedMonth || '',
            visitDate: sortByVisitedDate ? 'yes' : 'no',
            search: filterText,
            select: filterByStatut,
            agency: agency
        };

        navigate({
            search: new URLSearchParams(queryParams).toString(),
        }, { replace: true });
    }, [selectedMonth, sortByVisitedDate, filterByStatut, filterText, agency])


    const datePickerRef = useRef(null);

    const clearDate = () => {
        setStartDate(null);
        setSelectedMonth('');
        setSortByVisitedDate(false);
    };

    const handleCheck = () => {
        setSortByVisitedDate(prev => !prev);
    }


    const isScrolled = useScrollPosition();

    return (
        <div className={`container-fluid ${isScrolled ? 'isScrolled' : ''}`}>
            <div className={`container pt-2 pb-2 `}>
                <div className="entete row">
                    <div className="col-md-5">
                        {user?.type_employe === assistante ?
                            <div className="d-md-flex justify-content-start align-items-center mb-2">
                                <div className='font-bold px-1 mb-0 nowrap'>Agence | Code :</div>

                                <select
                                    className="form-control form-select w-75"
                                    id="pourcent2"
                                    onChange={(e) => changeAgency(e)}
                                    value={agency}>
                                    <option value={allAgencies}>Toutes les agences</option>
                                    {agencies?.map(agency =>
                                        <option key={agency.agency_code} value={agency.agency_code}>{agency.agency_name} | {agency.agency_code}</option>
                                    )}
                                </select>
                            </div>
                            : <Switcher
                                label={'Mes devis'}
                                onChange={selectMyDevis}
                                isChecked={myDevis}
                            />
                        }
                    </div>

                    <div className="col-md-3 text-center mb-1 mb-md-0">
                        {selectedAffaire?.id &&
                            <button className="btn btn-bleu" onClick={createDevis}>
                                <span className="me-2">Créer un devis</span>
                                <Icon icon='plus blanc' />
                            </button>}
                    </div>
                    <div className="col-md-4 text-end" >
                        <div className="d-md-flex align-items-center w-100 justify-content-end">
                            {/* <SearchTable setFilterText={setFilterText} placeholder={'Num. affaire ou nom de l\'assuré'} /> */}
                            <SearchTableControlled value={querySearch || ''} setFilterText={setFilterText} placeholder={'Num. affaire ou nom de l\'assuré'} />
                        </div>
                    </div>
                </div>
                <div className="d-md-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-start align-items-center">
                        <label className="d-flex justify-content-start align-items-center">
                            <DatePicker
                                ref={datePickerRef}
                                onChange={(date) => { handleMonthChange(date) }}
                                showMonthYearPicker
                                selected={startDate}
                                dateFormat="MM/yyyy"
                                //isClearable={true}
                                //clearButtonClassName="rouge"
                                icon="fa fa-calendar"
                                showIcon
                                className="form-control mx-width-2"
                                closeOnScroll
                                maxDate={lastDay}
                                minDate={firstDay}
                                locale={fr}
                            />
                            <div className={'btn btn-rouge mx-2'} onClick={clearDate}>
                                <Icon icon={'xmark'} />
                            </div>

                        </label>


                        <CBWrapper className='p-2 justify-content-center' >
                            <label className="d-flex align-items-center justify-content-start cursor-pointer">
                                <span className="me-1 display-18">Filtrer par date de visite</span>
                                <CustomCB type="checkbox"
                                    checked={sortByVisitedDate}
                                    onChange={handleCheck} />
                            </label>
                        </CBWrapper>

                    </div>
                    <div className="d-md-flex justify-content-start align-items-center">
                        <select
                            className="form-control form-select w-100"
                            id="pourcent2"
                            onChange={(e) => changeStatut(e)}
                            value={filterByStatut}>
                            <option key={'all'} value={'all'}>Tous les devis</option>
                            {Object.entries(corresp_statuts_devis)?.map(([key, statut]) =>
                                <option key={key} value={key}>{statut}</option>
                            )}
                        </select>

                        {
                            /*
                            <DevisSelect
                            listeDeroulante={{
                                selection: {
                                  label: corresp_statuts_devis[filterByStatut] || '',
                                  code: filterByStatut,  // Assuming code is the key for corresp_statuts_devis
                                },
                                possible: Object.entries(corresp_statuts_devis).map(([key, label]) => ({ code: key, label })),
                              }}
                            titre="Your Dropdown Title"
                            editable={true}
                            onChange={changeStatut(e)}
                            isError={false}
                            isLoading={false}
                            />
                            */
                        }

                    </div>
                </div>
            </div>
        </div>


    )
}


/* <span className='font-bold px-1 mb-0 nowrap'>Type de date :</span> 
    <select
        className="form-select w-50"
        id="pourcent2"
        onChange={(e) => changeDate(e)}>
        {dateType?.map(date =>
            <option key={date} value={date}>{date}</option>
        )}
    </select> 
    <input
        className='form-control'
        type="month"
        value={selectedMonth}
        min="2000-01"
        onChange={handleMonthChange}
/> */
/* <DevisSelect
    largeur={130}
    listeDeroulante={annees}
    titre="Changer l'année"
    editable
    taille={'xs'}
    onChange={(selectedOption: DevisValeur) => changeSelectedYear(Number(selectedOption.code))} 
    activeItem={2023}
/> */


/*
            <ConfirmModal show={showCalculatrice} title={''}   hideTitle={false}
                hideHeader={false}
                message={<CalculatriceContent ismodal={true} />}
                size={'lg'}
                isLoading={true}
                onConfirm={handleCloseCalculatrice}
                onCancel={handleCloseCalculatrice}
                />
*/

/*  const date = new Date();
    const [selectedYear, setSelectedYear] = useState<number>(date.getFullYear());

    const changeSelectedYear = (yearNumber: number) => {
        if (yearNumber) {
            setSelectedYear(yearNumber);
            setMaxDate(new Date(yearNumber + 1, 0))
        }
    };

    function getYearArray(max?: Date): { label: string, code: number }[] {
        const currentYear = new Date().getFullYear();
        const shift = max ? currentYear - max.getFullYear() + 1 : 5;
        const yearArray = [];
        for (let i = 0; i < shift; i++) {
            const year = currentYear - i;
            yearArray.push({ label: `Année ${year}`, code: year });
        }
        return yearArray;
    }

    const annees: ListeDeroulanteDevis = {
        selection: { label: `Année ${selectedYear}`, code: selectedYear },
        possible: getYearArray(filtredDevis.at(-1)?.createDate),
    }; */