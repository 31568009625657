import { ChangeEvent } from "react";

interface AnnulationStatusRadioProps {
    id: string;
    type: string;
    value: string;
    checked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    className: string;
    disabled: boolean;
    label: string;
}


const AnnulationStatusRadio = ({ id, type, value, checked, onChange, className, disabled, label
}: AnnulationStatusRadioProps) => {
    return (
        <>
            <input
                id={id}
                type={type}
                value={value}
                checked={checked}
                onChange={(e) => {
                    onChange(e);
                }}
                className={className}
                disabled={disabled}
            />
            <label htmlFor={id} className={`cursor-pointer ms-1 ${!disabled ? '' : 'gris'} noir`}>
                {label}
            </label>
        </>
    );
};

export default AnnulationStatusRadio;