import { useContext } from 'react';
import { MenuItem } from 'react-pro-sidebar';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useIsMobile } from '../../hooks/customs/useWindowSize';

interface SubSidebarItemProps {
    path: string;
    children: string | JSX.Element;
    icon: string;
    urls: string[];
}

const SubSidebarItem = ({ path, children, icon, urls }: SubSidebarItemProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMobileDevice = useIsMobile();

    const isActive = urls.some(url => location.pathname.includes(url));

    const handleClick = () => navigate(path);

    const menuItemStyles = {
        height: '25px',
        fontSize: '0.9rem',
        opacity: isActive ? '1' : '0.7',
        /*backgroundColor: isActive ? '#f0f0f0' : 'transparent',*/
        color: '#FFF',
        paddingLeft: '10px',
        borderRadius: '5px',
        marginTop: '-5px',
    };

    return (
        <OverlayTrigger
            placement="right"
            show={!isMobileDevice}
            overlay={<></>}
        >
            <MenuItem style={menuItemStyles} active={isActive} onClick={handleClick}>
                <div className="d-flex align-items-center">
                    <span className={`fa fa-${icon} me-2`}></span>
                    {children}
                </div>
            </MenuItem>
        </OverlayTrigger>
    );
};

export default SubSidebarItem;
