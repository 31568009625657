import { Dispatch, KeyboardEvent, useEffect, useState } from "react"
import { LotArticles } from "../../../../class/articles"
import { DevisLigneEdit } from "../../../../class/devis"
import { ActionDevis } from "../devis-infos-reducer"
import Cellule from "../cellule/cellule"


interface DevisLigneLotProps {
    devisLigne: DevisLigneEdit,
    articles: LotArticles[],
    isEdit?: boolean
    dispatch: Dispatch<ActionDevis>
    onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => void
}

export const DevisLigneLot = ({dispatch, onKeyUp, isEdit, articles, devisLigne}:DevisLigneLotProps) => {
    
    const {article_ref, designation} = devisLigne;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const placeholderRef = windowWidth < 1280 && windowWidth > 768 ? "Ref." : "Recherche une ref";
    const placeholderNomPdt = windowWidth < 1280 && windowWidth > 768 ? "Produit" : "Recherche un nom de produit";
    
    return (
        <div className="d-lg-flex w-100">
            <Cellule dispatch={dispatch} isEdit={isEdit} col={2} value={{ article_ref }} onkeyPress={onKeyUp} articles={articles} type='search' placeholder={placeholderRef} />
            <Cellule dispatch={dispatch} isEdit={isEdit} col={11} value={{ designation }} onkeyPress={onKeyUp} articles={articles} type='search' placeholder={placeholderNomPdt} />
        </div>
    )
}