export interface TemplateMail {
    id: number,
    name: string,
    content: string,
    comment: string,
    model: string,
    is_restricted: boolean,
    restricted_user_list: string,
}

// Identifiants des modèles d'e-mails utilisés pour filtrer la liste d'envoi
export const templateEmailId = ['35', '36', '40', '43', '44'];

export interface DestinataireProps {
    id: string;
    label: string;
    email: string | null | undefined;
}

export interface FilesProps {
    name: string;
    type: string;
    file: File;
    size: number;
}
