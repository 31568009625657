import React, { ChangeEvent } from "react";
import { TemplateMail, templateEmailId } from "../../../class/templateMail";

interface SelectedEmailModelProps {
    handleSelectEmailName: (e: ChangeEvent<HTMLSelectElement>) => void;
    selectedEmailName: string;
    templateMails: TemplateMail[];
}

export const SelectEmailModel = ({ handleSelectEmailName, selectedEmailName, templateMails }: SelectedEmailModelProps) => {

    const filteredTemplatesEmail = templateMails.filter(template => templateEmailId.includes(template.id.toString()));

    return (
        <select
            className="form-select bg-bleu-clair1 border-0 bleu"
            onChange={handleSelectEmailName}
            value={selectedEmailName} >
            <option value="">Modèle d'email</option>
            {filteredTemplatesEmail?.map((template) => (
                <option key={template.id} value={template.name}>
                    {template.name}
                </option>
            ))}
        </select>
    );
};
