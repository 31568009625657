import { Button } from "react-bootstrap"
import { Icon } from "../../tools/icon"


interface DevisBodyHeaderProps {
    areMultipleLigneSelected: boolean;
    onSelectionDelete: () => void;
    onSelectionDuplicate: () => void;
    onSelectionClose: () => void;
    toggleBibliothequeBpu?: () => void;
}

export const DevisBodyHeader = ({ areMultipleLigneSelected, onSelectionDelete, onSelectionDuplicate, onSelectionClose, toggleBibliothequeBpu }: DevisBodyHeaderProps) => {

    return (
        <div className="d-md-flex py-1 align-items-center sticky-0 bg-blanc justify-content-between mt-2 ombre-1">
            <div className="d-md-flex align-items-center justify-content-start col-11">
                <h3 className="m-0 me-3">Articles</h3>

                <div className={areMultipleLigneSelected ? '' : 'opacity-50'}>
                    <Button className="me-2 mb-1" onClick={onSelectionDuplicate} disabled={!areMultipleLigneSelected}>
                        <Icon icon='clone me-2' />
                        Dupliquer la sélection
                    </Button>
                    <Button className="me-2 mb-1" onClick={onSelectionDelete} disabled={!areMultipleLigneSelected}>
                        <Icon icon='trash me-2' />
                        Supprimer la sélection
                    </Button>
                    <Button className="mb-1" onClick={onSelectionClose} disabled={!areMultipleLigneSelected}>
                        <Icon icon='times' />
                    </Button>
                </div>
            </div>
            <button className="btn btn-bpu" onClick={toggleBibliothequeBpu}><Icon icon='chevron-left me-2' />BPU</button>

        </div>
    )
}