import { useEffect, useRef, useState } from "react";
import BtnTooltips from '../../tools/btn-tooltips'
import ItemDevis from "./devis-item";
import { frenchMonth, getYearMonth } from '../../functions/formatDate';
import { Devis } from "../../../class/devis";
import DuplicateDevisModal from "./duplicate-devis-modal";
import { DocumentDivalto } from "../../../class/document";
import { Affaire } from "../../../class/affaire";
import { useAffairesForDuplication } from "../../../hooks/useAffaires";


interface DevisWrapperProps {
    filtredDevis: Devis[];
    affaires: Affaire[];
    isAssistante: boolean;
    devisShownRefresher?: any;
    refreshListeDevis: () => void;
}

const DevisListeBody = ({ filtredDevis, isAssistante, affaires, devisShownRefresher, refreshListeDevis }: DevisWrapperProps) => {

    const baseDevisShown = 8;
    const [devisShown, setDevisShown] = useState(baseDevisShown);
    const lastLiRef = useRef<HTMLLIElement[]>([]);

    const agencyAffaires = useAffairesForDuplication();
    const [dupplicateModal, setDupplicateModal] = useState({ isOpen: false, doc: undefined as undefined | DocumentDivalto });
    const closeModal = () => setDupplicateModal(prev => ({ ...prev, isOpen: false }))
    const openModal = (doc: DocumentDivalto) => setDupplicateModal({ isOpen: true, doc })

    useEffect(() => {
        setDevisShown(baseDevisShown);
    }, [devisShownRefresher])

    useEffect(() => {
        lastLiRef.current[0] && lastLiRef.current[0].scrollIntoView({ behavior: 'smooth', block: "start", inline: "start" });
    }, [devisShown])

    const showMoreDevis = () =>
        setDevisShown(self => self + baseDevisShown);

    const getMonthChange = (i: number) => {
        const testedMonth = filtredDevis[i].createDate;
        const currentMonth = new Date();

        return (getYearMonth(testedMonth) !== getYearMonth(currentMonth) && i - 1 < 0)
            ? frenchMonth[testedMonth.getMonth()] + ' ' + testedMonth.getFullYear()
            : !(i - 1 < 0 || !filtredDevis)
                && (getYearMonth(filtredDevis[i - 1].createDate) !== getYearMonth(testedMonth)) 
                && frenchMonth[testedMonth.getMonth()] + ' ' + testedMonth.getFullYear();
    }

    return (
        <div className="container p-0">
            <div className="listedevis">
                <ul className="list-devis mb-0">
                    {filtredDevis.length ?
                        filtredDevis.slice(0, devisShown).map((devis, i) =>
                            <li key={devis.id} ref={ref => i % baseDevisShown === baseDevisShown - 1 && devisShown !== i + 1 && ref && (lastLiRef.current[0] = ref)}>
                                {getMonthChange(i) &&
                                    <div className="lignevert position-relative container-fluid pt-4">
                                        <div className="row align-items-center ">
                                            <div className="col-3 col-md-2 display-15 font-bold dating text-center">
                                                <div className="pt-3 pb-2"> </div>
                                            </div>
                                            <div className="col-9 col-md-10 p-3 ps-0 mb-2 position-relative">
                                                <div className="display-13 font-bold">{getMonthChange(i)}</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <ItemDevis agencyAffaires={agencyAffaires} isAssistante={isAssistante} devis={devis} refreshListeDevis={refreshListeDevis} showDupplicateModal={openModal} />
                            </li>
                        ) :
                        <div className="text-center fw-bold display-15 p-3"><span className="fa-solid fa-circle-exclamation me-2"></span>Aucun devis n'a été trouvé.</div>
                    }
                </ul>
                {devisShown < filtredDevis.length &&
                    <div className="container-fluid">
                        <div className="row p-2 text-center">
                            <div className="col-md-1" />
                            <div className="col-md-11 text-center">
                                <BtnTooltips tooltiplabel={'Afficher plus de devis'}
                                    buttonstyle={'nobt fa fa-ellipsis bleu-actif cursor-pointer display-12 mx-auto'}
                                    onClick={showMoreDevis}
                                    placement='top' />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <DuplicateDevisModal
                show={dupplicateModal.isOpen}
                closeModal={closeModal}
                document={dupplicateModal.doc}
                affaires={agencyAffaires} />
        </div>
    )
}
export default DevisListeBody


