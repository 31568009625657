import DataTable, { createTheme } from 'react-data-table-component';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAffaires } from '../../../hooks/useAffaires';
import { CustomDatatableTheme, PaginationComponentOptions } from '../dataTable/CustomTheme';
import { Loadering } from '../../tools/loadering';
import { Affaire } from '../../../class/affaire';
import { getAffairesCols } from './affaire-columns';
import { AffaireExpandable } from './affaire-expandable';
import { formatUrl } from '../../functions/formatUrl';
import ConfirmModal from '../../tools/confirm-modal';
import { ChatList } from '../chat/chat-list';
import { useAgencies } from '../../../hooks/useAgencies';
import { AffairesListeHeader } from './affaires-liste-header';
import { AuthContext } from '../../../provider/AuthProvider';
import { assistante, charge_affaire } from '../../../class/utilisateur';
import affairesService from '../../../services/AffairesService';

interface AffaireProps {
    nbrLotAttente: number;
    showLots: boolean;
}

export const listAffaire = 'affaires'

export const AffaireTable = ({ nbrLotAttente, showLots }: AffaireProps) => {

    const { state } = useLocation();
    const stateFilterText: string = state?.filterText || '';
    const stateAgency: string = state?.agency || allAgencies;
    const stateAffairesPerPage: number = state?.affairesPerPage || 25;
    const stateCurrentPage: number = state?.currentPage || 1;
    const [expandedRow, setexpandedRow] = useState<number>(NaN);
    const [filterText, setFilterText] = useState(stateFilterText);
    const affaires = useAffaires();
    const [pending, setPending] = useState(true);
    const [agency, setAgency] = useState(stateAgency);
    const [affairesPerPage, setAffairesPerPage] = useState(stateAffairesPerPage);
    const [currentPage, setCurrentPage] = useState(stateCurrentPage);
    const [showChatModal, setShowChatModal] = useState('');
    const [affairesByAgency, setAffairesByAgency] = useState<Affaire[] | []>([]);    
    const [isAffairesByAgency, setIsAffairesByAgency] = useState(false);    
    const { user } = useContext(AuthContext);
    const agencies = useAgencies();
    const isChargeDaffaire = user?.type_employe === charge_affaire;
    const isAssistante = user?.type_employe === assistante;
    
    const filterAffaires = localStorage.getItem('filterAffaires');
    const isMyAffaire = !filterAffaires || !!JSON.parse(filterAffaires);
    if (!filterAffaires) localStorage.setItem('filterAffaires', JSON.stringify(isMyAffaire));
    const [myAffaires, setMyAffaires] = useState(isMyAffaire);

    const navigate = useNavigate();
    createTheme('custom', CustomDatatableTheme, 'light');

    useEffect(() => {
        setPending(!affaires);
    }, [affaires]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (window.innerWidth < 768) {
            expandedRow && setTimeout(() => {
                const parentRow = document.getElementById(`expanded-affaire-${expandedRow}`);
                parentRow?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
            }, 50)
        }
    }, [expandedRow])


    const filteredItems = affaires ?
        affaires.data?.filter(
            affaire =>
                Object.values(affaire)
                    .join(',')
                    .toLowerCase()
                    .indexOf(filterText.toLowerCase()) !== -1
                && (Object.values(affaire).includes(agency) || agency === allAgencies || !isAssistante)
                && ((affaire.nom_charge_affaire.toLowerCase().includes(user.nom.toLowerCase()) && affaire.nom_charge_affaire.toLowerCase().includes(user.prenom.toLowerCase())) || !myAffaires || !isChargeDaffaire)
        )
        : [];

    const paginationTotalRows = isAffairesByAgency 
        ? affairesByAgency.length 
        : (isChargeDaffaire && !affaires.isNotFinished) 
            ? (filteredItems?.length || 0) 
            : affaires.count;

    const pageCount = Math.ceil(paginationTotalRows / affairesPerPage);

    // useEffect(() => {
    //     console.log('pageCount', pageCount)
    //     console.log('stateCurrentPage', stateCurrentPage)
    //     pageCount >= stateCurrentPage && setCurrentPage(stateCurrentPage)
    // }, [pageCount])


    const selectMyAffaires = () => {
        setMyAffaires(prev => !prev);
        localStorage.setItem('filterAffaires', JSON.stringify(!myAffaires));
    }

    const getAffairesByAgency = (agencyCode: string) => {
        affairesService.getAffairesByAgency(agencyCode, setAffairesByAgency);
        setIsAffairesByAgency(agencyCode !== allAgencies ? true : false);
    }

    const subHeaderComponentMemo = useMemo(() => (
        <AffairesListeHeader setFilterText={setFilterText} selectMyAffaires={selectMyAffaires} myAffaires={myAffaires} setAgency={setAgency} agencies={agencies} toggleClassSearch={''} user={user} isNotFinished={affaires.isNotFinished} agency={agency} filterText={filterText} getAffairesByAgency={getAffairesByAgency}/>
    ), [agencies, myAffaires, affaires.isNotFinished, agency, filterText]);

    const OpenModalWithData = (id_affaire: string) => {
        setShowChatModal(id_affaire);
    }

    const columns = useMemo(() => getAffairesCols({ navigate, OpenModalWithData, isChargeDaffaire }), [isChargeDaffaire]);

    if (!filteredItems) return (<Loadering color={'bleu'} />)

    return (
        <>
            <div className="table entete">
                <DataTable
                    columns={columns}
                    data={(isAffairesByAgency && affaires.isNotFinished) ? affairesByAgency : filteredItems}
                    theme="custom"
                    className={`row_pointer scrollbar ${showLots && nbrLotAttente > 0 ? 'height-5' : nbrLotAttente === 0 ? 'height-6' : 'height-13'}`}
                    //customStyles={affaireTableTheme}
                    striped
                    fixedHeader
                    //fixedHeaderScrollHeight={window.innerWidth <= 768 ? "calc(100% - 240px)" : "calc(100% - 190px)"} 
                    paginationPerPage={affairesPerPage}
                    pagination
                    paginationComponentOptions={PaginationComponentOptions}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    paginationTotalRows={paginationTotalRows}
                    // onChangeRowsPerPage={handlePerRowsChange}
                    paginationDefaultPage={currentPage}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRowsPerPage={(currentRowsPerPage) => setAffairesPerPage(currentRowsPerPage)}
                    paginationResetDefaultPage={false} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    //selectableRows
                    conditionalRowStyles={[{ when: (row: Affaire) => row.id === expandedRow, classNames: ['selected'] }]}
                    highlightOnHover={true}
                    persistTableHead
                    expandableRows={window.innerWidth < 1440}

                    expandableRowsComponent={AffaireExpandable} // as ExpandableRowsComponent<Affaire>
                    expandableRowsHideExpander={false}
                    expandableRowExpanded={(rowData: Affaire) => expandedRow === rowData.id}
                    onRowExpandToggled={(expanded: boolean, rowData: Affaire) => handleRowExpand(expanded ? rowData.id : undefined)}

                    noDataComponent={
                        filterText
                            ? <div className='p-4'>Nous n'avons trouvé aucune réponse correspondant à votre recherche.</div>
                            : isAffairesByAgency 
                                ? affairesByAgency.length === 0 
                                    ? <span className="p-4">Aucune affaire n'a été trouvée.</span> 
                                    : <div className="noAffaire"><Loadering color={'bleu'} /></div>
                                : filteredItems.length === 0 && affaires.isNotFinished 
                                    ? <div className="noAffaire"><Loadering color={'bleu'}  /></div>
                                    : <span className="p-4">Aucune affaire n'a été trouvée.</span>
                    }

                    progressPending={pending}
                    progressComponent={<Loadering color={'bleu'} formodal={true} />}
                    onRowClicked={onRowClicked}
                />
            </div>
            <ConfirmModal
                show={!!showChatModal}
                title={"L CHat"}
                titleIcon={''}
                hideHeader={false}
                message={<ChatList id={showChatModal} />}
                onConfirm={() => console.log('rien')}
                onCancel={() => setShowChatModal('')}
                cantConfirm={true}
                cantCancel={true}
                isRed={false}
                size={'xl'}
                hideFooter={false} />
        </>
    );

    function handleRowExpand (rowId: number | undefined) {
        const newExpandedRow = (expandedRow === rowId || !rowId) ? NaN : rowId;
        setexpandedRow(newExpandedRow);
    };

    function onRowClicked (rowData: Affaire) {
        const curPath = window.location.pathname;
        const newState = { currentPage: currentPage, affairesPerPage: affairesPerPage, from: listAffaire, filterText: filterText, agency: agency };
        navigate(curPath, {replace: true, state: newState})
        navigate(formatUrl('affaire', rowData.id), 
            { state: newState }
        );
    }
};

export default AffaireTable;

export const allAgencies = 'all';