import CodeBpus from "./code-bpus";
import ListItem from "./list-items";
import { BibliothequeCodeBpus } from "../../../../class/articles";

interface MetiersProps {
    label: string;
    isOuvragesChecked: boolean;
    codeBpus: BibliothequeCodeBpus;
    updateSelectedArticles: (id: number) => void;
    selectedArticles: number[];
}

const Metiers = ({ label, isOuvragesChecked, codeBpus, updateSelectedArticles, selectedArticles }: MetiersProps) => {
    return (
        <ListItem
            label={label}
            defaultOpen
            composants={Object.entries(codeBpus).map(([codeBpu, sousMetiers]) => {
                return (
                    <CodeBpus
                        key={codeBpu}
                        label={codeBpu}
                        sousMetiers={sousMetiers}
                        isOuvragesChecked={isOuvragesChecked}
                        updateSelectedArticles={updateSelectedArticles}
                        selectedArticles={selectedArticles}
                    />
                );
            })}
        />
    );
}



export default Metiers;