import axios from "axios";
import { API } from "../Constantes";
import authHeader from "./AuthHeader";
import { encodeParams } from "../components/functions/requestTools";
import { FilesProps, TemplateMail } from "../class/templateMail";
import { PdfModel } from "../options/pdf-export/models/pdf-model-list";
import { pdf } from "@react-pdf/renderer";


class TemplateMailService {
    delTemplate(id: number) {
        return axios.post(API + "/mail/del/template",
            encodeParams({ id }), {
            headers: authHeader()
        });
    }

    async updateTemplate(template: TemplateMail) {
        const response = await axios.post(API + "/mail/update/template",
            encodeParams({ template: JSON.stringify(template) }), {
            headers: authHeader()
        });
        return response.data;
    }

    async addTemplate(template: TemplateMail) {
        const response = await axios
            .post(API + '/mail/create/template',
                encodeParams({ template: JSON.stringify(template) }), {
                headers: authHeader()
            });
        return response.data;
    }

    async addMailPdf(pdfModel: PdfModel, pdfNameWithDevisId: string) {
        try {
            const pdfBlob = await pdf(pdfModel?.rendu).toBlob();
            const formData = new FormData();
            formData.append('pdfFile', pdfBlob, pdfNameWithDevisId);

            const response = await axios.post(`${API}mail/store/pdf`, formData, {
                headers: {
                    ...authHeader(),
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response

        } catch (error) {
            console.error("Erreur lors de la génération et de l'envoi du PDF :", error);
        }
    }

    async addMailPj(files: FilesProps[], timestamp: number) {
        try {
            const formData = new FormData();

            files.forEach((file) => {
                formData.append('files[]', file.file, file.name);
            });

            formData.append('timestamp', timestamp.toString());

            const response = await axios.post(`${API}mail/store/pj`, formData, {
                headers: {
                    ...authHeader(),
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response;

        } catch (error) {
            console.error("Erreur lors de l'envoi des fichiers au backend :", error);
        }
    }

    async addEmailToDocuware(
        nomClient: string,
        nomAgence: string,
        numeroDossier: string,
        contentTemplate: string,
        pdfNameWithDevisId: string,
        fileNames: string[],
        files: FilesProps[],
        timestamp: number,
        selectedEmailName: string
    ) {
        try {
            const response = await axios.post(API + "mail/upload/docuware", {
                nomClient: nomClient,
                nomAgence: nomAgence,
                numeroDossier: numeroDossier,
                contentTemplate: contentTemplate,
                pdfNameWithDevisId: pdfNameWithDevisId,
                fileNames: fileNames,
                files: files,
                timestamp: timestamp,
                selectedEmailName: selectedEmailName,
            }, {
                headers: {
                    ...authHeader(),
                    'Content-Type': 'application/json',
                },
            });
            return response;

        } catch (error) {
            console.error("Erreur lors de l'envoi des données au Backend:", error);
            throw error;
        }
    }

    async sendEmailRequest(
        emails: string[] | string | [],
        ccEmailsArrayFormatted: string[] | [],
        setLoading: (loading: boolean) => void,
        pdfName: string,
        contentTemplate: string,
        fileNames: string[],
        timestamp: number,
        pdfModel: PdfModel,
        pdfNameWithDevisId: string,
        files: FilesProps[],
    ) {
        setLoading(true);

        try {
            await this.addMailPdf(pdfModel, pdfNameWithDevisId);
            await this.addMailPj(files, timestamp);

            const response = await axios.post(API + "email/template/send", {
                emails: emails,
                ccEmailsArrayFormatted: ccEmailsArrayFormatted,
                contentTemplate: contentTemplate,
                pdfName: pdfName,
                fileNames: fileNames,
                timestamp: timestamp
            }, {
                headers: authHeader(),
            });
            return response.data;

        } catch (error: any) {
            console.log("Erreur lors de l'envoi de l'e-mail :");
            throw error;
        } finally {
            setLoading(false);
        }
    }
}
const templateMailService = new TemplateMailService();
export default templateMailService;